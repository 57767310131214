import { toast } from 'react-toastify';
import { BEFORE_FAQ, CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, GET_FAQ, GET_FAQS, CREATE_FAQ_CAT, BEFORE_FAQ_CAT, LIST_FAQ_CAT, GET_FAQ_CAT, EDIT_FAQ_CAT, DELETE_FAQ_CAT } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

const apiUrlFaqCats = `faqs/categories`
const apiUrlFaqs = `faqs`

export const beforeFaq = () => {
    return {
        type: BEFORE_FAQ
    }
}

export const getFaqs = (qs = '', body = {}) => dispatch => {
    toast.dismiss();

    let url = `${apiUrlFaqs}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        body,
        actionType: GET_FAQS,
    })
};

export const updateFaq = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqs}`,
        method: 'PUT',
        body,
        actionType: EDIT_FAQ,
    })
};

export const deleteFaq = (faqId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqs}/${faqId}`,
        method: 'DELETE',
        actionType: DELETE_FAQ,
    })
};

export const addFaq = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqs}`,
        method: 'POST',
        body,
        actionType: CREATE_FAQ,
    })
};

export const getFaq = (faqId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqs}/${faqId}`,
        method: 'GET',
        actionType: GET_FAQ,
    })
};

// Faq Categories

export const beforeFaqCat = () => {
    return {
        type: BEFORE_FAQ_CAT
    }
}

export const addFaqCat = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqCats}`,
        method: 'POST',
        body,
        actionType: CREATE_FAQ_CAT,
    })
};

export const listFaqCategories = (qs = '', showToast = true) => dispatch => {
    toast.dismiss();

    let url = `${apiUrlFaqCats}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        showToast,
        actionType: LIST_FAQ_CAT,
    })
};

export const getFaqCategory = (faqCatId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqCats}/${faqCatId}`,
        method: 'GET',
        actionType: GET_FAQ_CAT,
    })
};

export const editFaqCategory = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqCats}`,
        method: 'PUT',
        body,
        actionType: EDIT_FAQ_CAT,
    })
};

export const deleteFaqCat = (faqCatId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlFaqCats}/${faqCatId}`,
        method: 'DELETE',
        actionType: DELETE_FAQ_CAT,
    })
};