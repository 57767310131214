import { ADD_ROLE, UPDATE_ROLE, DELETE_ROLE, GET_ROLE, GET_ROLES, BEFORE_ROLE, BEFORE_PERMISSION, GET_PERMISSION } from '../../../redux/types';
import { toast } from 'react-toastify';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `roles`
export const beforeRole = () => {
    return {
        type: BEFORE_ROLE
    }
}

export const beforePermission = () => {
    return {
        type: BEFORE_PERMISSION
    }
}

export const addRole = (body) => dispatch => {
    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'POST',
        body,
        actionType: ADD_ROLE,
        addAccessToken: true,
        showToast: true
    })
}

export const getRoles = (query = '', allResults = false, showToast = true) => dispatch => {
    toast.dismiss();

    let url = `${apiUrl}`
    console.log('query',query)

    if (query)
        url = `${url}?${query}`

    if (allResults) {
        url = `${url}?all=${allResults}`
    }

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: GET_ROLES,
        addAccessToken: true,
        showToast
    })
}

export const getRole = (roleId) => dispatch => {
    toast.dismiss();

    generalHttpRequest({
        url: `${apiUrl}/${roleId}`,
        method: 'GET',
        actionType: GET_ROLE,
        addAccessToken: true
    })

}

export const updateRole = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'PUT',
        body,
        actionType: UPDATE_ROLE,
        addAccessToken: true
    })

}

export const deleteRole = (roleId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${roleId}`,
        method: 'DELETE',
        actionType: DELETE_ROLE,
        addAccessToken: true
    })
}

export const getPermission = (roleId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${roleId}`,
        method: 'GET',
        actionType: GET_PERMISSION,
        addAccessToken: true
    })
}