import { BEFORE_DASHBOARD, GET_DASHBOARD } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeDashboard = () => {
    return {
        type: BEFORE_DASHBOARD
    }
}

export const getDashboard = () => dispatch => {

    generalHttpRequest({
        url: `admin/dashboard`,
        method: 'GET',
        actionType: GET_DASHBOARD
    })
};

