import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { permissionsForComponents } from "../../utils/functions";
import { Images } from '../../assets/assets'
import { ENV } from "config/config";
import { Collapse, Nav } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = ({ routes, image, background, permissionsData }) => {
  let location = useLocation();
  const [state, setState] = React.useState({});
  const [permissions, setPermissions] = React.useState(permissionsData);
  const [adminRoutes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    if (Object.keys(permissionsData).length > 0) {
      setPermissions(permissionsData);
    }
  }, [permissionsData]);

  React.useEffect(() => {
    if (permissions && Object.keys(permissions).length > 0) {
      // separate those roles which are not permitted to admin
      let rolesNotPermitted = getRolesNotPermitted(permissions);

      // Based on the roles, separate those routes/components which admin is not allowed to view
      let routesNotPermitted = getRoutesNotPermitted(rolesNotPermitted);

      // if a route contains submenus and any of the submenu is not allowed to be viewed then remove it
      let filteredRoutes = routes.map((route) => {
        if (route.collapse) {
          route.submenus = route.submenus.filter((submenu) => !routesNotPermitted.includes(submenu.name));
        }
        return route;
      });

      // After submenus, filter main routes from array which are not allowed to be viewed by admin
      filteredRoutes = filteredRoutes.filter((route) => {
        if (route.collapse && route.submenus.length <= 0) {
          return null;
        }
        if (!routesNotPermitted.some((r) => r === route.name)) {
          return route;
        }
      });

      setRoutes(filteredRoutes);
    }
  }, [permissions]);

  React.useEffect(() => {
    if (adminRoutes && adminRoutes.length > 0) {
      setState(getCollapseStates(adminRoutes));
    }
  }, [adminRoutes]);

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.submenus),
          ...getCollapseStates(prop.submenus),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].submenus)) {
        return true;
      } else if (location.pathname === routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (!prop.showInSideBar) {
        return null;
      }
      if (prop.collapse) {
        let st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item className={getCollapseInitialState(prop.submenus) ? "active" : ""} as="li" key={key}>
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <span className="nav-icon-holder">
                <i className={prop.icon}></i>
                <FontAwesomeIcon icon={prop.faIcon} />
              </span>
              <p className="d-flex justify-content-between flex-fill">
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul" onClick={() => $("html").removeClass("nav-open")}>
                  {createLinks(prop.submenus)}
                </Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.path)}
          key={key}
          as="li"
          onClick={() => {
            $("html").removeClass("nav-open");
          }}
        >
          <Nav.Link to={prop.path} state= {{ location }} as={Link}>
            {prop.icon ? (
              <>
                <span className="nav-icon-holder">
                  <i className={prop.icon} />
                  <FontAwesomeIcon icon={prop.faIcon} />
                </span>
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="nav-icon-holder">
                  <i className={prop.icon}></i>
                  <FontAwesomeIcon icon={prop.faIcon} />
                </span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const getRolesNotPermitted = (permissions) => {
    let permissionsNotAllowed = [];
    let adminPermissions = [];
    for (const [key, value] of Object.entries(permissions)) {
      if (key !== "_id" && key !== "status" && key !== "title") {
        adminPermissions.push({ key: `${key}`, value: `${value}` });
      }
    }
    adminPermissions.map((per) => {
      if (per.value === "false") {
        permissionsNotAllowed.push(per.key);
      }
    });
    return permissionsNotAllowed;
  };

  const getRoutesNotPermitted = (rolesNotPermitted) => {
    let notPermittedRoutes = [];
    rolesNotPermitted.map((roleNotPermitted) => {
      permissionsForComponents.map((perC) => {
        if (roleNotPermitted === perC.role) {
          notPermittedRoutes.push(perC.component);
        }
      });
    });
    return notPermittedRoutes;
  };

  const navbarHandler = () => {
    $("html").removeClass("nav-open");
  };

  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-end justify-content-center">
            <Link className="logo-mini" to="/">
              <div className="logo-img">
                <img src={Images.LogoFav} alt="Biiview Logo" />
              </div>
            </Link>
            <Link className="simple-text logo-normal" to="/">
              <img src={Images.Logo} alt="Biiview Logo" />
            </Link>
            <span className="nav-icon-holder cross-icon" onClick={() => navbarHandler()}>
              <i className="fa fa-times"></i>
            </span>
          </div>
          <Nav as="ul">{createLinks(adminRoutes)}</Nav>
        </div>
      </div>
    </>
  );
};

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: [],
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf(["black", "azure", "green", "orange", "red", "purple"]),
  routes: PropTypes.array,
  permissionsData: PropTypes.object,
};

export default Sidebar;
