import { BEFORE_FAQ, CREATE_PRODUCT, DELETE_PRODUCT, EDIT_FAQ, GET_PRODUCT, GET_FAQS, CREATE_FAQ_CAT, BEFORE_FAQ_CAT, LIST_FAQ_CAT, GET_FAQ_CAT, EDIT_FAQ_CAT, DELETE_FA, GET_PRODUCTQ_CAT, EDIT_PRODUCT, CREATE_PRODUCT_CAT, BEFORE_PRODUCT_CAT, LIST_PRODUCT_CATS, GET_PRODUCT_CAT, EDIT_PRODUCT_CAT, DELETE_PRODUCT_CAT, LIST_PRODUCTS, BEFORE_PRODUCT } from '../../redux/types';

const initialState = {
    listProducts: null,
    listProductsAuth: false,

    getProduct: null,
    getProductAuth: false,

    deleteProductAuth: false,

    createProductAuth: false,

    editProductAuth: false,

    listProductCategories: null,
    listProductCategoriesAuth: false,

    getProductCategory: null,
    getProductCategoryAuth: false,

    deleteProductCategoryAuth: false,

    createProductCategoryAuth: false,

    editProductCategoryAuth: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            return {
                ...state,
                getProduct: action.payload.getProduct,
                getProductAuth: true
            }
        case CREATE_PRODUCT:
            return {
                ...state,
                createProductAuth: true
            }
        case LIST_PRODUCTS:
            return {
                ...state,
                listProducts: action.payload,
                listProductsAuth: true
            }
        case EDIT_PRODUCT:
            return {
                ...state,
                editProductAuth: true
            }
        case DELETE_PRODUCT:
            return {
                ...state,
                deleteProductAuth: true
            }
        case BEFORE_PRODUCT:
            return {
                ...state,
                listProducts: null,
                listProductsAuth: false,

                getProduct: null,
                getProductAuth: false,

                deleteProductAuth: false,

                createProductAuth: false,

                editProductAuth: false,

            }
        case CREATE_PRODUCT_CAT:
            return {
                ...state,
                createProductCategoryAuth: true
            }
        case LIST_PRODUCT_CATS:
            return {
                ...state,
                listProductCategories: action.payload,
                listProductCategoriesAuth: true
            }
        case GET_PRODUCT_CAT:
            return {
                ...state,
                getProductCategory: action.payload,
                getProductCategoryAuth: true
            }
        case EDIT_PRODUCT_CAT:
            return {
                ...state,
                editProductCategoryAuth: true
            }
        case DELETE_PRODUCT_CAT:
            return {
                ...state,
                deleteProductCategoryAuth: true,
            }
        case BEFORE_PRODUCT_CAT:
            return {
                ...state,
                listProductCategories: null,
                listProductCategoriesAuth: false,

                getProductCategory: null,
                getProductCategoryAuth: false,

                deleteProductCategoryAuth: false,

                createProductCategoryAuth: false,

                editProductCategoryAuth: false,

            }
        default:
            return {
                ...state
            }
    }
}