import { toast } from 'react-toastify';
import { BEFORE_BLOG_POST, CREATE_POST, LIST_POSTS, EDIT_POST, GET_POST, DELETE_POST } from '../../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `blogs/posts`

export const beforeBlogPost = () => {
    return {
        type: BEFORE_BLOG_POST
    }
}

export const createBlogPost = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'POST',
        body,
        actionType: CREATE_POST,
    })
};

export const listBlogPosts = (qs = '', showToast = true) => dispatch => {
    if (showToast) toast.dismiss();

    let url = `${apiUrl}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: LIST_POSTS,
        showToast
    })
};

export const editBlogPost = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'PUT',
        body,
        actionType: EDIT_POST,
    })
};


export const getBlogPost = (id, showToast = true) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${id}`,
        method: 'GET',
        actionType: GET_POST,
        showToast
    })
};

export const deleteBlogPost = (id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${id}`,
        method: 'DELETE',
        actionType: DELETE_POST,
    })
};