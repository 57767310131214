import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import { ENV } from "../../../config/config";
import { toast } from "react-toastify";
import {
  beforeBlogCategory,
  listBlogCategories,
} from "../blogCategories/blogCategories.actions";
import {
  beforeBlog,
  createBlog,
  editBlog,
  deleteBlog,
  getBlog,
  listBlogs,
} from "./blogs.actions";
import Select from "react-select";
import { fileValidation } from "utils/validations/validations";
import { removeExtraSpaces } from "utils/validations/validations";
const placeholderImg =
  "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658297644/hex-nft/assets/placeholder_ogfxwf.png";

const { objectToQueryString, allowedMediaTypes } = ENV;

const BlogListings = () => {
  const dispatch = useDispatch();

  const [blogs, setBlogs] = useState(null);
  const [blog, setBlog] = useState({
    title: "",
    description: "",
    categoryId: "",
    featuredImage: "",
    blogImage: ""
  });
  const [blogCategories, setCategories] = useState([]);
  const [image, setImage] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [loader, setLoader] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [resetButton, setResetButton] = useState(false);
  const [filters, setFilters] = useState({
    categoryId: "",
    title: "",
  });
  const [modalType, setModalType] = useState(0); // 1 = add, 2 = edit
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    categoryId: "",
    featuredImage: "",
  });
  const errorSelector = useSelector((state) => state.error);
  const roleselector = useSelector((state) => state.role.getRoleRes);
  const blogsSelector = useSelector((state) => state.blogs);

  useEffect(() => {
    window.scroll(0, 0);

    if (localStorage.getItem("userID")) {
      const qs = objectToQueryString({ all: true });

      dispatch(listBlogCategories(qs, false));
      dispatch(listBlogs());
    }
  }, []);

  useEffect(() => {
    if (errorSelector.error) setLoader(false);
  }, [errorSelector.error]);

  useEffect(() => {
    if (Object.keys(roleselector).length > 0) {
      setPermissions(roleselector.role);
      dispatch(beforeRole());
    }
  }, [roleselector]);

  useEffect(() => {
    if (blogsSelector.blogCategoriesAuth) {
      let { categories } = blogsSelector.blogCategoriesRes?.data;
      let options = [];
      if (categories?.length) {
        categories.map((c) => {
          options.push({ value: c._id, label: c.category });
        });
      }
      setCategories(options);
      dispatch(beforeBlogCategory());
    }
  }, [blogsSelector.blogCategoriesAuth]);

  useEffect(() => {
    if (blogsSelector.blogsAuth) {
      let { blogs, pagination } = blogsSelector.blogsRes?.data;
      setBlogs(blogs);
      setPagination(pagination);
      setLoader(false);
      dispatch(beforeBlog());
    }
  }, [blogsSelector.blogsAuth]);

  useEffect(() => {
    if (blogsSelector.getBlogAuth) {
      let blogData = blogsSelector.getBlogRes?.blog;
      setBlog(blogData);
      setImage(blogData?.featuredImage);
      dispatch(beforeBlog());
    }
  }, [blogsSelector.getBlogAuth]);

  useEffect(() => {
    let { editBlogAuth, deleteBlogAuth, createBlogAuth } = blogsSelector;
    if (editBlogAuth || deleteBlogAuth || createBlogAuth) {
      dispatch(listBlogs("", false));
    }
  }, [
    blogsSelector.editBlogAuth,
    blogsSelector.deleteBlogAuth,
    blogsSelector.createBlogAuth,
  ]);

  const deleteBlogHandler = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete this blog, all its posts will also be deleted.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        dispatch(deleteBlog(id));
      }
    });
  };

  const onPageChange = async (page) => {
    setLoader(true);
    const qs = objectToQueryString({ page, ...filters });
    dispatch(listBlogs(qs));
  };

  const applyFilters = () => {
    if ((filters && filters.title) || (filters && filters.categoryId)) {
      setResetButton(true);
      const qs = objectToQueryString({ page: 1, limit: 10, ...filters });
      dispatch(listBlogs(qs));
      setLoader(true);
    } else {
      toast.error("All filter fields are empty", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const reset = () => {
    setResetButton(false);
    setFilters({ title: "", categoryId: "" });
    dispatch(listBlogs());
    setLoader(true);
  };

  const submit = () => {
    let categoryIdErr = "";
    let titleErr = "";
    let imgErr = "";
    let msg = "This field is required";
    let payload = { ...blog };

    if (!payload.categoryId) categoryIdErr = msg;

    if (!payload.title) titleErr = msg;

    if (!payload.featuredImage && !payload.blogImage) imgErr = msg;

    setErrors({
      categoryId: categoryIdErr,
      title: titleErr,
      featuredImage: imgErr,
    });

    if (categoryIdErr || titleErr || imgErr) return;

    payload.title = removeExtraSpaces(payload.title);

    let formData = new FormData();
    for (const key in payload) formData.append(key, payload[key]);

    setLoader(true);
    setModalType(0);
    dispatch(modalType === 2 ? createBlog(formData) : editBlog(formData));
  };

  const modalCloseHandler = () => {
    setOpenModal(!openModal);
    resetModalContent();
    setModalType(0);
  };

  const resetModalContent = () => {
    setBlog({ title: "", description: "", categoryId: "", featuredImage: "" });
    setImage("");
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileErr = fileValidation(file, allowedMediaTypes.images);
      if (fileErr) {
        toast.error(
          `Supported File Types: ${allowedMediaTypes.images.join(", ")}`,
        );
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let result = e.target.result;
        setImage(result);
        setBlog({ ...blog, blogImage: file });
      };
    }
  };

  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row className="pb-3">
            <Col sm={12}>
              <Card className="filter-card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Title</label>
                        <Form.Control
                          value={filters.title}
                          type="text"
                          placeholder="Blog Title"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              title: e.target.value.trim(),
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Category</label>
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Category"
                          className="w-100"
                          options={blogCategories}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              categoryId: e ? e.value : "",
                            })
                          }
                          value={blogCategories.filter(
                            (c) => c.value === filters.categoryId,
                          )}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                        <div className="d-flex  filter-btns-holder">
                          <Button
                            className="btn-filled me-3"
                            onClick={applyFilters}
                          >
                            Search
                          </Button>
                          {resetButton && (
                            <Button
                              variant="warning"
                              className="outline-button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="table-outer">
                <Card>
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title as="h4">Blogs</Card.Title>
                      {permissions && permissions.addBlog && (
                        <Button
                          className="float-sm-right btn-filled d-flex align-items-center"
                          onClick={() => {
                            resetModalContent();
                            setModalType(2);
                            setOpenModal(true);
                          }}
                        >
                          <span className="add-icon me-2">
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                          Add Blog
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <div className="table-responsive">
                      <Table className="custom-table">
                        <thead>
                          <tr>
                            <th className="td-start serial-col">#</th>
                            <th className="td-name td-image">
                              Image
                            </th>
                            <th className="td-name">
                              Title
                            </th>
                            <th className="td-name">
                              <div className="faqs-title td-name">Category</div>
                            </th>
                            <th className="td-description">
                              <div className="faqs-title">Description</div>
                            </th>
                            <th className="td-actions text-center">
                              <div className="faqs-title">Actions</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {blogs && blogs.length ? (
                            blogs.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="serial-col">
                                    {pagination &&
                                      pagination.limit * pagination.page -
                                      pagination.limit +
                                      index +
                                      1}
                                  </td>
                                  <td className=" td-image">
                                    <div className="logo-holder d-inline-block align-top">
                                      <img
                                        src={
                                          item.featuredImage
                                            ? item.featuredImage
                                            : placeholderImg
                                        }
                                        className="user-image"
                                        alt="cover"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="faq-title td-name">
                                      {item.title}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="faqs-title td-name">
                                      {item.blogCategory.name}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="faq-title td-description">
                                      {item.description ? item.description : ""}
                                    </div>
                                  </td>
                                  {(permissions?.viewBlogs ||
                                    permissions?.editBlog ||
                                    permissions?.deleteBlog) && (
                                      <td className="td-actions">
                                        <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                          {permissions?.viewBlogs && (
                                            <li className="d-inline-block align-top">
                                              <OverlayTrigger
                                                overlay={renderViewTooltip}
                                                placement="left"
                                                delay={{ show: 150, hide: 200 }}
                                              >
                                                <Button
                                                  className="btn-link btn-icon"
                                                  type="button"
                                                  variant="primary"
                                                  onClick={() => {
                                                    dispatch(getBlog(item._id));
                                                    setModalType(1);
                                                    setOpenModal(true);
                                                  }}
                                                >
                                                  <i className="fas fa-eye"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </li>
                                          )}
                                          {permissions?.editBlog && (
                                            <li className="d-inline-block align-top">
                                              <OverlayTrigger
                                                overlay={renderEditTooltip}
                                                placement="left"
                                                delay={{ show: 150, hide: 200 }}
                                              >
                                                <Button
                                                  className="btn-link btn-icon"
                                                  type="button"
                                                  variant="success"
                                                  onClick={() => {
                                                    dispatch(getBlog(item._id));
                                                    resetModalContent();
                                                    setModalType(3);
                                                    setOpenModal(true);
                                                  }}
                                                >
                                                  <i className="fas fa-edit"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </li>
                                          )}
                                          {permissions?.deleteBlog && (
                                            <li className="d-inline-block align-top">
                                              <OverlayTrigger
                                                overlay={renderDeleteTooltip}
                                                placement="left"
                                                delay={{ show: 150, hide: 200 }}
                                              >
                                                <Button
                                                  className="btn-link btn-icon"
                                                  type="button"
                                                  variant="danger"
                                                  onClick={() =>
                                                    deleteBlogHandler(item._id)
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </li>
                                          )}
                                        </ul>
                                      </td>
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                <div className="alert alert-info" role="alert">
                                  No Blog Found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={pagination.page} // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {modalType > 0 && blog && (
            <Modal
              className="modal-primary edit-cotnact-modal"
              onHide={modalCloseHandler}
              show={openModal}
            >
              <Modal.Header className="justify-content-center">
                <Row>
                  <div className="col-12">
                    <h4 className="mb-0 mb-md-3 mt-0">
                      {modalType === 1
                        ? "View"
                        : modalType === 2
                          ? "Add"
                          : modalType === 3
                            ? "Edit"
                            : ""}{" "}
                      Blog
                    </h4>
                  </div>
                </Row>
              </Modal.Header>
              <Modal.Body>
                <Form className="text-left">
                  {(modalType === 2 || modalType === 3) && (
                  <Form.Group  className="mb-3">
                      <label className=" mb-2">
                        Featured/Cover Image{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        placeholder="Image"
                        type="file"
                        varient="info"
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => onFileChange(e)}
                        id="featuredImage"
                      ></Form.Control>
                      <span
                        className={
                          errors && errors.featuredImage ? `` : `d-none`
                        }
                      >
                        <label className="pl-1 text-danger">
                          {errors && errors.featuredImage
                            ? errors.featuredImage
                            : ""}
                        </label>
                      </span>
                    </Form.Group>
                  )}
                  {image && (
                  <Form.Group  className="mb-3">
                      <img
                        src={image}
                        id="imageUploader"
                        className="image-fluid"
                        style={{ width: "30%", height: "30%" }}
                        alt="blog-image"
                      />
                    </Form.Group>
                  )}
                <Form.Group  className="mb-3">
                    <label className=" mb-2">
                      Blog Title <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      placeholder="Enter title"
                      type="text"
                      name="title"
                      onChange={(e) =>
                        setBlog({ ...blog, title: e.target.value })
                      }
                      value={blog.title}
                      required
                      disabled={modalType === 1}
                    />
                    <span className={errors && errors.title ? `` : `d-none`}>
                      <label className="pl-1 text-danger">
                        {errors && errors.title ? errors.title : ""}
                      </label>
                    </span>
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">
                      Category <span className="text-danger">*</span>
                    </label>
                    <div className="select-items">
                      <Select
                        classNamePrefix="theme-select"
                        placeholder="Select Category"
                        className="w-100"
                        options={blogCategories}
                        onChange={(e) =>
                          setBlog({ ...blog, categoryId: e ? e.value : "" })
                        }
                        value={blogCategories.filter(
                          (l) => l.value === blog.categoryId,
                        )}
                        isDisabled={modalType === 1}
                        isClearable
                      />
                    </div>
                    <span
                      className={errors && errors.categoryId ? `` : `d-none`}
                    >
                      <label className="pl-1 text-danger">
                        {errors && errors.categoryId ? errors.categoryId : ""}
                      </label>
                    </span>
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">Description</label>
                    <Form.Control
                      placeholder="Enter description"
                      as="textarea"
                      rows={5}
                      name="description"
                      onChange={(e) =>
                        setBlog({ ...blog, description: e.target.value })
                      }
                      value={blog.description}
                      disabled={modalType === 1}
                    />
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">Featured</label>
                    <div className="d-flex pt-3">
                      <label className="right-label-radio me-3 mb-2">
                        Yes
                        <input
                          name="isFeatured"
                          type="radio"
                          checked={blog.isFeatured}
                          value={blog.isFeatured}
                          onChange={(e) => {
                            setBlog({ ...blog, isFeatured: true });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio me-3 mb-2">
                        No
                        <input
                          name="isFeatured"
                          type="radio"
                          checked={!blog.isFeatured}
                          value={!blog.isFeatured}
                          onChange={(e) => {
                            setBlog({ ...blog, isFeatured: false });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button className="outline-button" onClick={modalCloseHandler}>
                  Close
                </Button>
                {(modalType === 2 || modalType === 3) && (
                  <Button className="btn-filled" onClick={submit}>
                    {" "}
                    Submit
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      )}
    </>
  );
};

export default BlogListings;
