import { toast } from 'react-toastify';
import { GET_SETTINGS, EDIT_SETTINGS, BEFORE_SETTINGS } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeSettings = () => {
    return {
        type: BEFORE_SETTINGS
    }
}

export const getSettings = (showToast = true) => dispatch => {
    toast.dismiss()

    generalHttpRequest({
        url: `settings`,
        method: 'GET',
        actionType: GET_SETTINGS,
        showToast
    })
};

export const editSettings = (body, qs = '',) => dispatch => {
    let url = `settings`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'PUT',
        actionType: EDIT_SETTINGS,
        body,
    })
};
