// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// ADMIN
export const ADD_ADMIN = 'ADD_ADMIN';
export const BEFORE_ADMIN = 'BEFORE_ADMIN';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';


// ROLES
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const BEFORE_ROLE = 'BEFORE_ROLE';
export const BEFORE_PERMISSION = 'BEFORE_PERMISSION';
export const GET_PERMISSION = 'GET_PERMISSION';
export const SET_LOADER_ROLE = 'SET_LOADER_ROLE';
export const REMOVE_LOADER_ROLE = 'REMOVE_LOADER_ROLE';


// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const UPSERT_USER = 'UPSERT_USER';
export const DELETE_USER = 'DELETE_USER';

// Emails
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const BEFORE_EMAIL = 'BEFORE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPSERT_EMAIL = 'UPSERT_EMAIL';
export const ADD_EMAIL = 'ADD_EMAIL';
export const DELETE_EMAIL = 'DELETE'

// Settings
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';

// FAQS
export const GET_FAQS = 'GET_FAQS'
export const BEFORE_FAQ = 'BEFORE_FAQ'
export const DELETE_FAQ = 'DELETE_FAQ'
export const CREATE_FAQ = 'CREATE_FAQ'
export const GET_FAQ = 'GET_FAQ'
export const EDIT_FAQ = 'EDIT_FAQ'

// CONTACTS
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const GET_CONTACTS = 'GET_CONTACTS';
export const EDIT_CONTACT = 'EDIT_CONTACT';

// DASHBOARD
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD'
export const GET_DASHBOARD = 'GET_DASHBOARD'

//CONTENT MANAGEMENT
export const GET_CONTENT_PAGE = 'GET_CONTENT_PAGE'
export const GET_CONTENT_PAGES = 'GET_CONTENT_PAGES'
export const EDIT_CONTENT_PAGE = 'EDIT_CONTENT_PAGE'
export const DELETE_CONTENT_PAGE = 'DELETE_CONTENT_PAGE'
export const ADD_CONTENT_PAGE = 'ADD_CONTENT_PAGE'
export const BEFORE_CONTENT = 'BEFORE_CONTENT'

// NEWSLETTER 
export const LIST_NEWSLETTERS = 'LIST_NEWSLETTERS'
export const BEFORE_NEWSLETTERS = 'BEFORE_NEWSLETTERS'
export const SEND_NEWS_LETTER_EMAILS = 'SEND_NEWS_LETTER_EMAILS'

// FAQ_CAT
export const CREATE_FAQ_CAT = 'CREATE_FAQ_CAT'
export const BEFORE_FAQ_CAT = 'BEFORE_FAQ_CAT'
export const LIST_FAQ_CAT = 'LIST_FAQ_CAT'
export const GET_FAQ_CAT = 'GET_FAQ_CAT'
export const EDIT_FAQ_CAT = 'EDIT_FAQ_CAT'
export const DELETE_FAQ_CAT = 'DELETE_FAQ_CAT'


// BLOGS

// BLOG CATEGORIES
export const CREATE_BLOG_CAT = 'CREATE_BLOG_CAT'
export const LIST_BLOG_CATS = 'LIST_BLOG_CATS'
export const GET_BLOG_CAT = 'GET_BLOG_CAT'
export const EDIT_BLOG_CAT = 'EDIT_BLOG_CAT'
export const DELETE_BLOG_CAT = 'DELETE_BLOG_CAT'
export const BEFORE_BLOG_CAT = 'BEFORE_BLOG_CAT'


// BLOGS 
export const CREATE_BLOG = 'CREATE_BLOG'
export const LIST_BLOGS = 'LIST_BLOGS'
export const GET_BLOG = 'GET_BLOG'
export const EDIT_BLOG = 'EDIT_BLOG'
export const DELETE_BLOG = 'DELETE_BLOG'
export const BEFORE_BLOG = 'BEFORE_BLOG'


// BLOG POSTS
export const CREATE_POST = 'CREATE_POST'
export const LIST_POSTS = 'LIST_POSTS'
export const EDIT_POST = 'EDIT_POST'
export const DELETE_POST = 'DELETE_POST'
export const GET_POST = 'GET_POST'
export const BEFORE_BLOG_POST = 'BEFORE_BLOG_POST'

// NOTIFICATIONS
export const SET_SOCKET = 'SET_SOCKET'
export const BEFORE_SOCKET = 'BEFORE_SOCKET'
export const SET_COUNTER = 'SET_COUNTER'
export const BEFORE_COUNTER = 'BEFORE_COUNTER'
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS'
export const BEFORE_NOTIFICATIONS = 'BEFORE_NOTIFICATIONS'
export const EDIT_NOTIFICATION_STATUS = 'EDIT_NOTIFICATION_STATUS'
export const BEFORE_NOTIFICATION_STATUS = 'BEFORE_NOTIFICATION_STATUS'

// PRODUCTS

// PRODUCT CATEGORIES
export const CREATE_PRODUCT_CAT = 'CREATE_PRODUCT_CAT'
export const LIST_PRODUCT_CATS = 'LIST_PRODUCT_CATS'
export const GET_PRODUCT_CAT = 'GET_PRODUCT_CAT'
export const EDIT_PRODUCT_CAT = 'EDIT_PRODUCT_CAT'
export const DELETE_PRODUCT_CAT = 'DELETE_PRODUCT_CAT'
export const BEFORE_PRODUCT_CAT = 'BEFORE_PRODUCT_CAT'

// PRODUCT LISTING
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const LIST_PRODUCTS = 'LIST_PRODUCTS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const BEFORE_PRODUCT = 'BEFORE_PRODUCT'



