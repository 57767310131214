import { BEFORE_EMAIL, GET_EMAILS, UPSERT_EMAIL, GET_EMAIL, ADD_EMAIL, DELETE_EMAIL } from '../../redux/types';
import { ENV } from '../../config/config';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `emails`

export const beforeEmail = () => {
    return {
        type: BEFORE_EMAIL
    }
}

export const getEmails = (qs = null) => dispatch => {

    let url = `${apiUrl}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: GET_EMAILS,
    })
};

export const getEmail = (emailId, qs = null) => dispatch => {

    const url = `${apiUrl}/${emailId}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: GET_EMAIL,
    })
};

export const updateEmail = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'PUT',
        body,
        actionType: UPSERT_EMAIL,
    })
};

export const addEmail = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'POST',
        body,
        actionType: ADD_EMAIL,
    })
};

export const delEmail = (emailId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${emailId}`,
        method: 'DELETE',
        actionType: DELETE_EMAIL,
    })
};
