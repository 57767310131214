import { toast } from 'react-toastify';
import { BEFORE_FAQ, CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, GET_FAQ, GET_Products, CREATE_FAQ_CAT, BEFORE_FAQ_CAT, LIST_FAQ_CAT, GET_FAQ_CAT, EDIT_FAQ_CAT, DELETE_FAQ_CAT, BEFORE_PRODUCT, GET_PRODUCT, LIST_PRODUCTS, EDIT_PRODUCT, DELETE_PRODUCT, CREATE_PRODUCT, BEFORE_PRODUCT_CAT, CREATE_PRODUCT_CAT, LIST_PRODUCT_CATS, GET_PRODUCT_CAT, EDIT_PRODUCT_CAT, DELETE_PRODUCT_CAT } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

const apiUrlProductsCat = `products/categories`
const apiUrlProducts = `products`

export const beforeProduct = () => {
    return {
        type: BEFORE_PRODUCT
    }
}

export const getProducts = (qs = '', body = {}) => dispatch => {
    toast.dismiss();

    let url = `${apiUrlProducts}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        body,
        actionType: LIST_PRODUCTS,
    })
};

export const updateProduct = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProducts}`,
        method: 'PUT',
        body,
        actionType: EDIT_PRODUCT,
    })
};

export const deleteProduct = (faqId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProducts}/${faqId}`,
        method: 'DELETE',
        actionType: DELETE_PRODUCT,
    })
};

export const addProduct = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProducts}`,
        method: 'POST',
        body,
        actionType: CREATE_PRODUCT,
    })
};

export const getProduct = (faqId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProducts}/${faqId}`,
        method: 'GET',
        actionType: GET_PRODUCT,
    })
};

// Product Categories

export const beforeProductCategory = () => {
    return {
        type: BEFORE_PRODUCT_CAT
    }
}

export const addProductCategory = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProductsCat}`,
        method: 'POST',
        body,
        actionType: CREATE_PRODUCT_CAT,
    })
};

export const listProductCategories = (qs = '', showToast = true) => dispatch => {
    toast.dismiss();

    let url = `${apiUrlProductsCat}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        showToast,
        actionType: LIST_PRODUCT_CATS,
    })
};

export const getProductCategory = (faqCatId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProductsCat}/${faqCatId}`,
        method: 'GET',
        actionType: GET_PRODUCT_CAT,
    })
};

export const editProductCategory = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProductsCat}`,
        method: 'PUT',
        body,
        actionType: EDIT_PRODUCT_CAT,
    })
};

export const deleteProductCategory = (faqCatId) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlProductsCat}/${faqCatId}`,
        method: 'DELETE',
        actionType: DELETE_PRODUCT_CAT,
    })
};