import { combineReducers } from 'redux'
import adminReducer from '../views/admin/admin.reducer'
import rolesReducer from '../views/adminStaff/permissions/permissions.reducer'
import userReducer from 'views/users/users.reducer'
import errorReducer from './shared/error/error.reducer'
import emailReducer from '../views/emailTemplates/emailTemplates.reducer'
import settingsReducer from '../views/settings/settings.reducer'
import faqReducer from 'views/faqs/faqs.reducer'
import contactsReducer from 'views/contacts/contacts.reducer'
import dashboardReducer from 'views/dashboard/dashboard.reducer'
import contentManagementReducer from 'views/contentManagement/cms.reducer'
import newsLetterReducer from 'views/newsletter/newsletter.reducer'
import blogsReducer from 'views/blogs/blogs.reducer'
import notificationsRed from 'views/notifications/notifications.reducer'
import productsReducer from 'views/products/products.reducer'

export default combineReducers({
    admin: adminReducer,
    role: rolesReducer,
    user: userReducer,
    error: errorReducer,
    email: emailReducer,
    settings: settingsReducer,
    faqs: faqReducer,
    contacts: contactsReducer,
    dashboard: dashboardReducer,
    content: contentManagementReducer,
    newsletter: newsLetterReducer,
    blogs: blogsReducer,
    notifications: notificationsRed, 
    products: productsReducer
})