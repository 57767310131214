import React, { useState, useEffect } from "react";
import validator from "validator";
import { updateUser, beforeUser, createUser } from "./users.actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import {
  Button,
  Form,
  Container,
  Row,
  Modal,
  FormGroup,
} from "react-bootstrap";
import { validateName } from "utils/validations/validations";
import { removeExtraSpaces } from "utils/validations/validations";
import { validatePassword } from "utils/validations/validations";
import { validateAge } from "utils/validations/validations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const userModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());

  const [user, setUser] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    status: "",
  });
  const [msg, setMsg] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: "",
  });

  useEffect(() => {
    setUser(props.userData);
  }, [props.user]);

  const onCloseHandler = () => {
    props.setUserModal(!props.userModal);
    setUser({});
    setMsg({});
  };

  const submit = (e) => {
    let check = true;

    let fullName = "";
    let email = "";
    let password = "";
    let dob = "";
    let confirmPassword = "";
    let type = "";

    if (!user.fullName) {
      fullName = "Name is Required";
      check = false;
    } else {
      fullName = validateName(user.fullName);
    }

    if (!user.email) {
      check = false;
      email = "Email is Required";
    } else {
      if (!validator.isEmail(user.email)) {
        setEmailMsg("Please enter a valid email address");
        check = false;
      }
    }

    if (!user.password && user.confirmPassword) {
      check = false;
      password = "Password is required";
      confirmPassword = "";
    }

    if (!user.confirmPassword && user.password) {
      check = false;
      confirmPassword = "Confirm Password is required";
    }

    if (
      user.password &&
      user.confirmPassword &&
      user.password !== user.confirmPassword
    ) {
      check = false;
      confirmPassword = "Passwords do not match";
    }

    if (user.password) {
      password = validatePassword(user.password);
      if (password) check = false;
    }

    if (!user.dob) {
      check = false;
      dob = "Date of Birth is required";
    } else {
      dob = validateAge(user.dob);
      if (dob) check = false;
    }

    setMsg({
      fullName,
      email,
      dob,
      password,
      confirmPassword,
      type,
    });

    let name = removeExtraSpaces(user.fullName);

    if (check) {
      let payload = { ...user };
      payload.fullName = name;
      delete payload.confirmPassword;
      props.modalType === 2
        ? props.updateUser(payload)
        : props.createUser(payload);
      props.setLoader(true);
    } else {
      $("#modal-primary").scrollTop(0, 0);
    }
  };

  useEffect(() => {
    if (props.modalType === 3)
      setMsg({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        type: "",
      });
  }, [props.modalType]);

  return (
    <Container fluid>
      {props.modalType > 0 && (
        <Modal
          className="modal-primary"
          id="admin-modal"
          onHide={() => onCloseHandler()}
          show={props.userModal}
        >
          <Modal.Header className="justify-content-center">
            <Row>
              <div className="col-12">
                <h4 className="mb-0 mb-md-3 mt-0">
                  {props.modalType === 1
                    ? "View"
                    : props.modalType === 2
                      ? "Edit"
                      : "Add"}{" "}
                  User
                </h4>
              </div>
            </Row>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form className="date-picker-form">
              <Form.Group className="mb-3">
                <label className=" mb-2">
                  Full Name <span className="text-danger">*</span>
                </label>
                <Form.Control
                  placeholder="Enter first name"
                  disabled={props.modalType === 1}
                  type="text"
                  name="fullName"
                  onChange={(e) =>
                    setUser({ ...user, fullName: e.target.value })
                  }
                  value={user && user.fullName}
                  required
                />
                <span className={msg.fullName ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{msg.fullName}</label>
                </span>
              </Form.Group>
              <Form.Group className="mb-3">
                <label className=" mb-2">
                  Email <span className="text-danger">*</span>
                </label>
                <Form.Control
                  placeholder="xyz@example.com"
                  disabled={props.modalType === 1}
                  type="text"
                  name="email"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  value={user && user.email}
                  required
                />
                <span className={msg.email ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{msg.email}</label>
                </span>
              </Form.Group>
              {props.modalType !== 1 ? (
                <Form.Group className="mb-3">
                  <Form.Group className="mb-3">
                    <label className=" mb-2">Password </label>
                    <Form.Control
                      placeholder="password"
                      disabled={props.modalType === 1}
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                      value={user && user.password}
                      required
                    />
                    <span className={msg.password ? `` : `d-none`}>
                      <label className="pl-1 text-danger">{msg.password}</label>
                    </span>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <label className=" mb-2">Confirm Password </label>
                    <Form.Control
                      placeholder="confirmPassword"
                      disabled={props.modalType === 1}
                      type="password"
                      autoComplete="off"
                      name="confirmPassword"
                      onChange={(e) =>
                        setUser({ ...user, confirmPassword: e.target.value })
                      }
                      value={user && user.confirmPassword}
                      required
                    />
                    <span className={msg.confirmPassword ? `` : `d-none`}>
                      <label className="pl-1 text-danger">
                        {msg.confirmPassword}
                      </label>
                    </span>
                  </Form.Group>
                </Form.Group>
              ) : null}
              <Form.Group className="mb-3">
                <label className=" mb-2">
                  Date of Birth <span className="text-danger">*</span>
                </label>
                {/* <Form.Control
                  placeholder="Date of Birth"
                  disabled={props.modalType === 1}
                  type="date"
                  name="dob"
                  onChange={(e) => setUser({ ...user, dob: e.target.value })}
                  value={user?.dob ? moment(user.dob).format("YYYY-MM-DD") : ""}
                  required
                /> */}
                <div>
                  <DatePicker className="date-picker form-control w-100" selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <span className={msg.dob ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{msg.dob}</label>
                </span>
              </Form.Group>
              <FormGroup>
                <label className=" mb-2">Status</label>
                <div className="d-flex">
                  <label className="right-label-radio me-3 mb-2">
                    Active
                    <input
                      name="status"
                      disabled={props.modalType === 1}
                      type="radio"
                      checked={user && user.status}
                      value={user && user.status}
                      onChange={(e) => setUser({ ...user, status: true })}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="right-label-radio me-3 mb-2">
                    Inactive
                    <input
                      name="status"
                      disabled={props.modalType === 1}
                      type="radio"
                      checked={user && !user.status}
                      value={user && !user.status}
                      onChange={(e) => setUser({ ...user, status: false })}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="outline-button" onClick={() => onCloseHandler()}>
              Close
            </Button>
            {props.modalType === 1 ? (
              ""
            ) : (
              <Button className="btn-filled" onClick={() => submit()}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUser, beforeUser, createUser })(
  userModal,
);
