import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ENV } from "../../config/config";
import { beforeUser, getUsers, deleteUser } from "./users.actions";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { toast } from "react-toastify";
import moment from "moment";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import UserModal from "./userModalComponent";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { objectToQueryString } = ENV;

const Users = (props) => {
  const [users, setUsers] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState();
  const [loader, setLoader] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [searchEmail, setSearchEmail] = useState("");
  const [searchDate, setSearchDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    window.scroll(0, 0);
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.user.getUserAuth) {
      const { users, pagination } = props.user?.users?.data;
      setUsers(users);
      setPagination(pagination);
      props.beforeUser();
    }
  }, [props.user.getUserAuth]);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setPermissions(props.getRoleRes.role);
      props.beforeRole();
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (props.user.deleteUserAuth) {
      let filtered = users.filter((item) => {
        if (item._id !== props.user.userId) return item;
      });
      setUsers(filtered);
      props.beforeUser();
    }
  }, [props.user.deleteUserAuth]);

  useEffect(() => {
    if (users) {
      setLoader(false);
    }
  }, [users]);

  useEffect(() => {
    if (props.user.upsertUserAuth) {
      const { users } = props.user.users;
      setUsers(users);
      setLoader(false);
      setUserModal(false);
      props.beforeUser();
    }
  }, [props.user.upsertUserAuth]);

  // when an error is received
  useEffect(() => {
    if (props.error.error) setLoader(false);
  }, [props.error.error]);

  const setModal = (type = 0, userId = null) => {
    setModalType(type);
    setUserModal(!userModal);
    if ((type === 1 || type === 2) && userId) getUser(userId);
    else if (type === 3) {
      setUser();
      setUserId();
    }
  };

  const getUser = async (userId) => {
    setLoader(true);
    const userData = await users.find(
      (elem) => String(elem._id) === String(userId),
    );
    if (userData) setUser({ ...userData });
    setUserId(userId);
    setLoader(false);
  };

  const onPageChange = async (page) => {
    const filter = {};
    const body = {};
    if (searchName) {
      filter.fullName = searchName.trim();
    }
    if (searchEmail) {
      filter.email = searchEmail.trim();
      body.email = searchEmail.trim();
    }
    if (searchDate.startDate && searchDate.endDate) {
      filter.startDate = searchDate.startDate;
      filter.endDate = searchDate.endDate;
    }

    setLoader(true);
    const qs = objectToQueryString({ page, ...filter });
    props.getUsers(qs, body);
  };

  const deleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        props.deleteUser(userId);
      }
    });
  };

  const applyFilters = () => {
    const filter = {};
    const body = {};
    if (searchName) {
      filter.fullName = searchName.trim();
    }
    if (searchEmail) {
      filter.email = searchEmail.trim();
      body.email = searchEmail.trim();
    }
    if (searchDate.startDate && searchDate.endDate) {
      filter.startDate = searchDate.startDate;
      filter.endDate = searchDate.endDate;
    }
    if (
      filter.fullName ||
      filter.email ||
      (searchDate.startDate && searchDate.endDate)
    ) {
      setResetButton(true);
      const qs = objectToQueryString({ page: 1, limit: 10, ...filter });
      props.getUsers(qs, body);
      setLoader(true);
    } else {
      toast.error("Add data in at least one field.", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const reset = () => {
    setResetButton(false);
    setSearchEmail("");
    setSearchName("");
    setSearchDate({
      startDate: "",
      endDate: "",
    });
    props.getUsers();
    setLoader(true);
  };
  
  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row className="pb-3">
            <Col sm={12}>
              <Card className="filter-card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Search with Name...</label>
                        <Form.Control
                          value={searchName}
                          type="text"
                          placeholder="Full Name"
                          onChange={(e) => setSearchName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Search with Email...</label>
                        <Form.Control
                          value={searchEmail}
                          type="text"
                          placeholder="Email"
                          onChange={(e) => setSearchEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                        <div className="d-flex  filter-btns-holder">
                          <Button
                            className="btn-filled me-3"
                            onClick={applyFilters}
                          >
                            Search
                          </Button>
                          {resetButton && (
                            <Button
                              variant="warning"
                              className="outline-button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
            <div className="table-outer">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Users</Card.Title>
                    {permissions && permissions?.addUser && (
                      <Button
                        className="float-sm-right btn-filled d-flex align-items-center"
                        onClick={() => {
                          setModal(3);
                        }}
                      >
                        <span className="add-icon me-2">
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                        Add User
                      </Button>
                    )}
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width">
                  <div className="table-responsive">
                    <Table className="custom-table">
                      <thead>
                        <tr>
                          <th className="td-start">#</th>
                          <th className="td-name">Name</th>
                          <th className="td-mail">Email</th>
                          <th className="td-mail">Date of Birth</th>
                          <th className="td-actions text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users && users.length ? (
                          users.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td className="serial-col">
                                  {pagination &&
                                    pagination.limit * pagination.page -
                                    pagination.limit +
                                    index +
                                    1}
                                </td>
                                <td className="td-name">
                                  {user.fullName ? user.fullName : ""}
                                </td>
                                <td className="td-mail">
                                  {user.email ? user.email : "N/A"}
                                </td>
                                <td className="td-mail">
                                  {user.dob
                                    ? moment(user.dob).format("MM-DD-YYYY")
                                    : "N/A"}
                                </td>
                                <td className="td-actions">
                                  <ul className="list-unstyled mb-0 d-flex">
                                    {permissions?.viewUsers ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          overlay={renderViewTooltip}
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                        >
                                          <button
                                            type="button"
                                            className="btn-link btn-icon btn btn-info"
                                            onClick={() =>
                                              setModal(1, user._id)
                                            }
                                          >
                                            <i className="fas fa-eye"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                    {permissions?.editUser ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          overlay={renderEditTooltip}
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                        >
                                          <button
                                            type="button"
                                            className="btn-link btn-icon btn btn-success"
                                            onClick={() =>
                                              setModal(2, user._id)
                                            }
                                          >
                                            <i className="fas fa-edit"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                    {permissions?.deleteUser ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                          overlay={renderDeleteTooltip}
                                        >
                                          <button
                                            type="button"
                                            className="btn-link btn-icon btn btn-danger"
                                            onClick={() => deleteUser(user._id)}
                                          >
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                  </ul>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <div className="alert alert-info" role="alert">
                                No User Found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="pb-4">
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={pagination.page} // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              </div>
            </Col>
          </Row>
          <UserModal
            modalType={modalType}
            userData={user}
            userModal={userModal}
            setUserModal={setUserModal}
            userId={userId}
            setLoader={setLoader}
          />
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

Users.propTypes = {
  beforeUser: PropTypes.func,
  getUsers: PropTypes.func,
  deleteUser: PropTypes.func,
  beforeRole: PropTypes.func,
  getRoleRes: PropTypes.object,
  user: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, {
  beforeUser,
  getUsers,
  deleteUser,
  beforeRole,
})(Users);
