import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const AdminFooter = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <div className="d-flex justify-content-end">
            <p className="copyright text-center">
              {" "}
              &copy; <script>document.write(new Date().getFullYear())</script> All Rights Reserved by <Link to="/">Shafaq Waqas</Link> Company
            </p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default AdminFooter;
