import { toast } from 'react-toastify';
import { BEFORE_ADMIN, LOGIN_ADMIN, GET_ADMIN, ADD_ADMIN, GET_ADMINS, DELETE_ADMIN, UPDATE_ADMIN, UPDATE_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, BEFORE_USER_VERIFY, GET_USER_VERIFY } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `admin`

export const beforeAdmin = () => {
    return {
        type: BEFORE_ADMIN
    }
}

export const login = (body) => dispatch => {

    generalHttpRequest({
        url: 'admin/login',
        method: 'POST',
        actionType: LOGIN_ADMIN,
        body,
        showToast: false
    })
};

export const addStaffAdmin = (body) => dispatch => {
    generalHttpRequest({
        url: 'admin',
        method: 'POST',
        actionType: ADD_ADMIN,
        body,
        showToast: true
    })
}

export const deleteAdmin = (adminId) => dispatch => {
    generalHttpRequest({
        url: `admin/${adminId}`,
        method: 'DELETE',
        actionType: DELETE_ADMIN,
        showToast: true
    })
};

export const getAdmin = (staffId, showToast = false) => dispatch => {
    toast.dismiss()
    generalHttpRequest({
        url: `admin/${staffId}`,
        method: 'GET',
        actionType: GET_ADMIN,
        showToast
    })

};

export const getStaffAdmins = (query = "", showToast = true) => dispatch => {
    toast.dismiss()

    let url = `admin`;
    if (query)
        url = `admin?${query}`;

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: GET_ADMINS,
        showToast
    })
}

export const updateAdmin = (body) => dispatch => {

    generalHttpRequest({
        url: `admin`,
        method: 'PUT',
        body,
        actionType: UPDATE_ADMIN
    })
};

export const updatePassword = (body, method = 'PUT') => dispatch => {

    generalHttpRequest({
        url: `admin/edit-password`,
        method,
        body,
        actionType: UPDATE_PASSWORD,
    })
};

export const forgotPassword = (body) => dispatch => {

    generalHttpRequest({
        url: `admin/forgot-password`,
        method: 'POST',
        body,
        actionType: FORGOT_PASSWORD,
    })
};

export const resetPassword = (body, method = 'PUT') => dispatch => {

    generalHttpRequest({
        url: `admin/reset-password`,
        method,
        body,
        actionType: RESET_PASSWORD,
    })
};
