import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import { ENV } from "../../../config/config";
import { toast } from "react-toastify";
import {
  beforeBlogCategory,
  createBlogCategory,
  editBlogCategory,
  deleteBlogCategory,
  listBlogCategories,
  getBlogCategory,
} from "./blogCategories.actions";
import Select from "react-select";
import { removeExtraSpaces } from "utils/validations/validations";

const { objectToQueryString } = ENV;

const blogCategories = () => {
  const dispatch = useDispatch();

  const [blogCategories, setCategories] = useState(null);
  const [blogCategory, setCategory] = useState({
    category: "",
    description: "",
    status: false,
  });
  const [pagination, setPagination] = useState(null);
  const [loader, setLoader] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [resetButton, setResetButton] = useState(false);
  const [filters, setFilters] = useState({
    category: "",
    status: "",
  });
  const [modalType, setModalType] = useState(0); // 1 = add, 2 = edit
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState({ category: "" });
  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];

  const errorSelector = useSelector((state) => state.error);
  const roleselector = useSelector((state) => state.role.getRoleRes);
  const blogsSelector = useSelector((state) => state.blogs);

  useEffect(() => {
    window.scroll(0, 0);

    if (localStorage.getItem("userID")) {
      const qs = objectToQueryString({ page: 1, limit: 10 });
      dispatch(listBlogCategories(qs));
    }
  }, []);

  useEffect(() => {
    if (errorSelector.error) setLoader(false);
  }, [errorSelector.error]);

  useEffect(() => {
    if (Object.keys(roleselector).length > 0) {
      setPermissions(roleselector.role);
      dispatch(beforeRole());
    }
  }, [roleselector]);

  useEffect(() => {
    if (blogsSelector.blogCategoriesAuth) {
      let { categories, pagination } = blogsSelector.blogCategoriesRes?.data;
      setCategories(categories);
      setPagination(pagination);
      dispatch(beforeBlogCategory());
    }
  }, [blogsSelector.blogCategoriesAuth]);

  useEffect(() => {
    if (blogsSelector.getBlogCategoryAuth) {
      setCategory(blogsSelector.getBlogCategoryRes?.blogCategory);
      dispatch(beforeBlogCategory());
    }
  }, [blogsSelector.getBlogCategoryAuth]);

  useEffect(() => {
    if (blogCategories) {
      setLoader(false);
    }
  }, [blogCategories]);

  useEffect(() => {
    let {
      editBlogCategoryAuth,
      createBlogCategoryAuth,
      deleteBlogCategoryAuth,
    } = blogsSelector;
    if (
      editBlogCategoryAuth ||
      createBlogCategoryAuth ||
      deleteBlogCategoryAuth
    ) {
      dispatch(listBlogCategories("", false));
    }
  }, [
    blogsSelector.editBlogCategoryAuth,
    blogsSelector.createBlogCategoryAuth,
    blogsSelector.deleteBlogCategoryAuth,
  ]);

  const deleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        dispatch(deleteBlogCategory(id));
      }
    });
  };

  const onPageChange = async (page) => {
    setLoader(true);
    const qs = objectToQueryString({ page, ...filters });
    dispatch(listBlogCategories(qs));
  };

  const applyFilters = () => {
    if (!filters || (!filters.category && !filters.status))
      toast.error("All filter fields are empty", {
        toastId: "FIELD_REQUIRED",
      });
    else {
      setResetButton(true);
      const qs = objectToQueryString({ page: 1, limit: 10, ...filters });
      dispatch(listBlogCategories(qs));
      setLoader(true);
    }
  };

  const reset = () => {
    setResetButton(false);
    setFilters({ category: "", status: "" });
    dispatch(listBlogCategories());
    setLoader(true);
  };

  const submit = () => {
    let error = "";
    let payload = { ...blogCategory };

    if (!payload.category) error = "This field is required";

    setErrors({ ...errors, category: error });

    if (error) return;

    payload.category = removeExtraSpaces(payload.category);

    setLoader(true);
    setModalType(0);

    dispatch(
      modalType === 1 ? createBlogCategory(payload) : editBlogCategory(payload),
    );
  };

  const modalCloseHandler = () => {
    setOpenModal(!openModal);
    resetModalContent();
    setModalType(0);
  };

  const resetModalContent = () => {
    setCategory({ category: "", description: "", status: false });
  };


  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row className="pb-3">
            <Col sm={12}>
              <Card className="filter-card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Category Title</label>
                        <Form.Control
                          value={filters.category}
                          type="text"
                          placeholder="Category"
                          onChange={(e) =>
                            setFilters({ ...filters, category: e.target.value })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Status</label>
                    <Form.Group  className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Status"
                          className="w-100"
                          options={statusOptions}
                          onChange={(e) =>
                            setFilters({ ...filters, status: e ? e.value : "" })
                          }
                          value={statusOptions.filter(
                            (s) => s.value === filters.status,
                          )}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                        <div className="d-flex  filter-btns-holder">
                          <Button
                            className="btn-filled me-3"
                            onClick={applyFilters}
                          >
                            Search
                          </Button>
                          {resetButton && (
                            <Button
                              variant="warning"
                              className="outline-button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="table-outer">
                <Card>
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title as="h4">Blog Categories</Card.Title>
                      {permissions && permissions.addBlogCategory && (
                        <Button
                          className="float-sm-right btn-filled d-flex align-items-center"
                          onClick={() => {
                            resetModalContent();
                            setModalType(1);
                            setOpenModal(true);
                          }}
                        >
                          <span className="add-icon me-2">
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                          Add Blog Category
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <div className="table-responsive">
                      <Table className="custom-table">
                        <thead>
                          <tr>
                            <th className="td-start serial-col">#</th>
                            <th className="td-name faqs-title ">
                              <div className="td-name">Category</div>
                            </th>
                            <th className="td-description">
                              <div className="faq-title td-description">
                                Description
                              </div>
                            </th>
                            <th className="td-status">Status</th>
                            {(permissions?.editBlogCategory ||
                              permissions?.deleteBlogCategory) && (
                                <th className="td-actions text-center">Actions</th>
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {blogCategories && blogCategories.length ? (
                            blogCategories.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="serial-col">
                                    {pagination &&
                                      pagination.limit * pagination.page -
                                      pagination.limit +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    <div className="faq-title td-name">
                                      {item.category}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="faq-title td-description">
                                      {item.description
                                        ? item.description
                                        : "N/A"}
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className={`label kyc-badge d-inline-block align-top px-2 pty-1 ${item.status
                                        ? "label-success"
                                        : "label-danger"
                                        }`}
                                    >
                                      {item.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  {(permissions?.editBlogCategory ||
                                    permissions?.deleteBlogCategory) && (
                                      <td className="td-actions">
                                        <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                          {permissions?.editBlogCategory && (
                                            <li className="d-inline-block align-top">
                                              <OverlayTrigger
                                                overlay={renderEditTooltip}
                                                placement="left"
                                                delay={{ show: 150, hide: 200 }}
                                              >
                                                <Button
                                                  className="btn-link btn-icon"
                                                  type="button"
                                                  variant="success"
                                                  onClick={() => {
                                                    dispatch(
                                                      getBlogCategory(item._id),
                                                    );
                                                    resetModalContent();
                                                    setModalType(2);
                                                    setOpenModal(true);
                                                  }}
                                                >
                                                  <i className="fas fa-edit"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </li>
                                          )}
                                          {permissions?.deleteBlogCategory && (
                                            <li className="d-inline-block align-top">
                                              <OverlayTrigger
                                                overlay={renderDeleteTooltip}
                                                placement="left"
                                                delay={{ show: 150, hide: 200 }}
                                              >
                                                <Button
                                                  className="btn-link btn-icon"
                                                  type="button"
                                                  variant="danger"
                                                  onClick={() =>
                                                    deleteCategory(item._id)
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </li>
                                          )}
                                        </ul>
                                      </td>
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                <div className="alert alert-info" role="alert">
                                  No Blog Category Found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={pagination.page} // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {modalType > 0 && blogCategory && (
            <Modal
              className="modal-primary edit-cotnact-modal"
              onHide={modalCloseHandler}
              show={openModal}
            >
              <Modal.Header className="justify-content-center">
                <Row>
                  <div className="col-12">
                    <h4 className="mb-0 mb-md-3 mt-0">
                      {modalType === 1 ? "Add" : modalType === 2 ? "Edit" : ""}{" "}
                      Blog Category
                    </h4>
                  </div>
                </Row>
              </Modal.Header>
              <Modal.Body>
                <Form className="text-left">
                <Form.Group  className="mb-3">
                    <label className=" mb-2">
                      Category Title <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      placeholder="Enter title"
                      type="text"
                      name="category"
                      onChange={(e) =>
                        setCategory({
                          ...blogCategory,
                          category: e.target.value,
                        })
                      }
                      value={blogCategory.category}
                      required
                    />
                    <span className={errors && errors.category ? `` : `d-none`}>
                      <label className="pl-1 text-danger">
                        {errors && errors.category ? errors.category : ""}
                      </label>
                    </span>
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">Description</label>
                    <Form.Control
                      placeholder="Enter description"
                      type="text"
                      name="description"
                      onChange={(e) =>
                        setCategory({
                          ...blogCategory,
                          description: e.target.value,
                        })
                      }
                      value={blogCategory.description}
                    />
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">Highlighted</label>
                    <div className="d-flex pt-3">
                      <label className="right-label-radio me-3 mb-2">
                        Yes
                        <input
                          name="isHighlighted"
                          type="radio"
                          checked={blogCategory.isHighlighted}
                          value={blogCategory.isHighlighted}
                          onChange={(e) => {
                            setCategory({
                              ...blogCategory,
                              isHighlighted: true,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio me-3 mb-2">
                        No
                        <input
                          name="isHighlighted"
                          type="radio"
                          checked={!blogCategory.isHighlighted}
                          value={!blogCategory.isHighlighted}
                          onChange={(e) => {
                            setCategory({
                              ...blogCategory,
                              isHighlighted: false,
                            });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Form.Group>
                <Form.Group  className="mb-3">
                    <label className=" mb-2">Status</label>
                    <div className="d-flex pt-3 align-items-center">
                      <label className="right-label-radio me-3 mb-2">
                        Active
                        <input
                          name="status"
                          type="radio"
                          checked={blogCategory.status}
                          value={blogCategory.status}
                          onChange={(e) => {
                            setCategory({ ...blogCategory, status: true });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="right-label-radio me-3 mb-2">
                        Inactive
                        <input
                          name="status"
                          type="radio"
                          checked={!blogCategory.status}
                          value={!blogCategory.status}
                          onChange={(e) => {
                            setCategory({ ...blogCategory, status: false });
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button className="outline-button" onClick={modalCloseHandler}>
                  Close
                </Button>
                <Button className="btn-filled" onClick={submit}>
                  {modalType === 1 || modalType === 2 ? "Submit" : null}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      )}
    </>
  );
};

export default blogCategories;
