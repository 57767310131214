import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import validator from "validator";
import { addStaffAdmin, updateAdmin } from "views/admin/admin.action";
import $ from "jquery";
import Select from "react-select";
import {
  Button,
  Form,
  Container,
  Row,
  Modal,
  FormGroup,
} from "react-bootstrap";
import { validateName } from "utils/validations/validations";
import { removeExtraSpaces } from "utils/validations/validations";
import PropTypes from "prop-types";

const StaffPermissionModal = (props) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [fullName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPasssword] = useState("");
  const [confirmPassword, setConfirmPass] = useState("");
  const [status, setStatus] = useState(true);
  const [rolesOptions, setOptions] = useState([]);
  const [phone, setPhone] = useState("");
  const [nameMsg, setNameMsg] = useState("");
  const [phoneMsg, setPhoneMsg] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [passwordMsg, setPassMsg] = useState("");
  const [roleMsg, setRoleMsg] = useState("");
  const [confirmPassMsg, setConfirmPassMsg] = useState("");

  const adminSelector = useSelector((state) => state.admin);

  useEffect(() => {
    if (Object.keys(adminSelector.addAdminRes).length > 0) {
      props.setModalType(1);
      setEmpty();
      if (adminSelector.addAdminRes.success) {
        toast.success(adminSelector.addAdminRes.message);
      } else {
        toast.error(adminSelector.addAdminRes.message);
      }
    }
  }, [adminSelector.addAdminRes]);

  useEffect(() => {
    if (Object.keys(adminSelector.updateAdminRes).length > 0) {
      props.setModalType(1);
      setEmpty();
    }
  }, [adminSelector.updateAdminRes]);

  useEffect(() => {
    if (Object.keys(props.admin).length > 0) {
      if (props.modalType === 1) {
        setEmpty();
      } else if (props.modalType === 2 || props.modalType === 3) {
        let { admin } = props.admin;

        setUserId(admin._id);
        setName(admin.fullName);
        setRoleId(admin.roleId);
        setEmail(admin.email);
        setPhone(admin.phone);
        setStatus(admin.status);
        setPasssword("");
      }
    }
  }, [props.admin, props.flag]);

  useEffect(() => {
    if (props.roles) {
      let options = [];
      let { roles } = props;
      if (roles?.length) {
        roles.map((r) => {
          options.push({ value: r._id, label: r.title });
        });
      }
      setOptions(options);
    }
  }, [props.roles]);

  const setEmpty = () => {
    setName("");
    setRoleId("");
    setEmail("");
    setPasssword("");
    setConfirmPass("");
    setPhoneMsg("");
    setStatus(true);

    setNameMsg("");
    setRoleMsg("");
    setEmailMsg("");
    setPassMsg("");
    setConfirmPassMsg("");
  };

  const submit = (e) => {
    let check = true;
    let name = removeExtraSpaces(fullName);

    if (!name || !fullName) {
      setNameMsg("This field is Required.");
      check = false;
    } else {
      let nameError = validateName(name);
      setNameMsg(nameError);
    }
    if (!roleId) {
      setRoleMsg("Please select a role");
      check = false;
    } else {
      setRoleMsg("");
    }
    if (!email) {
      setEmailMsg("This field is Required.");
      check = false;
    } else {
      if (!validator.isEmail(email)) {
        setEmailMsg("Please enter a valid email address");
        check = false;
      } else {
        setEmailMsg("");
      }
    }
    if (!phone) {
      setPhoneMsg("This field is Required.");
      check = false;
    } else {
      if (!validator.isDecimal(phone)) {
        setPhoneMsg("Please enter a valid phone number");
        check = false;
      } else {
        setPhoneMsg("");
      }
    }

    if (!password && props.modalType !== 3) {
      setPassMsg("This field is Required.");
      check = false;
    } else {
      setPassMsg("");
    }

    if (!confirmPassword && props.modalType !== 3) {
      setConfirmPassMsg("This field is Required.");
      check = false;
    } else {
      if (
        !validator.equals(password, confirmPassword) &&
        props.modalType !== 3
      ) {
        setConfirmPassMsg("Passwords do not match");
        check = false;
      } else {
        setConfirmPassMsg("");
      }
    }

    if (check) {
      let payload = { fullName: name, email, password, status, phone, roleId };
      let formData = new FormData();
      for (const key in payload) formData.append(key, payload[key]);
      if (props.modalType === 1) {
        // add modal type
        dispatch(addStaffAdmin(payload));
        props.setLoader(true);
        props.getData(payload);
        props.setroleModal(!props.roleModal);
      } else if (props.modalType === 3) {
        // update modal type
        let payload = { fullName: name, email, status, phone, roleId };
        let formData1 = new FormData();
        for (const key in payload) formData1.append(key, payload[key]);

        formData1.append("_id", userId);
        let checkPwd = true;
        if (!password) {
        } else {
          formData1.append("password", password);
        }
        if (!validator.isEmpty(password)) {
          if (validator.isEmpty(confirmPassword)) {
            setConfirmPassMsg("This field is Required.");
            checkPwd = false;
          } else {
            if (!validator.equals(password, confirmPassword)) {
              setConfirmPassMsg("Passwords do not match");
              checkPwd = false;
            } else {
              setConfirmPassMsg("");
            }
          }
        }
        if (checkPwd) {
          dispatch(updateAdmin(formData1));
          props.setLoader(true);
          props.setroleModal(!props.roleModal);
        }
      }
    } else {
      $("#modal-primary").scrollTop(0, 0);
    }
  };

  useEffect(() =>{
    if(adminSelector.addAdminAuth){
      let { admin } = adminSelector.addAdminRes
      props.getData(admin);
      
    }
  }, [adminSelector.addAdminAuth])

  const onCloseHandler = () => {
    props.setroleModal(!props.roleModal);
    setEmpty();
  };

  return (
    <Container fluid>
      {props.modalType > 0 && (
        <Modal
          className="modal-primary"
          id="admin-modal"
          onHide={() => onCloseHandler()}
          show={props.roleModal}
        >
          <Modal.Header className="justify-content-center">
            <Row>
              <div className="col-12">
                <h4 className="mb-0 mb-md-3 mt-0">
                  {props.modalType === 1
                    ? "Add New"
                    : props.modalType === 2
                    ? "View"
                    : "Edit"}{" "}
                  Staff Role
                </h4>
              </div>
            </Row>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form>
            <Form.Group  className="mb-3">
                <label className="mb-2">
                  Name <span className="text-danger">*</span>
                </label>
                <Form.Control
                  placeholder="Enter name"
                  disabled={props.modalType === 2}
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  value={fullName}
                  required
                />
                <span className={nameMsg ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{nameMsg}</label>
                </span>
              </Form.Group>

            <Form.Group  className="mb-3">
                <label className=" d-block">
                  User Role <span className="text-danger">*</span>
                </label>
                <Select
                  classNamePrefix="theme-select"
                  placeholder="Select Role"
                  className="w-100"
                  name="roleSearch"
                  options={rolesOptions}
                  onChange={(e) => setRoleId(e.value)}
                  value={rolesOptions.filter((r) => r.value === roleId)}
                  disabled={props.modalType === 2}
                />

                <span className={roleMsg ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{roleMsg}</label>
                </span>
              </Form.Group>

            <Form.Group  className="mb-3">
                <label className=" mb-2">
                  Email <span className="text-danger">*</span>
                </label>
                <Form.Control
                  placeholder="xyz@example.com"
                  disabled={props.modalType === 2}
                  type="text"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                <span className={emailMsg ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{emailMsg}</label>
                </span>
              </Form.Group>
              {props.modalType !== 2 ? (
              <Form.Group  className="mb-3">
                <Form.Group  className="mb-3">
                    <label className=" mb-2">
                      Password{" "}
                      <span className="text-danger">
                        {props.modalType === 1 ? "*" : ""}
                      </span>
                    </label>
                    <Form.Control
                      placeholder="password"
                      disabled={props.modalType === 2}
                      type="password"
                      autoComplete="off"
                      name="password"
                      onChange={(e) => setPasssword(e.target.value)}
                      value={password}
                      required
                    />
                    <span className={passwordMsg ? `` : `d-none`}>
                      <label className="pl-1 text-danger">{passwordMsg}</label>
                    </span>
                  </Form.Group>

                <Form.Group  className="mb-3">
                    <label className=" mb-2">
                      Confirm Password{" "}
                      <span className="text-danger">
                        {props.modalType === 1 ? "*" : ""}
                      </span>
                    </label>
                    <Form.Control
                      placeholder="confirmPassword"
                      disabled={props.modalType === 2}
                      type="password"
                      autoComplete="off"
                      name="confirmPassword"
                      onChange={(e) => setConfirmPass(e.target.value)}
                      value={confirmPassword}
                      required
                    />
                    <span className={confirmPassMsg ? `` : `d-none`}>
                      <label className="pl-1 text-danger">
                        {confirmPassMsg}
                      </label>
                    </span>
                  </Form.Group>
                </Form.Group>
              ) : null}

            <Form.Group  className="mb-3">
                <label className="mb-2">
                  Phone <span className="text-danger">*</span>
                </label>
                <Form.Control
                  placeholder="+921111111111"
                  disabled={props.modalType === 2}
                  type="text"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  maxLength={11}
                />
                <span className={phoneMsg ? `` : `d-none`}>
                  <label className="pl-1 text-danger">{phoneMsg}</label>
                </span>
              </Form.Group>
              <FormGroup>
                <label className=" mb-2">Status</label>
                <div className="d-flex align-items-center">
                  <label className="right-label-radio me-3 mb-2">
                    Active
                    <input
                      name="status"
                      disabled={props.modalType === 2}
                      type="radio"
                      checked={status}
                      value={status}
                      onChange={(e) => setStatus(true)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="right-label-radio me-3 mb-2">
                    Inactive
                    <input
                      name="status"
                      disabled={props.modalType === 2}
                      type="radio"
                      checked={!status}
                      value={!status}
                      onChange={(e) => setStatus(false)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="outline-button" onClick={() => onCloseHandler()}>
              Close
            </Button>
            {props.modalType === 2 ? (
              ""
            ) : (
              <Button className="btn-filled" onClick={() => submit()}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

StaffPermissionModal.propTypes = {
  modalType: PropTypes.bool,
  setModalType: PropTypes.func,
  setLoader: PropTypes.func,
  getData: PropTypes.func,
  setroleModal: PropTypes.func,
  roleModal: PropTypes.bool,
  flag: PropTypes.bool,
  error: PropTypes.object,
  admin: PropTypes.object,
  roles: PropTypes.array,
};

export default StaffPermissionModal;
