import {
    CREATE_BLOG_CAT, LIST_BLOG_CATS, GET_BLOG_CAT, EDIT_BLOG_CAT, DELETE_BLOG_CAT,
    BEFORE_BLOG_CAT, CREATE_BLOG, LIST_BLOGS, EDIT_BLOG, GET_BLOG, DELETE_BLOG, BEFORE_BLOG,
    BEFORE_BLOG_POST, CREATE_POST, LIST_POSTS, EDIT_POST, GET_POST, DELETE_POST
} from '../../redux/types';

const initialState = {

    // blog categories

    blogCategoriesRes: null,
    blogCategoriesAuth: false,

    getBlogCategoryRes: null,
    getBlogCategoryAuth: false,

    editBlogCategoryRes: null,
    editBlogCategoryAuth: false,

    deleteBlogCategoryRes: null,
    deleteBlogCategoryAuth: false,

    createBlogCategoryRes: null,
    createBlogCategoryAuth: false,

    // blogs 

    blogsRes: null,
    blogsAuth: false,

    getBlogRes: null,
    getBlogAuth: false,

    editBlogRes: null,
    editBlogAuth: false,

    deleteBlogRes: null,
    deleteBlogAuth: false,

    createBlogRes: null,
    createBlogAuth: false,

    // blog posts 

    blogPostsRes: null,
    blogPostsAuth: false,

    getBlogPostRes: null,
    getBlogPostAuth: false,

    editBlogPostRes: null,
    editBlogPostAuth: false,

    deleteBlogPostRes: null,
    deleteBlogPostAuth: false,

    createBlogPostRes: null,
    createBlogPostAuth: false,


}

export default (state = initialState, action) => {
    switch (action.type) {

        // Blog Categories
        case CREATE_BLOG_CAT:
            return {
                ...state,
                createBlogCategoryRes: action.payload,
                createBlogCategoryAuth: true,
            }
        case LIST_BLOG_CATS:
            return {
                ...state,
                blogCategoriesRes: action.payload,
                blogCategoriesAuth: true,
            }
        case GET_BLOG_CAT:
            return {
                getBlogCategoryRes: action.payload,
                getBlogCategoryAuth: true
            }
        case EDIT_BLOG_CAT:
            return {
                ...state,
                editBlogCategoryRes: action.payload,
                editBlogCategoryAuth: true,
            }
        case DELETE_BLOG_CAT:
            return {
                ...state,
                deleteBlogCategoryRes: action.payload,
                deleteBlogCategoryAuth: true,
            }
        case BEFORE_BLOG_CAT:
            return {
                ...state,

                blogCategoriesRes: null,
                blogCategoriesAuth: false,

                getBlogCategoryRes: null,
                getBlogCategoryAuth: false,

                editBlogCategoryRes: null,
                editBlogCategoryAuth: false,

                deleteBlogCategoryRes: null,
                deleteBlogCategoryAuth: false,

                createBlogCategoryRes: null,
                createBlogCategoryAuth: false,
            }

        // Blogs 
        case CREATE_BLOG:
            return {
                ...state,
                createBlogRes: action.payload,
                createBlogAuth: true
            }
        case LIST_BLOGS:
            return {
                ...state,
                blogsRes: action.payload,
                blogsAuth: true
            }
        case GET_BLOG:
            return {
                ...state,
                getBlogRes: action.payload,
                getBlogAuth: true
            }
        case EDIT_BLOG:
            return {
                ...state,
                editBlogRes: action.payload,
                editBlogAuth: true
            }
        case DELETE_BLOG:
            return {
                ...state,
                deleteBlogRes: action.payload,
                deleteBlogAuth: true
            }
        case BEFORE_BLOG:
            return {
                ...state,

                blogsRes: null,
                blogsAuth: false,

                getBlogRes: null,
                getBlogAuth: false,

                editBlogRes: null,
                editBlogAuth: false,

                deleteBlogRes: null,
                deleteBlogAuth: false,

                createBlogRes: null,
                createBlogAuth: false,

            }

        // Blog Posts
        case CREATE_POST:
            return {
                ...state,
                createBlogPostRes: action.payload,
                createBlogPostAuth: true
            }
        case LIST_POSTS:
            return {
                ...state,
                blogPostsRes: action.payload,
                blogPostsAuth: true
            }
        case GET_POST:
            return {
                ...state,
                getBlogPostRes: action.payload,
                getBlogPostAuth: true
            }
        case EDIT_POST:
            return {
                ...state,
                editBlogPostRes: action.payload,
                editBlogPostAuth: true
            }
        case DELETE_POST:
            return {
                ...state,
                deleteBlogPostRes: action.payload,
                deleteBlogPostAuth: true
            }
        case BEFORE_BLOG_POST:
            return {
                ...state,

                blogPostsRes: null,
                blogPostsAuth: false,

                getBlogPostRes: null,
                getBlogPostAuth: false,

                editBlogPostRes: null,
                editBlogPostAuth: false,

                deleteBlogPostRes: null,
                deleteBlogPostAuth: false,

                createBlogPostRes: null,
                createBlogPostAuth: false,

            }

        default:
            return {
                ...state
            }
    }
}