import { LIST_NEWSLETTERS, BEFORE_NEWSLETTERS, SEND_NEWS_LETTER_EMAILS } from '../../redux/types';
import { toast } from 'react-toastify';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeNewsletter = () => {
    return {
        type: BEFORE_NEWSLETTERS
    }
}

export const listNewsletter = (qs = null) => dispatch => {
    toast.dismiss()

    let url = `newsletter/list`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: LIST_NEWSLETTERS,
    })
};

export const sendEmail = (body) => dispatch => {

    generalHttpRequest({
        url: `newsletter/send-email`,
        method: 'POST',
        body,
        actionType: SEND_NEWS_LETTER_EMAILS,
    })
};
