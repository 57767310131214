import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import { ENV } from "../../../config/config";
import { toast } from "react-toastify";
import { beforeProductCategory, addProductCategory, editProductCategory, deleteProductCategory, listProductCategories, getProductCategory, } from "../products.actions";
import Select from "react-select";
import { removeExtraSpaces } from "utils/validations/validations";

const { objectToQueryString } = ENV;

const ProductCategories = () => {
    const dispatch = useDispatch();

    const [productCategories, setCategories] = useState(null);
    const [productCategory, setCategory] = useState({
        name: "",
        description: "",
        slug: "",
    });
    const [pagination, setPagination] = useState(null);
    const [loader, setLoader] = useState(true);
    const [permissions, setPermissions] = useState({});
    const [resetButton, setResetButton] = useState(false);
    const [filters, setFilters] = useState({
        name: "",
        status: ""
    });
    const [modalType, setModalType] = useState(0); // 1 = add, 2 = edit
    const [openModal, setOpenModal] = useState(false);
    const [errors, setErrors] = useState({ name: "" });
    const statusOptions = [
        { value: "true", label: "Active" },
        { value: "false", label: "Inactive" },
    ];

    const errorSelector = useSelector((state) => state.error);
    const roleselector = useSelector((state) => state.role.getRoleRes);
    const productsSelector = useSelector((state) => state.products);

    useEffect(() => {
        window.scroll(0, 0);

        if (localStorage.getItem("userID")) {
            const qs = objectToQueryString({ page: 1, limit: 10 });
            dispatch(listProductCategories(qs));
        }
    }, []);

    useEffect(() => {
        if (errorSelector.error) setLoader(false);
    }, [errorSelector.error]);

    useEffect(() => {
        if (Object.keys(roleselector).length > 0) {
            setPermissions(roleselector.role);
            dispatch(beforeRole());
        }
    }, [roleselector]);

    useEffect(() => {
        if (productsSelector.listProductCategoriesAuth) {
            let { categories, pagination } = productsSelector.listProductCategories?.data;
            setCategories(categories);
            setPagination(pagination);
            dispatch(beforeProductCategory());
        }
    }, [productsSelector.listProductCategoriesAuth]);

    useEffect(() => {
        if (productsSelector.getProductCategoryAuth) {
            setCategory(productsSelector.getProductCategory?.productCategory);
            dispatch(beforeProductCategory());
        }
    }, [productsSelector.getProductCategoryAuth]);

    useEffect(() => {
        if (productCategories) {
            setLoader(false);
        }
    }, [productCategories]);

    useEffect(() => {
        let { editProductCategoryAuth, createProductCategoryAuth, deleteProductCategoryAuth } = productsSelector;
        if (editProductCategoryAuth || createProductCategoryAuth || deleteProductCategoryAuth
        ) {
            dispatch(listProductCategories("", false));
        }
    }, [productsSelector.editProductCategoryAuth, productsSelector.createProductCategoryAuth, productsSelector.deleteProductCategoryAuth,
    ]);

    const deleteCategory = (id) => {
        Swal.fire({
            title: "Are you sure you want to delete?",
            html: "If you delete an item, it will lost permanently.",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.value) {
                setLoader(true);
                dispatch(deleteProductCategory(id));
            }
        });
    };

    const onPageChange = async (page) => {
        setLoader(true);
        const qs = objectToQueryString({ page, ...filters });
        dispatch(listProductCategories(qs));
    };

    const applyFilters = () => {
        if (!filters || (!filters.name && !filters.status))
            toast.error("All filter fields are empty", {
                toastId: "FIELD_REQUIRED",
            });
        else {
            setResetButton(true);
            const qs = objectToQueryString({ page: 1, limit: 10, ...filters });
            dispatch(listProductCategories(qs));
            setLoader(true);
        }
    };

    const reset = () => {
        setResetButton(false);
        setFilters({ name: "", status: "" });
        dispatch(listProductCategories());
        setLoader(true);
    };

    const submit = () => {
        let error = "";
        let payload = { ...productCategory };

        if (!payload.name) error = "This field is required";

        setErrors({ ...errors, name: error });

        if (error) return;

        payload.name = removeExtraSpaces(payload.name);

        setLoader(true);
        setModalType(0);

        dispatch(
            modalType === 1 ? addProductCategory(payload) : editProductCategory(payload),
        );
    };

    const modalCloseHandler = () => {
        setOpenModal(!openModal);
        resetModalContent();
        setModalType(0);
    };

    const resetModalContent = () => {
        setCategory({ name: "", description: "" });
    };


    const renderDeleteTooltip = (props) => (
        <Tooltip id="button-tooltip1" {...props}>
            Delete
        </Tooltip>
    );
    const renderEditTooltip = (props) => (
        <Tooltip id="button-tooltip2" {...props}>
            Edit
        </Tooltip>
    );
    const renderViewTooltip = (props) => (
        <Tooltip id="button-tooltip3" {...props}>
            View
        </Tooltip>
    );
    return (
        <>
            {loader ? (
                <FullPageLoader />
            ) : (
                <Container fluid>
                    <Row className="pb-3">
                        <Col sm={12}>
                            <Card className="filter-card">
                                <Card.Header>
                                    <div className="d-flex align-items-center justify-content-between table-head">
                                        <Card.Title as="h4">Filters</Card.Title>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xl={4} sm={6}>
                                            <Form.Group className="mb-3">
                                                <label>Category Name</label>
                                                <Form.Control
                                                    value={filters.name}
                                                    type="text"
                                                    placeholder="Category"
                                                    onChange={(e) =>
                                                        setFilters({ ...filters, name: e.target.value })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4} sm={6} className="search-wrap">
                                            <label>Status</label>
                                            <Form.Group className="mb-3">
                                                <Select
                                                    classNamePrefix="theme-select"
                                                    placeholder="Select Status"
                                                    className="w-100"
                                                    options={statusOptions}
                                                    onChange={(e) =>
                                                        setFilters({ ...filters, status: e ? e.value : "" })
                                                    }
                                                    value={statusOptions.filter(
                                                        (s) => s.value === filters.status,
                                                    )}
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={4} md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                                                <div className="d-flex  filter-btns-holder">
                                                    <Button
                                                        className="btn-filled me-3"
                                                        onClick={applyFilters}
                                                    >
                                                        Search
                                                    </Button>
                                                    {resetButton && (
                                                        <Button
                                                            variant="warning"
                                                            className="outline-button"
                                                            onClick={reset}
                                                        >
                                                            Reset
                                                        </Button>
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="table-outer">
                                <Card>
                                    <Card.Header>
                                        <div className="d-flex align-items-center justify-content-between table-head">
                                            <Card.Title as="h4">Product Categories</Card.Title>
                                            {permissions && permissions.addBlogCategory && (
                                                <Button
                                                    className="float-sm-right btn-filled d-flex align-items-center"
                                                    onClick={() => {
                                                        resetModalContent();
                                                        setModalType(1);
                                                        setOpenModal(true);
                                                    }}
                                                >
                                                    <span className="add-icon me-2">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </span>
                                                    Add Product Category
                                                </Button>
                                            )}
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="table-full-width">
                                        <div className="table-responsive">
                                            <Table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th className="td-start serial-col">#</th>
                                                        <th className="td-name faqs-title ">
                                                            <div className="td-name">Name</div>
                                                        </th>
                                                        <th className="td-description">
                                                            <div className="faq-title td-description">
                                                                Description
                                                            </div>
                                                        </th>
                                                        <th className="td-status">Status</th>
                                                        {(permissions?.editProductCategory ||
                                                            permissions?.deleteProductCategory) && (
                                                                <th className="td-actions text-center">Actions</th>
                                                            )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productCategories && productCategories.length ? (
                                                        productCategories.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="serial-col ">
                                                                        {pagination &&
                                                                            pagination.limit * pagination.page -
                                                                            pagination.limit +
                                                                            index +
                                                                            1}
                                                                    </td>
                                                                    <td>
                                                                        <div className="faq-title td-name">
                                                                            {item.name}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="faq-title td-description">
                                                                            {item.description
                                                                                ? item.description
                                                                                : "N/A"}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className={`label kyc-badge d-inline-block align-top px-2 pty-1 ${item.status
                                                                                ? "label-success"
                                                                                : "label-danger"
                                                                                }`}
                                                                        >
                                                                            {item.status ? "Active" : "Inactive"}
                                                                        </span>
                                                                    </td>
                                                                    {(permissions?.editProductCategory ||
                                                                        permissions?.deleteProductCategory) && (
                                                                            <td className="td-actions">
                                                                                <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                                                                    {permissions?.editProductCategory && (
                                                                                        <li className="d-inline-block align-top">
                                                                                            <OverlayTrigger
                                                                                                overlay={renderEditTooltip}
                                                                                                placement="left"
                                                                                                delay={{ show: 150, hide: 200 }}
                                                                                            >
                                                                                                <Button
                                                                                                    className="btn-link btn-icon"
                                                                                                    type="button"
                                                                                                    variant="success"
                                                                                                    onClick={() => {
                                                                                                        dispatch(
                                                                                                            getProductCategory(item._id),
                                                                                                        );
                                                                                                        resetModalContent();
                                                                                                        setModalType(2);
                                                                                                        setOpenModal(true);
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-edit"></i>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </li>
                                                                                    )}
                                                                                    {permissions?.deleteProductCategory && (
                                                                                        <li className="d-inline-block align-top">
                                                                                            <OverlayTrigger
                                                                                                overlay={renderDeleteTooltip}
                                                                                                placement="left"
                                                                                                delay={{ show: 150, hide: 200 }}
                                                                                            >
                                                                                                <Button
                                                                                                    className="btn-link btn-icon"
                                                                                                    type="button"
                                                                                                    variant="danger"
                                                                                                    onClick={() =>
                                                                                                        deleteCategory(item._id)
                                                                                                    }
                                                                                                >
                                                                                                    <i className="fas fa-trash"></i>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </td>
                                                                        )}
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" className="text-center">
                                                                <div className="alert alert-info" role="alert">
                                                                    No Product Category Found
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            {pagination && (
                                                <Pagination
                                                    className="m-3"
                                                    defaultCurrent={1}
                                                    pageSize // items per page
                                                    current={pagination.page} // current active page
                                                    total={pagination.pages} // total pages
                                                    onChange={onPageChange}
                                                    locale={localeInfo}
                                                />
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    {modalType > 0 && productCategory && (
                        <Modal
                            className="modal-primary edit-cotnact-modal"
                            onHide={modalCloseHandler}
                            show={openModal}
                        >
                            <Modal.Header className="justify-content-center">
                                <Row>
                                    <div className="col-12">
                                        <h4 className="mb-0 mb-md-3 mt-0">
                                            {modalType === 1 ? "Add" : modalType === 2 ? "Edit" : ""}{" "}
                                            Product Category
                                        </h4>
                                    </div>
                                </Row>
                            </Modal.Header>
                            <Modal.Body>
                                <Form className="text-left">
                                    <Form.Group className="mb-3">
                                        <label className=" mb-2">
                                            Category Name <span className="text-danger">*</span>
                                        </label>
                                        <Form.Control
                                            placeholder="Enter title"
                                            type="text"
                                            name="name"
                                            onChange={(e) =>
                                                setCategory({
                                                    ...productCategory,
                                                    name: e.target.value,
                                                })
                                            }
                                            value={productCategory.name}
                                            required
                                        />
                                        <span className={errors && errors.name ? `` : `d-none`}>
                                            <label className="pl-1 text-danger">
                                                {errors && errors.name ? errors.name : ""}
                                            </label>
                                        </span>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <label className=" mb-2">Description</label>
                                        <Form.Control
                                            placeholder="Enter description"
                                            type="text"
                                            name="description"
                                            onChange={(e) =>
                                                setCategory({
                                                    ...productCategory,
                                                    description: e.target.value,
                                                })
                                            }
                                            value={productCategory.description}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <label className=" mb-2">Status</label>
                                        <div className="d-flex pt-3 align-items-center">
                                            <label className="right-label-radio me-3 mb-2">
                                                Active
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    checked={productCategory.status}
                                                    value={productCategory.status}
                                                    onChange={(e) => {
                                                        setCategory({ ...productCategory, status: true });
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="right-label-radio me-3 mb-2">
                                                Inactive
                                                <input
                                                    name="status"
                                                    type="radio"
                                                    checked={!productCategory.status}
                                                    value={!productCategory.status}
                                                    onChange={(e) => {
                                                        setCategory({ ...productCategory, status: false });
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button className="outline-button" onClick={modalCloseHandler}>
                                    Close
                                </Button>
                                <Button className="btn-filled" onClick={submit}>
                                    {modalType === 1 || modalType === 2 ? "Submit" : null}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </Container>
            )}
        </>
    );
};

export default ProductCategories;
