//importing layouts....
import Admin from "layouts/admin";
import UnAuth from "layouts/auth";

import Dashboard from "views/dashboard/dashboard.js";
import Login from "./views/login/login";
import Users from "./views/users/users";
import SiteSettings from "views/settings/siteSettings";
import SocialSettings from "views/settings/socialSettings";
import Faq from "views/faqs/faqs";
import AddFaq from "views/faqs/addFaq";
import EditFaq from "views/faqs/editFaq";
import EmailTemplates from "views/emailTemplates/emailTemplates";
import Profile from "views/profile/profile";
import EmailTemplate from "views/emailTemplates/emailTemplate";
import ForgotPassword from "views/forgotPassword/forgotPassword";
import ResetPassword from "views/resetPassword/resetPassword";
import Contacts from "views/contacts/contacts";
import Permissions from "views/adminStaff/permissions/permissionsListingComponent";
import Staff from "views/adminStaff/staff/staffListingComponent";
import contentManagementSystem from "views/contentManagement/contentManagementSystem";
import UpsertContent from "views/contentManagement/upsertContent";
import ViewCMS from "views/contentManagement/viewCMS";
import NewsLetter from "views/newsletter/newsletter";
import FaqCategories from "views/faqs/faqCategories";
import UpsertFaqCategory from "views/faqs/upsertFaqCategory";
import BlogCategories from "views/blogs/blogCategories/blogCategories";
import BlogListings from "views/blogs/blogListings/blogs";
import BlogPosts from "views/blogs/blogPosts/blogPosts";
import UpsertBlogPost from "views/blogs/blogPosts/upsertBlogPost";

import { faAddressBook, faAddressCard, faBlog, faDashboard, faEnvelopesBulk, faGear, faList, faListCheck, faNewspaper, faQuestion, faServer, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import ProductCategories from "views/products/categories/productCategories";

const routes = [
  {
    path: "/",
    layout: UnAuth,
    name: "Login",
    access: true,
    exact: true,
    component: Login,
  },
  {
    path: "/dashboard",
    layout: Admin,
    name: "Dashboard",
    faIcon: faDashboard,
    access: true,
    exact: true,
    component: Dashboard,
    showInSideBar: true,
  },
  {
    path: "/profile",
    layout: Admin,
    name: "Profile",
    faIcon: faUser,
    access: true,
    exact: true,
    component: Profile,
    showInSideBar: false,
  },
  {
    collapse: true,
    name: "Admin Staff",
    state: "openAdminStaff",
    faIcon: faUser,
    showInSideBar: true,
    submenus: [
      {
        path: "/permissions",
        layout: Admin,
        name: "Permissions",
        faIcon: faListCheck,
        access: true,
        exact: true,
        component: Permissions,
        showInSideBar: true,
      },
      {
        path: "/staff",
        layout: Admin,
        name: "Staff",
        faIcon: faUser,
        access: true,
        exact: true,
        component: Staff,
        showInSideBar: true,
      },
    ],
  },
  {
    path: "/users",
    layout: Admin,
    name: "Users",
    faIcon: faUsers,
    access: true,
    exact: true,
    component: Users,
    showInSideBar: true,
  },
  {
    collapse: true,
    name: "BLOGS",
    state: "openBlogs",
    faIcon: faBlog,
    showInSideBar: true,
    submenus: [
      {
        path: "/blogs",
        layout: Admin,
        name: "Blogs",
        faIcon: faBlog,
        access: true,
        exact: true,
        component: BlogListings,
        showInSideBar: true,
      },
      {
        path: "/blog-categories",
        layout: Admin,
        name: "Blog Categories",
        faIcon: faList,
        access: true,
        exact: true,
        component: BlogCategories,
        showInSideBar: true,
      },
      {
        path: "/blog-posts",
        layout: Admin,
        name: "Blog Posts",
        faIcon: faAddressCard,
        access: true,
        exact: true,
        component: BlogPosts,
        showInSideBar: true,
      },
    ],
  },
  {
    path: "/blog-posts/upsert",
    layout: Admin,
    name: "Blog Post",
    access: true,
    exact: true,
    component: UpsertBlogPost,
  },
  {
    path: "/email-templates",
    layout: Admin,
    name: "Email Templates",
    faIcon: faEnvelopesBulk,
    access: true,
    exact: true,
    component: EmailTemplates,
    showInSideBar: true,
  },
  {
    path: "/email-template/:emailId",
    layout: Admin,
    name: "Edit Email Template",
    access: true,
    exact: true,
    component: EmailTemplate,
  },
  {
    path: "/add-email-template",
    layout: Admin,
    name: "Add Email Template",
    access: true,
    exact: true,
    component: EmailTemplate,
  },
  {
    collapse: true,
    name: "FAQS",
    state: "openFaqs",
    faIcon: faQuestion,
    showInSideBar: true,
    submenus: [
      {
        path: "/faqs",
        layout: Admin,
        name: "Faqs",
        faIcon: faQuestion,
        access: true,
        exact: true,
        component: Faq,
        showInSideBar: true,
      },
      {
        path: "/faq-categories",
        layout: Admin,
        name: "Faq Categories",
        faIcon: faList,
        access: true,
        exact: true,
        component: FaqCategories,
        showInSideBar: true,
      },
    ],
  },
  {
    path: "/add-faq-category",
    layout: Admin,
    name: "Add Faq Category",
    access: true,
    exact: true,
    component: UpsertFaqCategory,
  },
  {
    path: "/edit-faq-category/:faqCatId",
    layout: Admin,
    name: "Edit Faq Category",
    access: true,
    exact: true,
    component: UpsertFaqCategory,
  },
  {
    path: "/add-faq",
    layout: Admin,
    name: "Add Faq",
    access: true,
    exact: true,
    component: AddFaq,
  },
  {
    path: "/edit-faq/:faqId",
    layout: Admin,
    name: "Edit Faq",
    access: true,
    exact: true,
    component: EditFaq,
  },
  {
    collapse: true,
    name: "PRODUCTS",
    state: "openProducts",
    faIcon: faBlog,
    showInSideBar: true,
    submenus: [
      {
        path: "/products",
        layout: Admin,
        name: "Products",
        faIcon: faBlog,
        access: true,
        exact: true,
        component: BlogListings,
        showInSideBar: true,
      },
      {
        path: "/products/categories",
        layout: Admin,
        name: "Product Categories",
        faIcon: faList,
        access: true,
        exact: true,
        component: ProductCategories,
        showInSideBar: true,
      },
    ],
  },
  // {
  //   path: "/cms",
  //   layout: Admin,
  //   name: "CMS",
  //   faIcon: faServer,
  //   access: true,
  //   exact: true,
  //   component: contentManagementSystem,
  //   showInSideBar: true,
  // },
  // {
  //   path: "/add-cms",
  //   layout: Admin,
  //   name: "Add Content",
  //   access: true,
  //   exact: true,
  //   component: UpsertContent,
  // },
  // {
  //   path: "/edit-cms/:contentId",
  //   layout: Admin,
  //   name: "Edit Content",
  //   access: true,
  //   exact: true,
  //   component: UpsertContent,
  // },
  // {
  //   path: "/view-cms/:contentId",
  //   layout: Admin,
  //   name: "CMS",
  //   access: true,
  //   exact: true,
  //   component: ViewCMS,
  // },
  // {
  //   path: "/contact",
  //   layout: Admin,
  //   name: "Contacts",
  //   faIcon: faAddressBook,
  //   access: true,
  //   exact: true,
  //   component: Contacts,
  //   showInSideBar: true,
  // },
  // {
  //   path: "/newsletter",
  //   layout: Admin,
  //   name: "Newsletters",
  //   faIcon: faNewspaper,
  //   access: true,
  //   exact: true,
  //   component: NewsLetter,
  //   showInSideBar: true,
  // },
  {
    collapse: true,
    name: "Settings",
    state: "opensettings",
    faIcon: faGear,
    showInSideBar: true,
    submenus: [
      {
        path: "/site-settings",
        layout: Admin,
        name: "Site Settings",
        mini: "SS",
        faIcon: faGear,
        access: true,
        exact: true,
        component: SiteSettings,
        showInSideBar: true,
      },
      {
        path: "/social-settings",
        layout: Admin,
        name: "Social Settings",
        mini: "SS",
        faIcon: faGear,
        access: true,
        exact: true,
        component: SocialSettings,
        showInSideBar: true,
      },
    ],
  },
  {
    path: "/login",
    layout: UnAuth,
    name: "Login",
    mini: "LP",
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: UnAuth,
    name: "Forgot Passowrd",
    mini: "FP",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:adminId",
    layout: UnAuth,
    name: "Reset Passowrd",
    mini: "RP",
    component: ResetPassword,
  },
];

export default routes;
