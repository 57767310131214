import React from "react";
import routes from "./routes";
import { PrivateRoute } from "./privateRoute";
import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "views/notFound/notFound";
import Getter from "./getter";
import { useLocation } from "react-router-dom";
import { ENV } from "./config/config";
const { exclusiveRoutes } = ENV;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userID");
  const currentLocation = location.pathname.split("/")[1];

  const getRouteAccess = () => {
    return userId || exclusiveRoutes.indexOf(currentLocation) > -1 ? true : false;
  };

  return (
    <React.Fragment>
      <Getter />
      <Routes>
        {routes.map((route, index) => {
          if (route.path) {
            return (
              <Route key={index} path={route.path} exact={route.exact} element={<PrivateRoute access={getRouteAccess()} />}>
                <Route
                  path={route.path}
                  exact={route.exact}
                  element={
                    <route.layout>
                      <route.component location={location} navigate={navigate} />
                    </route.layout>
                  }
                />
              </Route>
            );
          } else {
            return route.submenus.map((subroute, subkey) => {
              if (subroute.path) {
                return (
                  <Route key={index + subkey} path={subroute.path} exact={subroute.exact} element={<PrivateRoute access={getRouteAccess()} />}>
                    <Route
                      path={subroute.path}
                      exact={subroute.exact}
                      element={
                        <subroute.layout>
                          <subroute.component location={location} navigate={navigate} />
                        </subroute.layout>
                      }
                    />
                  </Route>
                );
              } else {
                return subroute.submenus.map((nestedSubroute, nestedSubkey) => {
                  return (
                    <Route key={index + nestedSubkey} path={nestedSubroute.path} exact={nestedSubroute.exact} element={<PrivateRoute access={getRouteAccess()} />}>
                      <Route
                        path={nestedSubroute.path}
                        exact={nestedSubroute.exact}
                        element={
                          <nestedSubroute.layout>
                            <nestedSubroute.component location={location} navigate={navigate} />
                          </nestedSubroute.layout>
                        }
                      />
                    </Route>
                  );
                });
              }
            });
          }
        })}
        <Route component={NotFound} />
      </Routes>
    </React.Fragment>
  );
};

export default App;
