import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ENV } from "../../config/config";
import { beforeContent, listContent, deleteContent } from "./cms.action";
import {
  beforeRole,
  getRole,
} from "views/adminStaff/permissions/permissions.actions";
import Select from "react-select";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

const CryptoJS = require("crypto-js");
const { objectToQueryString, secretKey } = ENV;

const ContentManagementSystem = (props) => {
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [resetButton, setResetButton] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [loader, setLoader] = useState(true);
  const [filters, setFilters] = useState({
    title: "",
    status: "",
  });
  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    props.listContent();
    let roleEncrypted = localStorage.getItem("role");
    let role = "";
    if (roleEncrypted) {
      let roleDecrypted = CryptoJS.AES.decrypt(
        roleEncrypted,
        secretKey,
      ).toString(CryptoJS.enc.Utf8);
      role = roleDecrypted;
      props.getRole(role);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setPermissions(props.getRoleRes.role);
      props.beforeRole();
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (props.content.getContentPagesAuth) {
      let { contentPages, pagination } =
        props.content?.getContentPagesRes?.data;
      setData(contentPages);
      setPagination(pagination);
      setLoader(false);
      props.beforeContent();
    }
  }, [props.content.getContentPagesAuth]);

  useEffect(() => {
    if (props.content.deleteContentAuth) {
      setData(
        data.filter((item) => {
          if (props.content.deleteContentRes.contentId !== item._id) {
            return item;
          }
        }),
      );
      setLoader(false);
      props.beforeContent();
    }
  }, [props.content.deleteContentAuth]);

  const deleteCMS = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        props.deleteContent(id);
      }
    });
  };

  const onPageChange = async (page) => {
    setLoader(true);
    const qs = objectToQueryString({ page, ...filters });
    props.listContent(qs);
  };

  const applyFilters = () => {
    if ((filters && filters.title) || (filters && filters.status)) {
      setResetButton(true);
      const qs = objectToQueryString({ page: 1, limit: 10, ...filters });
      props.listContent(qs);
      setLoader(true);
    } else {
      toast.error("All filter fields are empty.", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const reset = () => {
    setResetButton(false);
    setFilters({ title: "", status: "" });
    props.listContent();
    setLoader(true);
  };


  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row className="pb-3">
            <Col sm={12}>
              <Card className="filter-card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Search with Title...</label>
                        <Form.Control
                          value={filters.title}
                          type="text"
                          placeholder="Title"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              title: e.target.value.trim(),
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Search with status...</label>
                    <Form.Group  className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Status"
                          className="w-100"
                          options={statusOptions}
                          onChange={(e) =>
                            setFilters({ ...filters, status: e ? e.value : "" })
                          }
                          value={statusOptions.filter(
                            (s) => s.value === filters.status,
                          )}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                        <div className="d-flex  filter-btns-holder">
                          <Button
                            className="btn-filled me-3"
                            onClick={applyFilters}
                          >
                            Search
                          </Button>
                          {resetButton && (
                            <Button
                              variant="warning"
                              className="outline-button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="table-outer">
                <Card>
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title as="h4">CMS</Card.Title>
                      {permissions && permissions.addContent && (
                        <Button
                          className="float-sm-right btn-filled d-flex align-items-center"
                          onClick={() => navigate(`/add-cms`)}
                        >
                          <span className="add-icon me-2">
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                          Add CMS
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <div className="table-responsive">
                      <Table className="custom-table">
                        <thead>
                          <tr>
                            <th className="td-start serial-col">#</th>
                            <th className="td-name">
                              <div className="faqs-title td-name">Title</div>
                            </th>
                            <th className="td-status">Slug</th>
                            <th className="td-status text-center">Status</th>
                            {(permissions?.editContent ||
                              permissions?.deleteContent) && (
                                <th className="td-actions text-center">Actions</th>
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length ? (
                            data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="serial-col">
                                    {pagination &&
                                      pagination.limit * pagination.page -
                                      pagination.limit +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    <div className="faq-title td-name">
                                      {item.title}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="faq-title td-name">
                                      {item.slug}
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      className={`label kyc-badge kyc-status-badge d-inline-block align-top px-2 py-1 ${item.status
                                        ? "label-success"
                                        : "label-danger"
                                        }`}
                                    >
                                      {item.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  {(permissions?.editContent ||
                                    permissions?.deleteContent) && (
                                      <td className="td-actions">
                                        <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                          <li className="d-inline-block align-top">
                                            <OverlayTrigger
                                              overlay={renderViewTooltip}
                                              placement="left"
                                              delay={{ show: 150, hide: 200 }}
                                            >
                                              <Button
                                                className="btn-link btn-icon"
                                                type="button"
                                                variant="info"
                                                onClick={() =>
                                                  navigate(
                                                    `/view-cms/${window.btoa(
                                                      item._id,
                                                    )}`,
                                                  )
                                                }
                                              >
                                                <i className="fas fa-eye"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </li>
                                          {permissions &&
                                            permissions.editContent && (
                                              <li className="d-inline-block align-top">
                                                <OverlayTrigger
                                                  overlay={renderEditTooltip}
                                                  placement="left"
                                                  delay={{ show: 150, hide: 200 }}
                                                >
                                                  <Button
                                                    className="btn-link btn-icon"
                                                    type="button"
                                                    variant="success"
                                                    onClick={() =>
                                                      navigate(
                                                        `/edit-cms/${window.btoa(
                                                          item._id,
                                                        )}`,
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-edit"></i>
                                                  </Button>
                                                </OverlayTrigger>
                                              </li>
                                            )}
                                          {permissions &&
                                            permissions.deleteContent && (
                                              <li className="d-inline-block align-top">
                                                <OverlayTrigger
                                                  overlay={renderDeleteTooltip}
                                                  placement="left"
                                                  delay={{ show: 150, hide: 200 }}
                                                >
                                                  <Button
                                                    className="btn-link btn-icon"
                                                    type="button"
                                                    variant="danger"
                                                    onClick={() =>
                                                      deleteCMS(item._id)
                                                    }
                                                  >
                                                    <i className="fas fa-trash"></i>
                                                  </Button>
                                                </OverlayTrigger>
                                              </li>
                                            )}
                                        </ul>
                                      </td>
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                <div className="alert alert-info" role="alert">
                                  No CMS Found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={pagination.page} // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  content: state.content,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

ContentManagementSystem.propTypes = {
  getRoleRes: PropTypes.object,
  beforeContent: PropTypes.func,
  listContent: PropTypes.func,
  deleteContent: PropTypes.func,
  getRole: PropTypes.func,
  beforeRole: PropTypes.func,
  content: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, {
  beforeContent,
  listContent,
  deleteContent,
  getRole,
  beforeRole,
})(ContentManagementSystem);
