import React, { useState, useEffect } from "react";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import "rc-pagination/assets/index.css";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAdmin, beforeAdmin } from "views/admin/admin.action";
import { beforeBlogPost, getBlogPost, editBlogPost, createBlogPost } from "./blogPosts.actions";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { ENV } from "../../../config/config";
import { listBlogs, beforeBlog } from "../blogListings/blogs.actions";
import { fileValidation } from "utils/validations/validations";
import { removeExtraSpaces } from "utils/validations/validations";
const placeholderImg = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658297644/hex-nft/assets/placeholder_ogfxwf.png";
const { objectToQueryString, allowedMediaTypes } = ENV;
import { ckEditorCustomConfig } from "config/ckEditorConfig";

const UpsertBlogPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [type, setType] = useState(0);
  const [image, setImage] = useState(false);
  const [tag, setTag] = useState("");

  const [blogPost, setBlogPost] = useState({
    title: "",
    content: "",
    tags: [],
    blogId: "",
    postedDate: "",
    featuredImage: "",
    published: false,
  });
  const [author, setAuthor] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [selectedTags, setSeletedTags] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    content: "",
    blogId: "",
    featuredImage: "",
    tags: "",
  });

  const blogPostsSelector = useSelector((state) => state.blogs);
  const errorSelector = useSelector((state) => state.error);
  const adminsSelector = useSelector((state) => state.admin);

  useEffect(() => {
    toast.dismiss();
    window.scroll(0, 0);

    let adminId = localStorage.getItem("userID");

    if (adminId) {
      const qs = objectToQueryString({ all: true });

      dispatch(getAdmin(adminId, false));
      dispatch(listBlogs(qs, false));

      let value = new URLSearchParams(window.location.search).get("type");
      let id = new URLSearchParams(window.location.search).get("id");
      setType(parseInt(value)); // 1-> add, 2-> update

      if ((value == "2" || value == "3") && id) dispatch(getBlogPost(id));
    }
  }, []);

  useEffect(() => {
    if (type === 1) setLoader(false);
  }, [type]);

  // when an error is received
  useEffect(() => {
    if (errorSelector.error) setLoader(false);
  }, [errorSelector.error]);

  useEffect(() => {
    if (adminsSelector.getAuth) {
      let data = adminsSelector.admin?.admin;
      setAuthor({ _id: data._id, fullName: data.fullName });
      dispatch(beforeAdmin());
    }
  }, [adminsSelector.getAuth]);

  useEffect(() => {
    if (blogPostsSelector.getBlogPostAuth) {
      setBlogPost({
        ...blogPost,
        ...blogPostsSelector.getBlogPostRes?.blogPost[0],
      });

      let { blogPost } = blogPostsSelector.getBlogPostRes;

      setImage(blogPost[0]?.featuredImage);
      setSeletedTags(blogPost[0]?.tags);

      // setLoader(false)
      dispatch(beforeBlogPost());
    }
  }, [blogPostsSelector.getBlogPostAuth]);

  useEffect(() => {
    if (blogPost && Object.keys(blogPost)?.length && blogs?.length) {
      setLoader(false);
    }
  }, [blogPost, blogs]);

  useEffect(() => {
    if (blogPostsSelector.blogsAuth) {
      let data = blogPostsSelector.blogsRes?.data?.blogs;
      let options = [];
      if (data?.length) {
        data.map((c) => {
          options.push({ value: c._id, label: c.title });
        });
      }
      setBlogs(options);
      dispatch(beforeBlog());
    }
  }, [blogPostsSelector.blogsAuth]);

  useEffect(() => {
    let { createBlogPostAuth } = blogPostsSelector;
    if (createBlogPostAuth) {
      dispatch(beforeBlogPost());
      reset();
      setLoader(false);
      navigate("/blog-posts");
      dispatch(beforeBlogPost());
    }
  }, [blogPostsSelector.createBlogPostAuth]);

  useEffect(() => {
    let { editBlogPostAuth } = blogPostsSelector;
    if (editBlogPostAuth) {
      navigate("/blog-posts");
    }
  }, [blogPostsSelector.editBlogPostAuth]);

  const onEditorChange = (event, editor) => {
    let data = editor.getData();
    setBlogPost({ ...blogPost, content: data });
  };

  const addTagHandler = (e, flag) => {
    if (flag === 2) {
      if (tag && tag.trim() && !selectedTags.includes(tag)) {
        setBlogPost({ ...blogPost, tags: [...blogPost.tags, tag] });
        setSeletedTags([...selectedTags, tag]);
        setTag("");
      }
    }
  };

  const removeTagHandler = (value, flag) => {
    if (type !== 3) {
      if (flag === 2) {
        let filteredTags = blogPost.tags.filter((t) => t !== value);
        let filteredSelectedTags = selectedTags.filter((t) => t !== value);

        setBlogPost({ ...blogPost, tags: filteredTags });
        setSeletedTags(filteredSelectedTags);
      }
    }
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileErr = fileValidation(file, allowedMediaTypes.images);
      if (fileErr) {
        toast.error(`Supported File Types: ${allowedMediaTypes.images.join(", ")}`);
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let result = e.target.result;
        setImage(result);
        setBlogPost({ ...blogPost, featuredImage: file });
      };
    }
  };

  const submit = () => {
    let authorsErr = "";
    let titleErr = "";
    let imgErr = "";
    let contentErr = "";
    let blogErr = "";
    let tagsErr = "";
    let msg = "This field is required";

    if (!author._id) authorsErr = msg;

    if (!blogPost.title) titleErr = msg;

    if (!blogPost.blogId) blogErr = msg;

    if (!blogPost.featuredImage) imgErr = msg;

    if (!blogPost.content) contentErr = msg;

    if (blogPost.tags && blogPost.tags?.length) {
      let lengthCheck = true;
      let alphabetsCheck = true;

      blogPost.tags.map((tag) => {
        if (tag.length > 20) lengthCheck = false;
        else if (!/^[A-Za-z0-9 ]*$/.test(tag)) alphabetsCheck = false;
      });

      if (!lengthCheck) tagsErr = "A tag can only contain upto 20 characters";
      else if (!alphabetsCheck) tagsErr = "A tag can only contain alphabets & numbers";
    }

    setErrors({
      author: authorsErr,
      title: titleErr,
      blogId: blogErr,
      featuredImage: imgErr,
      content: contentErr,
      tags: tagsErr,
    });

    if (authorsErr || titleErr || blogErr || imgErr || contentErr || tagsErr) return;

    let payload = { ...blogPost, author };
    payload.title = removeExtraSpaces(payload.title);

    let formData = new FormData();
    for (const key in payload) {
      if (key === "author" || key === "tags") {
        formData.append(key, JSON.stringify(payload[key]));
      } else formData.append(key, payload[key]);
    }

    setLoader(true);
    dispatch(type === 1 ? createBlogPost(formData) : editBlogPost(formData));
  };

  const reset = () => {
    setBlogPost({
      title: "",
      content: "",
      tags: [],
      blogId: "",
      postedDate: "",
      featuredImage: "",
      published: false,
    });
    setImage("");
    setAuthor({});
    setSeletedTags([]);
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container>
          <Row>
            <Col xl="8" className="mb-xl-0 mb-3">
              <Card className="pb-3 table-outer">
                <Card.Header>
                  <Card.Title as="h4" className="mb-3">
                    Blog Post
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl="6">
                      <Form.Group className="mb-3">
                        <label>
                          Title<span className="text-danger"> *</span>
                        </label>
                        <Form.Control
                          value={blogPost?.title}
                          disabled={type === 3}
                          onChange={(e) => {
                            setBlogPost({ ...blogPost, title: e.target.value });
                          }}
                          placeholder="Title"
                          type="text"
                        ></Form.Control>
                        <span className={errors.title ? `` : `d-none`}>
                          <label className="pl-1 pt-1 text-danger">{errors.title}</label>
                        </span>
                      </Form.Group>
                    </Col>
                    <Col xl="6">
                      <Form.Group className="mb-3">
                        <label className="text-white mb-2">
                          Blogs<span className="text-danger"> *</span>
                        </label>
                        <div className="select-items">
                          <Select
                            classNamePrefix="theme-select"
                            placeholder="Select Blog"
                            className="w-100"
                            isDisabled={type === 3}
                            options={blogs}
                            onChange={(e) =>
                              setBlogPost({
                                ...blogPost,
                                blogId: e ? e.value : "",
                              })
                            }
                            value={blogs?.filter((b) => blogPost?.blogId === b.value)}
                            isClearable
                          />
                        </div>
                        <span className={errors && errors.blogId ? `` : `d-none`}>
                          <label className="pl-1 text-danger">{errors && errors.blogId ? errors.blogId : ""}</label>
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xxl="6" xl="12">
                      <Form.Group className="mb-3">
                        <label className="text-white mb-2">
                          Author<span className="text-danger"> *</span>
                        </label>
                        <Form.Control value={author?.fullName} disabled={true} placeholder="Title" type="text"></Form.Control>
                        <span className={errors && errors.author ? `` : `d-none`}>
                          <label className="pl-1 text-danger">{errors && errors.author ? errors.author : ""}</label>
                        </span>
                      </Form.Group>
                    </Col>
                    <Col xxl="6" xl="12">
                      <Row>
                        <Col xxl="6" xl="5">
                          <Form.Group className="mb-3">
                            <label>Tags</label>
                            <Form.Control value={tag} disabled={type === 3} onChange={(e) => setTag(e.target.value)} placeholder="Add Tag" type="text"></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xxl="6" xl="7" className="mt-4">
                          <Form.Group className="mb-3">
                            <Button disabled={type === 3} className="btn-filled" onClick={(e) => addTagHandler(e, 2)}>
                              <i className="fa fa-plus-circle me-2"></i>
                              Add Tag
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {selectedTags && selectedTags.length && (
                    <Row>
                      <Col md="6">
                        <div>
                          {selectedTags.map((tag, index) => {
                            return (
                              tag && (
                                <div key={index} className={`label kyc-badge d-inline-block align-top px-2 pty-1 label-danger me-2 mb-2`}>
                                  {tag}
                                  <span
                                    className="ml-2"
                                    style={{
                                      cursor: type !== 3 ? "pointer" : "",
                                    }}
                                    onClick={() => removeTagHandler(tag, 2)}
                                  >
                                    <i className="fa fa-minus-circle"></i>
                                  </span>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {
                    <span className={errors.tags ? `` : `d-none`}>
                      <label className="pl-1 pt-1 text-danger">{errors.tags}</label>
                    </span>
                  }
                  <Row>
                    <Col md="12">
                      <label>
                        Text / Description
                        <span className="text-danger"> *</span>
                      </label>
                      <CKEditor editor={ClassicEditor} config={ckEditorCustomConfig} disabled={type === 3} data={blogPost?.content || ""} content={blogPost?.content || ""} onChange={(event, editor) => onEditorChange(event, editor)} />
                      <span className={errors.content ? `` : `d-none`}>
                        <label className="pl-1 pt-1 text-danger">{errors.content}</label>
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <div className="d-flex pt-3">
                          <label className="me-3">
                            Publish<span className="text-danger"> *</span>
                          </label>
                          <label className="right-label-radio me-3 mb-2">
                            Yes
                            <input disabled={type === 3} name="published" type="radio" checked={blogPost?.published} value={blogPost?.published} onChange={() => setBlogPost({ ...blogPost, published: true })} />
                            <span className="checkmark" onChange={() => setBlogPost({ ...blogPost, published: true })}></span>
                          </label>
                          <label className="right-label-radio me-3 mb-2">
                            No
                            <input disabled={type === 3} name="published" type="radio" checked={!blogPost?.published} value={!blogPost?.published} onChange={() => setBlogPost({ ...blogPost, published: false })} />
                            <span className="checkmark" onChange={() => setBlogPost({ ...blogPost, published: false })}></span>
                          </label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="me-2">
                          <Link to={"/blog-posts"} className="outline-button btn btn-primary mt-3">
                            Back
                          </Link>
                        </div>
                        {type !== 3 && (
                          <Button className="btn-filled pull-right mt-3" type="submit" variant="info" onClick={submit}>
                            {type === 1 ? "Add" : "Update"}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="card-user table-outer">
                <Card.Header className="no-padding"></Card.Header>
                <Card.Body>
                  <div className="author">
                    <img alt="..." className="avatar border-gray" src={image ? image : placeholderImg}></img>
                    <Card.Title as="h5">{blogPost?.title}</Card.Title>
                  </div>

                  <Form className="profile-main-form ml-4 mt-2">
                    <Form.Group className="pl-3 pr-3">
                      <label>
                        Featured Image<span className="text-danger"> *</span>
                      </label>
                      <Form.Control className="text-white" placeholder="Image" type="file" varient="info" accept=".png,.jpeg,.jpg" onChange={(e) => onFileChange(e)} disabled={type === 3} id="featuredImage"></Form.Control>
                      <span className={errors.featuredImage ? `` : `d-none`}>
                        <label className="pl-1 pt-1 text-danger">{errors.featuredImage}</label>
                      </span>
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="pb-2"></div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default UpsertBlogPost;
