import { toast } from 'react-toastify';
import { CREATE_BLOG, LIST_BLOGS, EDIT_BLOG, GET_BLOG, DELETE_BLOG, BEFORE_BLOG } from '../../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrlBlogs = `blogs`

export const beforeBlog = () => {
    return {
        type: BEFORE_BLOG
    }
}

export const createBlog = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlBlogs}`,
        method: 'POST',
        body,
        actionType: CREATE_BLOG,
    })
};

export const listBlogs = (qs = '', showToast = true) => dispatch => {
    if(showToast) toast.dismiss()

    let url = `${apiUrlBlogs}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: LIST_BLOGS,
        showToast
    })

};

export const editBlog = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlBlogs}`,
        method: 'PUT',
        body,
        actionType: EDIT_BLOG,
    })
};

export const getBlog = (id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlBlogs}/${id}`,
        method: 'GET',
        actionType: GET_BLOG,
        showToast: false
    })
};

export const deleteBlog = (id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrlBlogs}/${id}`,
        method: 'DELETE',
        actionType: DELETE_BLOG,
    })
};