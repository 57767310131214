import { GET_ERRORS } from "../../redux/types";
import { ENV } from "config/config";
import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";
import store from "store";
const { Authorization, x_auth_token } = ENV;

export const generalHttpRequest = ({ url, method, actionType, body, showToast = true, addAccessToken = false, toastId }) => {
  try {
    if (!url || !method || !actionType) return false;

    store.dispatch(emptyError());

    let reqUrl = `${ENV.url}${url}`;
    let reqData = {
      method,
      headers: {
        Authorization: Authorization,
        "x-auth-token": x_auth_token,
      },
    };

    if ((method.toLowerCase() === "post" || method.toLowerCase() === "put") && !(body instanceof FormData))
      reqData.headers = {
        ...reqData.headers,
        "content-Type": "application/json",
      };

    if (addAccessToken)
      reqData.headers = {
        ...reqData.headers,
        "x-access-token": localStorage.getItem("accessToken"),
      };

    // if (body) reqData.body = body instanceof FormData ? body : JSON.stringify(body);
    if (body) {
      if (body instanceof FormData)
        reqData.body = body
      else if (Object.keys(body)?.length)
        reqData.body = JSON.stringify(body)
    }

    fetch(reqUrl, { ...reqData })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (showToast)
            toast.success(data.message, {
              toastId: toastId || `${actionType.toLowerCase()}-success`,
            });
          store.dispatch({
            type: actionType,
            payload: data,
          });
        } else {
          toast.error(data.message, {
            toastId: toastId || `${actionType.toLowerCase()}-err`,
          });

          store.dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) toast.error(data.message);
        }
        store.dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  } catch (err) {
    console.log(err);
    toast.error(err.mesage);
    return false;
  }
};
