import React, { useEffect, useState } from "react";
import AOS from "aos";
import "./fullPageLoader.css";

const FullPageLoader = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.Fragment>
      <div className="center-body">
        <div className="loader"></div>
      </div>
    </React.Fragment>
  );
};

export default FullPageLoader;
