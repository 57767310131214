import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import {
  beforeAdmin,
  getAdmin,
  updateAdmin,
  updatePassword,
} from "../admin/admin.action";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from "validator";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { ENV } from "../../config/config";
import { fileValidation } from "utils/validations/validations";
import PropTypes from "prop-types";

const userDefaultImg =
  "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658297126/hex-nft/assets/default-profile_captlc.png";
const { allowedMediaTypes } = ENV;

const Profile = (props) => {
  const [admin, setAdmin] = useState(null);

  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirm: "",
    _id: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    verifyPassword: false,
    currentPassword: false,
  });

  const [pic, setPic] = useState({
    profileImage: null,
    _id: "",
  });

  const navigate = useNavigate();
  const [nameMsg, setNameMsg] = useState("");
  const [phoneMsg, setPhoneMsg] = useState("");
  const [passwordMsgCheck, setPasswordMsgCheck] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [loader, setLoader] = useState(true);
  const adminId = localStorage.getItem("userID");

  useEffect(() => {
    window.scroll(0, 0);

    if (localStorage.getItem("userID")) {
      props.getAdmin(adminId);
    }
  }, []);

  useEffect(() => {
    if (admin) {
      setLoader(false);
    }
  }, [admin]);

  useEffect(() => {
    if (props.admin.getAuth) {
      const { admin } = props.admin?.admin;
      setAdmin(admin);
    }
  }, [props.admin.getAuth]);

  useEffect(() => {
    if (props.admin.updatePasswordAuth) {
      navigate("/login");
      localStorage.clear();
      props.beforeAdmin();
    }
  }, [props.admin.updatePasswordAuth]);

  useEffect(() => {
    if (props.admin.updateAdminAuth) {
      setLoader(false);
      props.beforeAdmin();
    }
  }, [props.admin.updateAdminAuth]);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setAdminRole(props.getRoleRes.role);
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (password.confirm && password.new) {
      if (password.new === password.confirm) {
        setPasswordMsgCheck(false);
      } else {
        setPasswordMsg("New password and confirm password are not same.");
        setPasswordMsgCheck(true);
      }
    } else {
      setPasswordMsgCheck(false);
    }
  }, [password]);

  const submitCallBack = (e) => {
    e.preventDefault();

    let fullName = "";
    let phone = "";
    let payload = {...admin}

    if (!payload.fullName) fullName = "This field is required.";
    else {
      let regex = /^[a-zA-Z ]{3,30}$/;
      if (!payload.fullName.match(regex))
        fullName =
          "Name should be atleast 3 characters long and should only contain alphabets";
    }

    if (payload.phone) {
      if (!validator.isMobilePhone(payload.phone)) phone = "Invalid Phone Number";
    }

    setNameMsg(fullName);
    setPhoneMsg(phone);

    if (fullName || phone) return;

    delete payload.profileImage
    delete payload.profileImageLocal

    let formData = new FormData();
    for (const key in payload) formData.append(key, payload[key]);

    setLoader(true);
    setNameMsg("");
    setPhoneMsg("");
    props.updateAdmin(formData);
  };

  const passwordForm = (e) => {
    e.preventDefault();

    if (
      !validator.isEmpty(password.current) &&
      !validator.isEmpty(password.new) &&
      !validator.isEmpty(password.confirm)
    ) {
      if (password.new === password.confirm) {
        if (validator.isStrongPassword(password.new)) {
          setPasswordMsgCheck(false);
          let formData = new FormData();
          for (const key in password) formData.append(key, password[key]);
          props.updatePassword(formData);

          setPassword({
            current: "",
            new: "",
            confirm: "",
            _id: "",
          });
          e.target[0].value = "";
          e.target[1].value = "";
          e.target[2].value = "";
        } else {
          setPasswordMsg(
            "Password must contain Upper Case, Lower Case, a Special Character, a Number and must be at least 8 characters in length",
          );
          setPasswordMsgCheck(true);
        }
      } else {
        setPasswordMsg("New password & confirm password are not same.");
        setPasswordMsgCheck(true);
      }
    } else {
      setPasswordMsg("You have to fill all fields to change password.");
      setPasswordMsgCheck(true);
    }
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileErr = fileValidation(file, allowedMediaTypes.images);
      if (fileErr) {
        toast.error(
          `Supported File Types: ${allowedMediaTypes.images.join(", ")}`,
        );
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setAdmin({ ...admin, profileImage: reader.result });
      };
      setPic({ _id: adminId, profileImage: file });
    }
  };

  const submitPic = (e) => {
    e.preventDefault();
    if (pic.profileImage) {
      let formData = new FormData();
      for (const key in pic) formData.append(key, pic[key]);

      props.updateAdmin(formData);
      setLoader(true);
      document.querySelector("#imageUploader").value = null;
      setPic({ ...pic, profileImage: null });
    } else {
      toast.error("Please upload pic before updating.");
    }
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <div className="section-image" data-image="../../assets/img/bg5.jpg">
            <Row>
              <Col xl="8">
                <Form
                  action=""
                  className="form"
                  onSubmit={(e) => submitCallBack(e)}
                >
                  <Card className="pb-4 table-outer mb-4">
                    <Card.Header>
                      <Card.Header className="p-0">
                        <Card.Title as="h4">Profile</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Form.Group className="mb-3">
                            <label>
                              Name<span className="text-danger"> *</span>
                            </label>
                            <Form.Control
                              value={admin?.fullName}
                              onChange={(e) =>
                                setAdmin({
                                  ...admin,
                                  fullName: e.target.value,
                                })
                              }
                              placeholder="Name"
                              type="text"
                            ></Form.Control>
                            <span className={nameMsg ? `` : `d-none`}>
                              <label className="pl-1 text-danger">
                                {nameMsg}
                              </label>
                            </span>
                          </Form.Group>
                        </Col>
                        <Col className="pl-3" md="6">
                          <Form.Group className="mb-3">
                            <label>
                              Email<span className="text-danger"></span>
                            </label>
                            <Form.Control
                              className="input-bg"
                              value={admin?.email}
                              placeholder="Email"
                              type="email"
                              disabled={true}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <Form.Group className="mb-3">
                            <label>Address</label>
                            <Form.Control
                              value={admin?.address}
                              onChange={(e) =>
                                setAdmin({
                                  ...admin,
                                  address: e.target.value,
                                })
                              }
                              placeholder="Address"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <Form.Group className="mb-3">
                            <label>Phone</label>
                            <Form.Control
                              value={admin?.phone}
                              onChange={(e) =>
                                setAdmin({ ...admin, phone: e.target.value })
                              }
                              type="text"
                              placeholder="Enter Number"
                            ></Form.Control>
                            <span className={phoneMsg ? `` : `d-none`}>
                              <label className="pl-1 text-danger">
                                {phoneMsg}
                              </label>
                            </span>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group className="mb-3">
                            <label>Role</label>
                            <Form.Control
                              className="input-bg"
                              readOnly
                              value={adminRole?.title}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex justify-content-end align-items-center">
                            <Button
                              className="btn-filled"
                              type="button"
                              onClick={submitCallBack}
                            >
                              Update Profile
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col xl="4">
                <Card className="card-user table-outer mb-4">
                  <Card.Body>
                    <div className="author d-flex flex-column justify-content-center align-items-center">
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={
                          admin.profileImage
                            ? admin.profileImage
                            : userDefaultImg
                        }
                      />
                      <Card.Title as="h5">{admin.fullName}</Card.Title>
                      <p className="card-description"></p>
                    </div>

                    <Form className="profile-main-form">
                      <Form.Group className="pl-3 pr-3 d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-file-btn-holder m-0">
                          <Form.Control
                            className="text-white"
                            placeholder="Company"
                            type="file"
                            varient="info"
                            accept=".png,.jpeg,.jpg"
                            onChange={(e) => onFileChange(e)}
                            id="imageUploader"
                          ></Form.Control>
                          <span className="input-file-btn">Choose Image</span>
                        </div>

                        <div>
                          <Button
                            className="btn-filled ms-3"
                            type="button"
                            onClick={submitPic}
                          >
                            Update Pic
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                  <Card.Footer>
                    <div className="pb-2"></div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Form
                  action=""
                  className="form"
                  onSubmit={(e) => passwordForm(e)}
                >
                  <Card className="table-outer pb-4">
                    <Card.Header>
                      <Card.Header className="pl-0">
                        <Card.Title as="h4">Change Password</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="4">
                          <Form.Group className="mb-3">
                            <label>
                              Current Password
                              <span className="text-danger"> *</span>
                              {password.current ? (
                                showPasswords.currentPassword ? (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...showPasswords,
                                        currentPassword: false,
                                      })
                                    }
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...showPasswords,
                                        currentPassword: true,
                                      })
                                    }
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </label>
                            <Form.Control
                              placeholder="Current Password"
                              type={
                                showPasswords.currentPassword
                                  ? "text"
                                  : "password"
                              }
                              onChange={(e) => {
                                setPassword({
                                  ...password,
                                  current: e.target.value,
                                });
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group className="mb-3">
                            <label>
                              New Password
                              <span className="text-danger"> *</span>
                              {password.new ? (
                                showPasswords.password ? (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...showPasswords,
                                        password: false,
                                      })
                                    }
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...showPasswords,
                                        password: true,
                                      })
                                    }
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </label>
                            <Form.Control
                              placeholder="New Password"
                              type={
                                showPasswords.password ? "text" : "password"
                              }
                              onChange={(e) => {
                                setPassword({
                                  ...password,
                                  new: e.target.value,
                                });
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group className="mb-3">
                            <label>
                              Confirm Password
                              <span className="text-danger"> *</span>
                              {password.confirm ? (
                                showPasswords.verifyPassword ? (
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...password,
                                        verifyPassword: false,
                                      })
                                    }
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    onClick={() =>
                                      setShowPasswords({
                                        ...password,
                                        verifyPassword: true,
                                      })
                                    }
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </label>
                            <Form.Control
                              placeholder="Confirm Password"
                              type={
                                showPasswords.verifyPassword
                                  ? "text"
                                  : "password"
                              }
                              onChange={(e) =>
                                setPassword({
                                  ...password,
                                  confirm: e.target.value,
                                  _id: adminId,
                                })
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Group
                          className={passwordMsgCheck ? `` : `d-none`}
                        >
                          <label className="pl-3 text-danger">
                            {passwordMsg}
                          </label>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex justify-content-end align-items-center">
                            <Button className="btn-filled" type="submit">
                              Update Password
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

Profile.propTypes = {
  beforeAdmin: PropTypes.func,
  getAdmin: PropTypes.func,
  updateAdmin: PropTypes.func,
  updatePassword: PropTypes.func,
  admin: PropTypes.object,
  error: PropTypes.object,
  getRoleRes: PropTypes.object,
};

export default connect(mapStateToProps, {
  beforeAdmin,
  getAdmin,
  updateAdmin,
  updatePassword,
})(Profile);
