import { SET_COUNTER, BEFORE_COUNTER, USER_NOTIFICATIONS, BEFORE_NOTIFICATIONS, EDIT_NOTIFICATION_STATUS, BEFORE_NOTIFICATION_STATUS, SET_SOCKET } from '../../redux/types';
import { toast } from 'react-toastify'
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeCounter = () => {
    return {
        type: BEFORE_COUNTER
    }
}

export const setNotificationCounter = (payload) => {
    return {
        type: SET_COUNTER,
        payload
    }
}

export const setSocketInstance = (socket) => {
    return {
        type: SET_SOCKET,
        payload: socket
    }
}

export const beforeUserNotifications = () => {
    return {
        type: BEFORE_NOTIFICATIONS
    }
}

export const getUserNotifications = (qs = null) => dispatch => {
    toast.dismiss()

    let url = `notifications/list`
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: USER_NOTIFICATIONS,
        addAccessToken: true,
        showToast: false
    })
};

export const editSeenStatus = () => dispatch => {
    toast.dismiss()

    generalHttpRequest({
        url: `notifications/edit`,
        method: 'PUT',
        actionType: EDIT_NOTIFICATION_STATUS,
        addAccessToken: true,
        showToast: false
    })
};

export const beforeNotificationsStatus = () => {
    return {
        type: BEFORE_NOTIFICATION_STATUS
    }
}


