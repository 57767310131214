import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { beforeAdmin, resetPassword } from "../admin/admin.action";
import { Images } from '../../assets/assets'
import validator from "validator";
import PropTypes from "prop-types";
import "./resetPassword.css";

const ResetPassword = (props) => {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [password, setPassword] = React.useState({
    new: "",
    confirm: "",
    _id: window.location.pathname.split("/")[3],
    code: window.location.pathname.split("/")[4],
  });
  const [msg, setMsg] = React.useState({
    new: "",
    confirm: "",
  });

  const Submit = (e) => {
    e.preventDefault();
    if (!validator.isEmpty(password.new) && !validator.isEmpty(password.confirm)) {
      if (password.new === password.confirm) {
        if (validator.isStrongPassword(password.new)) {
          setMsg({ new: "", confirm: "" });
          let formData = new FormData();
          for (const key in password) formData.append(key, password[key]);
          props.resetPassword(formData);
        } else {
          setMsg({
            new: "Password must contain Upper Case, Lower Case, a Special Character, a Number and must be at least 8 characters in length",
            confirm: "",
          });
        }
      } else {
        setMsg({
          new: "New password & confirm password are not same.",
          confirm: "",
        });
      }
    } else {
      let passNew = "",
        passConf = "";
      if (validator.isEmpty(password.new)) {
        passNew = "Please fill new password field.";
      }
      if (validator.isEmpty(password.confirm)) {
        passConf = "Please fill confirm password field.";
      }
      setMsg({ new: passNew, confirm: passConf });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setCardClasses("");
    }, 500);
  });

  React.useEffect(() => {
    if (props.admin.resetPasswordAuth) {
      setPassword({ ...password, new: "", confirm: "" });
      props.beforeAdmin();
    }
  }, [props.admin.resetPasswordAuth]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Submit();
    }
  };

  return (
    <>
      <div className="full-page section-image h-100 auth-page" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Row>
              <Col className="mx-auto" lg="6" md="8">
                <Form action="" className="form" method="">
                  <Card className={"card-login " + cardClasses}>
                    <Card.Header className="text-center">
                      <div className="logo-holder d-inline-block align-top">
                        <img src={Images.Logo} className="img-fluid" alt="" />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="form-group">
                        <label>
                          New Password<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <Form.Control placeholder="Password" value={password.new} onChange={(e) => setPassword({ ...password, new: e.target.value })} type="password"></Form.Control>
                          <span>
                            <label className="pl-1 text-danger">{msg.new}</label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Confirm Password<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <Form.Control
                            placeholder="Password"
                            type="password"
                            value={password.confirm}
                            onChange={(e) => setPassword({ ...password, confirm: e.target.value })}
                            onKeyDown={handleKeyPress}
                          ></Form.Control>
                          <span>
                            <label className="pl-1 text-danger">{msg.confirm}</label>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Link to="/" className="btn-no-bg" type="submit" variant="warning">
                          Login
                        </Link>
                      </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-center">
                      <Button className="btn-filled" type="submit" onClick={Submit}>
                        Submit
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  error: state.error,
});

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  beforeAdmin: PropTypes.func,
  admin: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, { beforeAdmin, resetPassword })(ResetPassword);
