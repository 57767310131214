import React from "react";
import { useState, useEffect } from "react";
import { ENV } from "../../config/config";
import { getSettings, beforeSettings, editSettings } from "./settings.action";
import { connect } from "react-redux";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import validator from "validator";
import PropTypes from "prop-types";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { isValidUrl } from "utils/validations/validations";

const { objectToQueryString } = ENV;

const SocialSettings = (props) => {
  const [links, setLinks] = useState({
    discord: "",
    twitter: "",
    youtube: "",
    instagram: "",
    linkedIn: "",
    medium: "",
    telegram: "",
    reddit: "",
    facebook: "",
  });
  const [permissions, setPermissions] = useState({});
  const [loader, setLoader] = useState(true);
  const [msg, setMsg] = useState({
    twitter: "",
    youtube: "",
    linkedIn: "",
    facebook: "",
    pinterest: "",
  });

  useEffect(() => {
    window.scroll(0, 0);

    if (localStorage.getItem("userID")) {
      props.getSettings();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setPermissions(props.getRoleRes.role);
      props.beforeRole();
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (props.settings.settingsAuth) {
      const settingsData = props.settings?.settings?.settings;
      if (settingsData) {
        setLoader(false);
        setLinks({ ...links, ...settingsData });
      }
      props.beforeSettings();
    }
  }, [props.settings.settingsAuth]);

  useEffect(() => {
    if (props.settings.editSettingsAuth) {
      setLoader(false);
      props.beforeSettings();
      props.getSettings(false);
    }
  }, [props.settings.editSettingsAuth]);

  const save = () => {
    let check = true;

    let twitter = "";
    let youtube = "";
    let linkedIn = "";
    let facebook = "";
    let pinterest = "";

    if (!validator.isEmpty(links.twitter) && !isValidUrl(links.twitter)) {
      twitter = "Invalid Url";
      check = false;
    }
    if (!validator.isEmpty(links.youtube) && !isValidUrl(links.youtube)) {
      youtube = "Invalid Url";
      check = false;
    }
    if (!validator.isEmpty(links.pinterest) && !isValidUrl(links.pinterest)) {
      pinterest = "Invalid Url";
      check = false;
    }
    if (!validator.isEmpty(links.linkedIn) && !isValidUrl(links.linkedIn)) {
      linkedIn = "Invalid Url";
      check = false;
    }
    if (!validator.isEmpty(links.facebook) && !isValidUrl(links.facebook)) {
      facebook = "Invalid Url";
      check = false;
    }

    setMsg({
      twitter,
      youtube,
      pinterest,
      linkedIn,
      facebook,
    });

    if (check) {
      let formData = new FormData();
      for (const key in links) formData.append(key, links[key]);
      const qs = objectToQueryString({ type: "2" });
      props.editSettings(formData, qs);
      setLoader(true);
    }
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Form
                action=""
                className="form-horizontal"
                id="TypeValidation"
                method=""
              >
                <Card className="table-outer">
                  <Card.Header>
                    <div className="d-block d-md-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Social Settings</Card.Title>
                    </div>
                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <Col xl={4} md={6}>
                      <Form.Group  className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Twitter
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={links.twitter}
                            onChange={(e) =>
                              setLinks({ ...links, twitter: e.target.value })
                            }
                          ></Form.Control>
                          <span className={msg.twitter ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.twitter}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                      <Form.Group  className="mb-3">
                          <Form.Label className="d-block mb-2">
                            YouTube
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={links.youtube}
                            onChange={(e) =>
                              setLinks({ ...links, youtube: e.target.value })
                            }
                          ></Form.Control>
                          <span className={msg.youtube ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.youtube}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                      <Form.Group  className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Pinterest{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={links.pinterest}
                            onChange={(e) =>
                              setLinks({ ...links, pinterest: e.target.value })
                            }
                          ></Form.Control>
                          <span className={msg.pinterest ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.pinterest}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                      <Form.Group  className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Linked In
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={links.linkedIn}
                            onChange={(e) =>
                              setLinks({ ...links, linkedIn: e.target.value })
                            }
                          ></Form.Control>
                          <span className={msg.linkedIn ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.linkedIn}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                      <Form.Group  className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Facebook
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={links.facebook}
                            onChange={(e) =>
                              setLinks({ ...links, facebook: e.target.value })
                            }
                          ></Form.Control>
                          <span className={msg.facebook ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.facebook}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Row className="float-right">
                      {permissions && permissions.editSetting && (
                        <Col sm={12} className="d-flex">
                          <Button className="btn-filled mt-3" onClick={save}>
                            {" "}
                            Save{" "}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

SocialSettings.propTypes = {
  getSettings: PropTypes.func,
  beforeSettings: PropTypes.func,
  editSettings: PropTypes.func,
  beforeRole: PropTypes.func,
  getRoleRes: PropTypes.object,
  settings: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, {
  getSettings,
  beforeSettings,
  editSettings,
  beforeRole,
})(SocialSettings);
