import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { beforeAdmin, login } from "../admin/admin.action";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import {useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import { ENV } from "../../config/config";
import { Images } from '../../assets/assets'
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const CryptoJS = require("crypto-js");
const { secretKey } = ENV;

const Login = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState({ emailMsg: "", passwordMsg: "" });
  const [showPassword, setShowPass] = useState(false);

  useEffect(() => {
    if (props.admin.loginAdminAuth) {
      const roleId = props.admin.admin?.data?.roleId;
      let { data } = props.admin?.admin;
      let { adminStatus } = props.admin?.admin;

      const encryptedRole = CryptoJS.AES.encrypt(roleId, secretKey).toString();
      localStorage.setItem("role", encryptedRole);
      localStorage.setItem("userID", data?._id);

      if (adminStatus) localStorage.setItem("accessToken", data?.accessToken);
      else toast.error("You are not active. Kindly contact admin!");

      navigate("/dashboard");
    }
  }, [props.admin.loginAdminAuth]);

  // when an error is received
  useEffect(() => {
    if (props.error.error) setLoader(false);
  }, [props.error.error]);

  const onChange = (e) => {
    let { name, value } = e.target;
    let data = user;
    data[name] = value;
    setUser({ ...data });
  };

  const submit = async () => {
    let check = true;
    if (validator.isEmpty(user.email)) {
      check = false;
      setMsg({ emailMsg: "Email is Required" });
    } else if (!validator.isEmail(user.email)) {
      check = false;
      setMsg({ emailMsg: "Invalid Email" });
    }

    if (validator.isEmpty(user.password)) {
      check = false;
      setMsg({ passwordMsg: "Password is required" });
    }

    if (check) {
      setMsg({ emailMsg: "", passwordMsg: "" });
      setLoader(true);
      props.login(user);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="full-page section-image h-100 auth-page p-0" data-color="black">
          <div className="content d-flex align-items-center p-0">
            <Container>
              <Row>
                <Col className="mx-auto" lg="6" md="8">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <Card.Header className="text-center">
                        <div className="logo-holder d-inline-block align-top mb-3">
                          <img src={Images.Logo} className="img-fluid" alt="" />
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div className="form-group" onKeyDown={handleKeyPress}>
                          <label>
                            Email address <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <Form.Control placeholder="Enter email" type="text" name="email" onChange={(e) => onChange(e)} defaultValue={user.email} />
                            <span className={msg.emailMsg ? `` : ``}>
                              <label className="pl-1 text-danger">{msg.emailMsg}</label>
                            </span>
                          </div>
                        </div>
                        <div className="form-group position-relative" onKeyDown={handleKeyPress}>
                          <label>
                            Password <span className="text-danger">*</span>
                            <div className="password-show-hide">
                              {user.password ? (
                                showPassword ? (
                                  <FontAwesomeIcon icon={faEye} className="eye-icon" onClick={() => setShowPass(false)} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} className="eye-icon" onClick={() => setShowPass(true)} />
                                )
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} className="eye-icon" onClick={() => setShowPass(true)} />
                              )}
                            </div>
                          </label>
                          <Form.Control
                            className="password-field"
                            placeholder="Enter Password"
                            value={user.password}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => onChange(e)}
                          />
                          <span className={msg.passwordMsg ? `` : <label>&nbsp;</label>}>
                            <label className="pl-1 text-danger">{msg.passwordMsg}</label>
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="pl-0 form-check">
                            <input type="checkbox" className="form-check-input position-static" />
                          </div>
                          <Link to="/forgot-password" className="btn-no-bg" type="submit" variant="warning">
                            Forgot Password ?
                          </Link>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex justify-content-center">
                        <Button className="btn-filled" type="button" disabled={loader} onClick={() => submit()}>
                          Login
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  error: state.error,
});

Login.propTypes = {
  login: PropTypes.func,
  beforeAdmin: PropTypes.func,
  admin: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, { beforeAdmin, login })(Login);
