import { uploadPlugin } from "components/ckEditor/ckEditor";

export const ckEditorCustomConfig = {
  extraPlugins: [uploadPlugin],
  htmlSupport: {
    // preserve html styles
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
};
