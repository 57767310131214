import React from "react";
import { useState, useEffect, useRef } from "react";
import { ENV } from "../../config/config";
import { getSettings, beforeSettings, editSettings } from "./settings.action";
import { connect } from "react-redux";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import validator from "validator";
import PropTypes from "prop-types";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";

const { googleMapApiKey, objectToQueryString } = ENV;

const SiteSettings = (props) => {
  const [permissions, setPermissions] = useState({});
  const [loader, setLoader] = useState(true);
  const [msg, setMsg] = useState({
    email: "",
    phone: "",
    mobile: "",
    domain: "",
  });
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
    if (localStorage.getItem("userID")) {
      props.getSettings();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setPermissions(props.getRoleRes.role);
      props.beforeRole();
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (props.settings.settingsAuth) {
      setLoader(false);
      const settingsData = props.settings?.settings?.settings;
      if (settingsData) {
        setSettings({ ...settings, ...settingsData });
        setLocation({ ...settingsData.location });
      }
      props.beforeSettings();
    }
  }, [props.settings.settingsAuth]);

  useEffect(() => {
    if (props.settings.editSettingsAuth) {
      setLoader(false);
      props.beforeSettings();
      props.getSettings(false);
    }
  }, [props.settings.editSettingsAuth]);

  const [settings, setSettings] = useState({
    email: "",
    mobile: "",
    phone: "",
    address: "",
    desc: "",
    domain: "",
    api: "",
  });

  const submit = () => {
    let check = true;
    let email = "";
    let phone = "";
    let mobile = "";
    let domain = "";
    let api = "";
    let address = "";

    if (
      !validator.isEmpty(settings.email) &&
      !validator.isEmail(settings.email)
    ) {
      email = "Invalid Email";
      check = false;
    }
    if (
      !validator.isEmpty(settings.phone) &&
      !validator.isMobilePhone(settings.phone)
    ) {
      phone = "Invalid Phone No";
      check = false;
    }
    if (
      !validator.isEmpty(settings.mobile) &&
      !validator.isMobilePhone(settings.mobile)
    ) {
      mobile = "Invalid Mobile No";
      check = false;
    }

    if (validator.isEmpty(settings.domain)) {
      domain = "Domain Required";
      check = false;
    }

    if (validator.isEmpty(settings.address)) {
      address = "Address Required";
      check = false;
    } else if (!location || location.lat == 0 || location.lng == 0) {
      address = "Invalid Address";
      check = false;
    }

    if (validator.isEmpty(settings.api)) {
      api = "Mailgun Api Key Required";
      check = false;
    }

    setMsg({
      email,
      phone,
      mobile,
      domain,
      api,
      address,
    });

    if (check) {
      setMsg({
        email: "",
        mobile: "",
        phone: "",
        address: "",
        domain: "",
        api: "",
      });
      let formData = new FormData();
      for (const key in settings) formData.append(key, settings[key]);
      for (const key in location) formData.append(key, location[key]);

      const qs = objectToQueryString({ type: "1" });
      props.editSettings(formData, qs);
      setLoader(true);
    }
  };

  const loadMap = (map, maps) => {
    // create marker on map
    let positionMarker = new maps.Marker({
      position: { lat: location.lat, lng: location.lng },
      map,
      draggable: true,
    });

    // set location & address when marker is dragged on map
    positionMarker.addListener("dragend", () => {
      let lat = positionMarker.getPosition().lat();
      let lng = positionMarker.getPosition().lng();
      setLocation({ ...location, lat, lng });

      // get address where marked is dropped
      const geocoder = new maps.Geocoder();
      geocoder.geocode(
        { latLng: positionMarker.getPosition() },
        (results, status) => {
          if (status == maps.GeocoderStatus.OK) {
            if (results[0]) {
              setSettings({
                ...settings,
                address: results[0].formatted_address,
              });
            }
          }
        },
      );
    });

    // create autocomplete search instance to get suggestions while entering places
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };

    let autocompleteSearch = new maps.places.Autocomplete(
      inputRef.current,
      options,
    );

    // set location and address when user inputs a place
    autocompleteSearch.addListener("place_changed", () => {
      let { formatted_address, geometry } = autocompleteSearch.getPlace();
      if (!geometry) {
        setLocation({ lat: 0, lng: 0 });
        return;
      }

      let lat = geometry.location.lat();
      let lng = geometry.location.lng();

      // if the place is not in current viewport, set map bounds to fit that viewport
      if (geometry.viewport) {
        map.fitBounds(geometry.viewport);
      } else {
        map.setCenter(geometry.location);
        map.setZoom(11);
      }

      positionMarker.setPosition(geometry.location);
      positionMarker.setVisible(true);

      setLocation({ ...location, lat, lng });
      setSettings({ ...settings, address: formatted_address });
    });
  };

  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Form
                action=""
                className="form-horizontal"
                id="TypeValidation"
                method=""
              >
                <Card className="table-outer">
                  <Card.Header>
                    <div className="d-block d-md-flex align-items-center justify-content-between">
                      <Card.Title as="h4">Site Settings</Card.Title>
                    </div>
                  </Card.Header>

                  <Card.Body>
                    <Row>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Site Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            value={settings.email}
                            onChange={(e) => {
                              setSettings({
                                ...settings,
                                email: e.target.value,
                              });
                            }}
                          ></Form.Control>
                        </Form.Group>
                        <span className={msg.email ? `` : `d-none`}>
                          <label className="pl-1 pt-0 text-danger">
                            {msg.email}
                          </label>
                        </span>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Mobile No
                          </Form.Label>
                          <Form.Control
                            type="text"
                            maxLength={12}
                            value={settings.mobile}
                            onChange={(e) => {
                              setSettings({
                                ...settings,
                                mobile: e.target.value,
                              });
                            }}
                          ></Form.Control>
                          <span className={msg.mobile ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.mobile}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Phone No
                          </Form.Label>
                          <Form.Control
                            type="text"
                            maxLength={12}
                            value={settings.phone}
                            onChange={(e) => {
                              setSettings({
                                ...settings,
                                phone: e.target.value,
                              });
                            }}
                          ></Form.Control>
                          <span className={msg.phone ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.phone}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Footer Description
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={settings.desc}
                            onChange={(e) => {
                              setSettings({
                                ...settings,
                                desc: e.target.value,
                              });
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Mailgun Domain
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={settings.domain}
                            onChange={(e) => {
                              setSettings({
                                ...settings,
                                domain: e.target.value,
                              });
                            }}
                          ></Form.Control>
                          <span className={msg.domain ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.domain}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Mailgun Api-Key
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={settings.api}
                            onChange={(e) => {
                              setSettings({ ...settings, api: e.target.value });
                            }}
                          ></Form.Control>
                          <span className={msg.api ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.api}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="d-block mb-2">
                            Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            ref={inputRef}
                            value={settings.address}
                            placeholder="Search"
                            onChange={(e) =>
                              setSettings({
                                ...settings,
                                address: e.target.value,
                              })
                            }
                          ></Form.Control>
                          <span className={msg.address ? `` : `d-none`}>
                            <label className="pl-1 pt-0 text-danger">
                              {msg.address}
                            </label>
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>

                  </Card.Body>
                  <Card.Footer>
                    <Row className="float-right">
                      {permissions && permissions.editSetting && (
                        <Col sm={12}>
                          <Button className="btn-filled" onClick={submit}>
                            Save
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

SiteSettings.propTypes = {
  getSettings: PropTypes.func,
  beforeSettings: PropTypes.func,
  editSettings: PropTypes.func,
  beforeRole: PropTypes.func,
  getRoleRes: PropTypes.object,
  settings: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, {
  getSettings,
  beforeSettings,
  editSettings,
  beforeRole,
})(SiteSettings);
