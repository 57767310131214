import React, { useEffect, useState } from "react";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import PropTypes from "prop-types";

const Unauth = (props) => {
  const [loader, setLoader] = useState(false);
  let accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (accessToken) {
      setLoader(true);
      window.location.href = "/admin/dashboard";
    }
  }, []);

  return (
    <div className="wrapper login-wrapper">
      {loader ? <FullPageLoader /> : props.children}
    </div>
  );
};

Unauth.propTypes = {
  children: PropTypes.object,
};
export default Unauth;
