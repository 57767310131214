import { GET_CONTENT_PAGE, GET_CONTENT_PAGES, EDIT_CONTENT_PAGE, DELETE_CONTENT_PAGE, ADD_CONTENT_PAGE, BEFORE_CONTENT } from '../../redux/types'
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeContent = () => {
    return {
        type: BEFORE_CONTENT
    }
}

export const listContent = (qs = '', body = {}, showToast = true) => dispatch => {

    let url = `content/list`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'POST',
        body,
        actionType: GET_CONTENT_PAGES,
        addAccessToken: true
    })
};

export const updateContent = (body) => dispatch => {

    generalHttpRequest({
        url: `content/edit`,
        method: 'PUT',
        body,
        actionType: EDIT_CONTENT_PAGE,
        addAccessToken: true
    })
};

export const deleteContent = (contentId) => dispatch => {

    generalHttpRequest({
        url: `content/delete/${contentId}`,
        method: 'DELETE',
        actionType: DELETE_CONTENT_PAGE,
        addAccessToken: true
    })
};

export const addContent = (body) => dispatch => {

    generalHttpRequest({
        url: `content/create`,
        method: 'POST',
        body,
        actionType: ADD_CONTENT_PAGE,
    })
};

export const getContent = (contentId) => dispatch => {

    generalHttpRequest({
        url: `content/get/${contentId}`,
        method: 'GET',
        actionType: GET_CONTENT_PAGE,
        addAccessToken: true
    })
};