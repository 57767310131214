import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import App from "./App";
import store from "./store";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/style.css";

import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer newestOnTop={true} position="top-right" pauseOnHover={true} autoClose={3000} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
