import React from "react";
import { useEffect, useState } from "react";
import { ENV } from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { getUserNotifications, setNotificationCounter, setSocketInstance, beforeNotificationsStatus, beforeUserNotifications, beforeCounter, editSeenStatus } from "./notifications.actions";
import moment from "moment";
const { events, backendBaseUrl, objectToQueryString } = ENV;

const AdminNotifications = () => {
  const dispatch = useDispatch();

  const [counter, setCounter] = useState();
  const [page, setPage] = useState(2); // next page for infinite scroll
  const [moreCheck, setMoreCheck] = useState(true);
  const [showSmallLoader, setSmallLoader] = useState(true);
  const [counterSeen, setCounterSeen] = useState(false);
  const [noticationsSeen, setNotSeen] = useState(false);
  const [openModal, setOpen] = useState(false);
  const [notificationsList, setList] = useState([]);

  const notificationsSelector = useSelector((state) => state.notifications);

  useEffect(() => {
    let socket = io(backendBaseUrl);
    socket.on("connect", () => {
      dispatch(setSocketInstance(socket));
      notificationsHandler(socket);
    });
    return () => socket.close();
  }, []);

  useEffect(() => {
    if (notificationsSelector.notificationsCounter) {
      let notificationCounter = notificationsSelector.notificationsCounter;
      notificationCounter = notificationCounter ? notificationCounter : null;

      setCounter(notificationCounter);
      localStorage.setItem("adminNotificationsCounter", notificationCounter || 0);

      setCounterSeen(false);
      setNotSeen(false);

      // if new notification is received while modal is open
      if (openModal) {
        let filteredList = notificationsList.filter((n) => n.type === 0);
        if (!filteredList.length) {
          let list = [{ type: 0 }].concat(notificationsList);
          setList(list);
        }
      }
    }
  }, [notificationsSelector.notificationsCounter]);

  const notificationsHandler = (socket) => {
    if (!socket.connected) {
      socket = io(backendBaseUrl, { forceNew: true });
      socket.on("connect", () => {
        notificationsHandler(socket);
      });
    }

    let notificationsData = [...events.notifications];
    notificationsData.map((n) => {
      socket.on(`${n.name}-admin`, (data) => {
        dispatch(setNotificationCounter());
      });
    });
  };

  const getNotifications = (filter = {}) => {
    let qs = objectToQueryString(filter);
    dispatch(getUserNotifications(qs));
  };

  useEffect(() => {
    if (localStorage.getItem("userID")) {
      // fetch unread notifications
      getNotifications({ hasSeen: 2, all: true });
    }
  }, []);

  useEffect(() => {
    if (openModal) setOpen(false);
  }, [window.location.pathname]);

  useEffect(() => {
    // fetch notifications
    if (openModal) {
      getNotifications();
    }
  }, [openModal]);

  useEffect(() => {
    if (notificationsSelector.adminNotificationsAuth) {
      const { adminNotifications, total } = notificationsSelector.adminNotifications;
      // unread notifications
      if (total !== undefined) {
        setCounter(total ? total : null);
        localStorage.setItem("adminNotificationsCounter", total);
        dispatch(setNotificationCounter(total));
      }
      if (adminNotifications) {
        setSmallLoader(false);
        if (adminNotifications.length) {
          setList(notificationsList.concat(adminNotifications));
        } else {
          setMoreCheck(false);
        }
      }
      dispatch(beforeUserNotifications());
    }
  }, [notificationsSelector.adminNotificationsAuth]);

  useEffect(() => {
    if (notificationsList && notificationsList.length) {
      let nCounter = 0;
      notificationsList.map((n) => {
        if (!n.hasSeen) nCounter++;
      });
      if (!counter && nCounter > 0) setCounter(nCounter);
    }
  }, [notificationsList]);

  useEffect(() => {
    if (notificationsSelector.editNotificationsStatusAuth) {
      const { editNotificationsStatus } = notificationsSelector;

      // reset states when notification has been seen
      setCounterSeen(true);
      setNotSeen(true);
      setCounter(null);
      localStorage.setItem("adminNotificationsCounter", 0);

      dispatch(beforeCounter());
      dispatch(beforeNotificationsStatus());
    }
  }, [notificationsSelector.editNotificationsStatusAuth]);

  const closeModal = () => {
    setOpen(false);
    setList([]);
    setPage(2);
  };

  const fetchData = () => {
    getNotifications({ page });
    setPage(page + 1);
  };

  const loadNewNotifications = () => {
    setSmallLoader(true);
    setList([]);
    getNotifications();
    setTimeout(() => dispatch(editSeenStatus()), 1500);
  };

  const getNotificationsText = (notification) => {
    if (notification.type === 0)
      return (
        <>
          <FontAwesomeIcon icon={faArrowUp} />
          Show New Notifications
        </>
      );
    else if (notification.type === 1) return <>{`This is a dummy notification `}</>;
  };

  return (
    <>
      <div
        className="notification-icon cursor-pointer"
        onClick={() => {
          setOpen(true);
          setTimeout(() => dispatch(editSeenStatus()), 1500);
        }}
      >
        <FontAwesomeIcon icon={faBell} />
        <span className={` ${counter && counter !== 0 ? "notification-counter" : ""}`} style={{ fontWeight: !counterSeen ? "bold" : "" }}>
          {counter}
        </span>
      </div>
      {openModal && (
        <div className="modal-wrapper">
          <Modal centered size="md" show={openModal} onHide={closeModal} className="notification-modal text-white">
            <Modal.Header className="text-center  modal-title-wrapper">
              <Modal.Title className="mb-0 d-flex justify-content-between w-100 mt-0 mb-3">
               <h4 className="text-black">NOTIFICATIONS</h4>
                <button type="button" onClick={closeModal} className="close-icon">
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row pt-2  p-4 notification-modal">
              <div className="container">
                <div className="row">
                  {
                    <div className="col-12">
                      {showSmallLoader ? (
                        <p className="text-black">Loading...</p>
                      ) : (
                        <ul className="list-unstyled">
                          <InfiniteScroll dataLength={notificationsList.length} next={fetchData} hasMore={moreCheck} loader={<p>Loading...</p>} height={400}>
                            {notificationsList?.length > 0 ? (
                              notificationsList?.map((notification, idx) => {
                                return (
                                  <li key={`notification-${idx}`}>
                                    <div className="my-2">
                                      <div className="d-flex align-items-start">
                                        {notification.type === 0 ? (
                                          <div className="col-12" onClick={loadNewNotifications}>
                                            <p className="mb-0 pointer">
                                              {getNotificationsText({
                                                type: 0,
                                              })}
                                            </p>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="userImg me-2">
                                              <img src={notification.thumbnail} className="img-fluid" alt={`thumbnail-${idx}`} />
                                            </div>
                                            <div className="rest-owner">
                                              <div className="col-12">
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    fontWeight: noticationsSeen || notification.hasSeen ? "" : "bold",
                                                  }}
                                                >
                                                  {getNotificationsText(notification)}
                                                </p>
                                              </div>
                                              <div className="col-12">
                                                <p className="mb-0 notification-date">{moment(notification.createdAt).format("DD/MM/YYYY HH:mm")}</p>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li className="text-white pb-2">There are no new notifications</li>
                            )}
                          </InfiniteScroll>
                        </ul>
                      )}
                    </div>
                  }
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AdminNotifications;
