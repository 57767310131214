import { SET_COUNTER, BEFORE_COUNTER, USER_NOTIFICATIONS, BEFORE_NOTIFICATIONS, EDIT_NOTIFICATION_STATUS, BEFORE_NOTIFICATION_STATUS, SET_SOCKET } from '../../redux/types';

const initialState = {
    clientSocket: null,

    notificationsCounter: localStorage.getItem('adminNotificationsCounter') ? JSON.parse(localStorage.getItem('adminNotificationsCounter')) : null,
    notificationsCounterAuth: false,

    adminNotifications: null,
    adminNotificationsAuth: false,

    editNotificationsStatus: null,
    editNotificationsStatusAuth: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SOCKET:
            return {
                ...state,
                clientSocket: action.payload,
            }
        case SET_COUNTER:
            return {
                ...state,
                notificationsCounter: action.payload !== undefined ? action.payload : state.notificationsCounter + 1,
                notificationsCounterAuth: true
            }
        case BEFORE_COUNTER:
            return {
                ...state,
                notificationsCounter: null,
                notificationsCounterAuth: false
            }
        case USER_NOTIFICATIONS:
            return {
                ...state,
                adminNotifications: action.payload,
                adminNotificationsAuth: true
            }
        case BEFORE_NOTIFICATIONS:
            return {
                ...state,
                adminNotifications: null,
                adminNotificationsAuth: false
            }
        case EDIT_NOTIFICATION_STATUS:
            return {
                ...state,
                editNotificationsStatus: action.payload,
                editNotificationsStatusAuth: true
            }
        case BEFORE_NOTIFICATION_STATUS:
            return {
                ...state,
                editNotificationsStatus: null,
                editNotificationsStatusAuth: false
            }
        default:
            return {
                ...state
            }
    }
}