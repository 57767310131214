import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getStaffAdmins, deleteAdmin, beforeAdmin } from "views/admin/admin.action";
import { getRoles, beforeRole } from "../permissions/permissions.actions";
import { getPermission } from "../permissions/permissions.actions";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Pagination from "rc-pagination";
import PropTypes from "prop-types";
import "rc-pagination/assets/index.css";
import StaffModal from "./staffModalComponent";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { Button, Card, Form, Table, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ENV } from "config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const { objectToQueryString, secretKey } = ENV;
const CryptoJS = require("crypto-js");

const AdminStaff = (props) => {
  const [admins, setAdmins] = useState({});
  const [admin, setAdmin] = useState({});
  const [currentUserRole, setCurrentUserRole] = useState({});
  const [isLoader, setLoader] = useState(true);
  const [roleModal, setroleModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [roles, setRoles] = useState();
  const [rolesOptions, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [roleSearch, setRoleSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [resetButton, setResetButton] = useState(false);
  const adminId = localStorage.getItem("userID");
  const [flag, setFlag] = useState(false);
  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];

  const setModal = (type = 1, admin = {}) => {
    setFlag((prevVal) => !prevVal);
    setroleModal(!roleModal);
    setModalType(type);
    setLoader(false);

    if ((type === 2 || type === 3) && admin) {
      setAdmin(admin);
    }
  };

  const deleteRoleHandler = (adminId) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete a staff, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        props.deleteAdmin(adminId);
      }
    });
  };

  const onSearch = () => {
    if (search || email || searchStatus || roleSearch) {
      setResetButton(true);
      const qs = objectToQueryString({
        title: search.trim(),
        email: encodeURIComponent(email.trim()),
        status: searchStatus,
        roleId: roleSearch,
        page: 1,
        limit: 10,
        adminId,
      });
      props.getStaffAdmins(qs);
      setLoader(true);
    } else {
      toast.error("All filters are empty.", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch();
    }
  };

  const reset = () => {
    setLoader(true);
    setResetButton(false);
    setSearch("");
    setEmail("");
    setRoleSearch("");
    setSearchStatus("");
    const qs = objectToQueryString({ page: 1, limit: 10, adminId });
    props.getStaffAdmins(qs);
  };

  const onPageChange = (page) => {
    const qs = objectToQueryString({
      title: search.trim(),
      email: email.trim(),
      status: searchStatus,
      roleId: roleSearch,
      page,
      limit,
      adminId,
    });
    props.getStaffAdmins(qs);
    setLoader(true);
  };

  const getData = (admin) => {
    const qs = objectToQueryString({
      title: search.trim(),
      page,
      limit,
      adminId,
    });
    props.getStaffAdmins(qs);
    setAdmin(admin);
  };

  useEffect(() => {
    let roleEncrypted = localStorage.getItem("role");
    let role = "";
    if (roleEncrypted) {
      let roleDecrepted = CryptoJS.AES.decrypt(roleEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
      props.getPermission(roleDecrepted);

      const qs = objectToQueryString({
        page: 1,
        limit: 10,
        adminId: localStorage.getItem("userID"),
      });
      props.getStaffAdmins(qs);
      props.getRoles('', true, false);
    }
  }, []);

  useEffect(() => {
    if (props.currentUserPermission.authPermission) {
      setCurrentUserRole(props.currentUserPermission.permission.role);
    }
  }, [props.currentUserPermission.authPermission]);

  useEffect(() => {
    if (props.getAdminAuth) {
      setLoader(false);
      let { data } = props.getAdminsRes;
      setAdmins(data.admins);
      setPage(data.pagination.page);
      setPages(data.pagination.pages);
      setLimit(data.pagination.limit);
      props.beforeAdmin();
      setLoader(false);
    }
  }, [props.getAdminAuth]);

  useEffect(() => {
    if (Object.keys(props.deleteAdminRes).length > 0) {
      setModalType(1);
      setLoader(false);
      props.beforeAdmin();

      const qs = objectToQueryString({ page: 1, limit: 10, adminId });
      props.getStaffAdmins(qs);
    }
  }, [props.deleteAdminRes]);

  useEffect(() => {
    if (Object.keys(props.getRolesRes).length > 0) {
      let data = props.getRolesRes.data;
      setRoles(data);
      let options = [];
      if (data?.length) {
        data.map((d) => {
          options.push({ value: d._id, label: d.title });
        });
      }
      setOptions(options);
      props.beforeRole();
    }
  }, [props.getRolesRes]);

  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );

  return (
    <>
      {isLoader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="filter-card card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6} className="search-wrap">
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">Search with name...</Form.Label>
                        <Form.Control onKeyDown={handleKeyPress} name="search" placeholder="Name" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">Search with email...</Form.Label>
                        <Form.Control onKeyDown={handleKeyPress} name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Search with User Role...</label>
                    <Form.Group  className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Role"
                          className="w-100"
                          name="roleSearch"
                          options={rolesOptions}
                          onChange={(e) => setRoleSearch(e ? e.value : "")}
                          value={rolesOptions.filter((r) => r.value === roleSearch)}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Search with status...</label>
                    <Form.Group  className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Status"
                          name="status"
                          options={statusOptions}
                          onChange={(e) => setSearchStatus(e ? e.value : "")}
                          value={statusOptions.filter((l) => l.value === searchStatus)}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <label className="d-none d-sm-block mb-2 form-label">&nbsp;</label>
                        <div className="d-flex filter-btns-holder">
                          <button type="button" className="btn-filled me-3" onClick={onSearch}>
                            Search
                          </button>
                          {resetButton && (
                            <button type="button" className="outline-button" onClick={reset}>
                              Reset
                            </button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div className="table-outer">
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Staff</Card.Title>
                    {currentUserRole && currentUserRole.addStaff ? (
                      <Button className="btn-filled d-flex align-items-center" onClick={() => setModal(1)}>
                        <span className="add-icon me-2">
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                        Add New Staff
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width staff-table">
                  <div className="table-responsive">
                    <Table className="custom-table">
                      <thead>
                        <tr>
                          <th className="td-start">#</th>
                          <th className="td-name">Name</th>
                          <th className="td-email">Email</th>
                          <th className="td-role">User Role</th>
                          <th className="text-center td-status">Status</th>
                          <th className="td-actions text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admins && admins.length > 0 ? (
                          admins.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{limit * page - limit + key + 1}</td>
                                <td className="td-name">{val.fullName}</td>
                                <td className="td-email">{val.email}</td>
                                <td className="td-role">{val.role.title}</td>
                                <td className="text-center">
                                  {val.status ? <label className="status-tag bg-success text-white">Active</label> : <label className="status-tag bg-danger">Inactive</label>}
                                </td>
                                <td className="td-actions">
                                  <ul className="list-unstyled mb-0 d-flex">
                                    {currentUserRole?.viewStaff ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          overlay={renderViewTooltip}
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                        >
                                          <button type="button" className="btn-link btn-icon btn btn-info" onClick={() => setModal(2, val)}>
                                            <i className="fas fa-eye"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                    {currentUserRole?.editStaff ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          overlay={renderEditTooltip}
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                        >
                                          <button
                                            type="button"
                                            className="btn-link btn-icon btn btn-success"
                                            onClick={() => setModal(3, val)}
                                            disabled={val._id === localStorage.getItem("userID") || val.isSuperAdmin}
                                          >
                                            <i className="fas fa-edit"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                    {currentUserRole?.deleteStaff ? (
                                      <li className="d-inline-block align-top">
                                        <OverlayTrigger
                                          overlay={renderDeleteTooltip}
                                          placement="left"
                                          delay={{ show: 150, hide: 200 }}
                                        >
                                          <button
                                            type="button"
                                            className="btn-link btn-icon btn btn-danger"
                                            onClick={() => deleteRoleHandler(val._id)}
                                            disabled={val._id === localStorage.getItem("userID") || val.isSuperAdmin}
                                          >
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    ) : null}
                                  </ul>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center px-0" colSpan="6">
                              <span className="alert alert-info d-block text-center">No Staff Found</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Col>
                      <Pagination
                        defaultCurrent={1}
                        pageSize // items per page
                        current={page} // current active page
                        total={pages} // total pages
                        onChange={onPageChange}
                        locale={localeInfo}
                      />
                    </Col>
                  </div>
                </Card.Body>
              </Card>
              </div>
            </Col>
          </Row>
          <StaffModal
            flag={flag}
            getData={getData}
            modalType={modalType}
            setModalType={setModalType}
            roleModal={roleModal}
            setroleModal={setroleModal}
            setLoader={setLoader}
            admin={admin}
            roles={roles}
          />
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getAdminsRes: state.admin.getAdminsRes,
  getAdminAuth: state.admin.getAuth,
  currentUserPermission: state.role,
  deleteAdminRes: state.admin.deleteAdminRes,
  getRolesRes: state.role.getRolesRes,
});

AdminStaff.propTypes = {
  getStaffAdmins: PropTypes.func,
  deleteAdmin: PropTypes.func,
  beforeAdmin: PropTypes.func,
  getRoles: PropTypes.func,
  beforeRole: PropTypes.func,
  getPermission: PropTypes.func,
  getAdminsRes: PropTypes.object,
  getRolesRes: PropTypes.object,
  deleteAdminRes: PropTypes.object,
  getAdminAuth: PropTypes.bool,
  deleteRoleRes: PropTypes.object,
  currentUserPermission: PropTypes.object,
};

export default connect(mapStateToProps, {
  getStaffAdmins,
  deleteAdmin,
  beforeAdmin,
  getRoles,
  beforeRole,
  getPermission,
})(AdminStaff);
