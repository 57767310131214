import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { beforeRole } from "views/adminStaff/permissions/permissions.actions";
import { ENV } from "../../config/config";
import { beforeFaq, deleteFaq, getFaqs } from "./faqs.actions";
import Select from "react-select";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

const { objectToQueryString } = ENV;

const Faq = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [loader, setLoader] = useState(true);
  const [resetButton, setResetButton] = useState(false);
  const [filters, setFilters] = useState({
    title: "",
    status: "",
  });
  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];

  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    window.scroll(0, 0);

    if (localStorage.getItem("userID")) {
      props.getFaqs();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.getRoleRes).length > 0) {
      setPermissions(props.getRoleRes.role);
      props.beforeRole();
    }
  }, [props.getRoleRes]);

  useEffect(() => {
    if (props.faqs.getFaqsAuth) {
      let { faqs, pagination } = props.faqs.faqs?.data;
      setData(faqs);
      setPagination(pagination);
      props.beforeFaq();
    }
  }, [props.faqs.getFaqsAuth]);

  useEffect(() => {
    if (data) {
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    if (props.faqs.delFaqAuth) {
      let filtered = data.filter((item) => {
        if (item._id !== props.faqs.faq.faqId) return item;
      });
      setData(filtered);
      props.beforeFaq();
    }
  }, [props.faqs.delFaqAuth]);

  const deleteFAQ = (faqId) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        props.deleteFaq(faqId);
      }
    });
  };

  const onPageChange = async (page) => {
    setLoader(true);
    const qs = objectToQueryString({ page, ...filters });
    props.getFaqs(qs);
  };

  const applyFilters = () => {
    if ((filters && filters.title) || (filters && filters.status)) {
      setResetButton(true);
      const qs = objectToQueryString({ page: 1, limit: 10, ...filters });
      props.getFaqs(qs);
      setLoader(true);
    } else {
      toast.error("All filter fields are empty.", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const reset = () => {
    setResetButton(false);
    setFilters({ title: "" });
    props.getFaqs();
    setLoader(true);
  };

  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );
  return (
    <>
      {loader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row className="pb-3">
            <Col sm={12}>
              <Card className="filter-card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title as="h4">Filters</Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6}>
                    <Form.Group  className="mb-3">
                        <label>Search with Title...</label>
                        <Form.Control
                          value={filters.title}
                          type="text"
                          placeholder="Title"
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              title: e.target.value.trim(),
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Search with status...</label>
                    <Form.Group  className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Status"
                          className="w-100"
                          options={statusOptions}
                          onChange={(e) =>
                            setFilters({ ...filters, status: e ? e.value : "" })
                          }
                          value={statusOptions.filter(
                            (s) => s.value === filters.status,
                          )}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                    <Form.Group  className="mb-3">
                        <Form.Label className="d-block mb-2">&nbsp;</Form.Label>
                        <div className="d-flex  filter-btns-holder">
                          <Button
                            className="btn-filled me-3"
                            onClick={applyFilters}
                          >
                            Search
                          </Button>
                          {resetButton && (
                            <Button
                              variant="warning"
                              className="outline-button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="table-outer">
                <Card>
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title as="h4">FAQs</Card.Title>
                      {permissions && permissions.addFaq && (
                        <Button
                          className="float-sm-right btn-filled d-flex align-items-center"
                          onClick={() => navigate(`/add-faq`)}
                        >
                          <span className="add-icon me-2">
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                          Add FAQ
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <div className="table-responsive">
                      <Table className="custom-table">
                        <thead>
                          <tr>
                            <th className="td-start serial-col">#</th>
                            <th className="td-name">
                              <div className="faqs-title td-name">Title</div>
                            </th>
                            <th className="td-description">
                              <div className="faq-title td-description w-100 text-center">
                                Description
                              </div>
                            </th>
                            <th className="td-status">Status</th>
                            {(permissions?.editFaq || permissions?.deleteFaq) && (
                              <th className="td-actions text-center">Actions</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length ? (
                            data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="serial-col">
                                    {pagination &&
                                      pagination.limit * pagination.page -
                                      pagination.limit +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    <div className="faq-title td-name">
                                      {item.title}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div
                                        className="faq-title td-description faq-desc uploaded-img logo-holder "
                                        dangerouslySetInnerHTML={{
                                          __html: item.desc || "N/A",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className={`label kyc-badge d-inline-block align-top px-2 py-1 ${item.status
                                        ? "label-success"
                                        : "label-danger"
                                        }`}
                                    >
                                      {item.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td className="td-actions">
                                    <ul className="list-unstyled mb-0 d-flex justify-content-center align-items-center">
                                      {permissions && permissions.editFaq && (
                                        <li className="d-inline-block align-top">
                                          <OverlayTrigger
                                            overlay={renderEditTooltip}
                                            placement="left"
                                            delay={{ show: 150, hide: 200 }}
                                          >
                                            <Button
                                              className="btn-link btn-icon"
                                              type="button"
                                              variant="success"
                                              onClick={() =>
                                                navigate(`/edit-faq/${item._id}`)
                                              }
                                            >
                                              <i className="fas fa-edit"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </li>
                                      )}
                                      {permissions && permissions.deleteFaq && (
                                        <li className="d-inline-block align-top">
                                          <OverlayTrigger
                                            overlay={renderDeleteTooltip}
                                            placement="left"
                                            delay={{ show: 150, hide: 200 }}
                                          >
                                            <Button
                                              className="btn-link btn-icon"
                                              type="button"
                                              variant="danger"
                                              onClick={() => deleteFAQ(item._id)}
                                            >
                                              <i className="fas fa-trash"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                <div className="alert alert-info" role="alert">
                                  No FAQ Found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {pagination && (
                        <Pagination
                          className="m-3"
                          defaultCurrent={1}
                          pageSize // items per page
                          current={pagination.page} // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  faqs: state.faqs,
  error: state.error,
  getRoleRes: state.role.getRoleRes,
});

Faq.propTypes = {
  beforeFaq: PropTypes.func,
  getFaqs: PropTypes.func,
  deleteFaq: PropTypes.func,
  beforeRole: PropTypes.func,
  faqs: PropTypes.object,
  getRoleRes: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, {
  beforeFaq,
  getFaqs,
  deleteFaq,
  beforeRole,
})(Faq);
