import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Dropdown, Navbar, Nav, Container } from "react-bootstrap";
import AdminNotifications from "views/notifications/adminNotifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faEllipsisVertical, faList, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

const AdminNavbar = () => {
  const location = useLocation()
  
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button className="btn-fill btn-icon d-none d-lg-block" onClick={() => document.body.classList.toggle("sidebar-mini")}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Button>
              <Button className="btn-fill btn-icon d-block d-lg-none" onClick={() => document.documentElement.classList.toggle("nav-open")}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {localStorage.getItem("userID") ? <AdminNotifications /> : <></>}
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle className="d-inline-block align-top" as={Nav.Link} id="dropdown-41471887333" variant="default">
                {/* <i className="nc-icon nc-bullet-list-67"></i> */}
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight={true} aria-labelledby="navbarDropdownMenuLink">
                <NavLink to="/profile" state= {{ location }} className="dropdown-item">
                  {/* <i className="nc-icon nc-settings-90"></i> */}
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                  Profile
                </NavLink>
                <a
                  style={{ cursor: "pointer" }}
                  className="dropdown-item"
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    window.location.replace("/admin");
                    localStorage.removeItem("role");
                    localStorage.removeItem("userID");
                  }}
                >
                  {/* <i className="nc-icon nc-button-power"></i> */}
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  Log out
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
