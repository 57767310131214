import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import AdminNavbar from "components/navbars/adminNavbar";
import { getRole, beforeRole } from "views/adminStaff/permissions/permissions.actions";
import Footer from "components/footer/adminFooter";
import Sidebar from "components/sidebar/sidebar";
import routes from "routes.js";
import { ENV } from "../config/config";
import PropTypes from "prop-types";
const image3 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1658146838/hex-nft/assets/sidebar-bg_gmgqbc_sgisga.png";
const CryptoJS = require("crypto-js");
const { secretKey } = ENV;

let ProtectedArrayProperties = [
  {
    viewDashboard: false,
    url: "/admin/dashboard",
    navigateTo: "/permissions",
  },
  {
    viewRole: false,
    url: "/admin/permissions",
    navigateTo: "/staff",
  },
  {
    viewStaff: false,
    url: "/admin/staff",
    navigateTo: "/users",
  },
  {
    viewUsers: false,
    url: "/admin/users",
    navigateTo: "/blog-categories",
  },
  {
    viewBlogCategories: false,
    url: "/admin/blog-categories",
    navigateTo: "/blogs",
  },
  {
    viewBlogs: false,
    url: "/admin/blogs",
    navigateTo: "/blog-posts",
  },
  {
    viewBlogPosts: false,
    url: "/admin/blog-posts",
    navigateTo: "/email-templates",
  },
  {
    viewEmailTemplates: false,
    url: "/admin/email-templates",
    navigateTo: "/faq-categories",
  },
  {
    addEmailTemplates: false,
    url: "/admin/add-email-template",
    navigateTo: "/faq-categories",
  },
  {
    editEmailTemplates: false,
    url: "/admin/email-template/",
    navigateTo: "/faq-categories",
    hasParam: true,
  },
  {
    viewFaqCategories: false,
    url: "/admin/faq-categories",
    navigateTo: "/faqs",
  },
  {
    editFaqCategories: false,
    url: "/admin/edit-faq-category/",
    navigateTo: "/faqs",
    hasParam: true,
  },
  {
    addFaqCategories: false,
    url: "/admin/add-faq-category",
    navigateTo: "/faqs",
  },
  {
    viewFaqs: false,
    url: "/admin/faqs",
    navigateTo: "/products/categories",
  },
  {
    addFaq: false,
    url: "/admin/add-faq",
    navigateTo: "/products/categories",
  },
  {
    editFaq: false,
    url: "/admin/edit-faq",
    navigateTo: "/products/categories",
    hasParam: true,
  },
  {
    viewProductCategories: false,
    url: "/admin/products/categories",
    navigateTo: "/products",
  },
  {
    viewProducts: false,
    url: "/admin/products",
    navigateTo: "/site-settings",
  },
  // {
  //   viewContent: false,
  //   url: "/admin/cms",
  //   navigateTo: "/contact",
  // },
  // {
  //   viewContent: false,
  //   url: "/admin/view-cms/",
  //   navigateTo: "/contact",
  // },
  // {
  //   addContent: false,
  //   url: "/admin/add-cms",
  //   navigateTo: "/contact",
  // },
  // {
  //   editContent: false,
  //   url: "/admin/edit-cms/",
  //   navigateTo: "/contact",
  //   hasParam: true,
  // },
  // {
  //   viewContact: false,
  //   url: "/admin/contact",
  //   navigateTo: "/newsletter",
  // },
  // {
  //   viewNewsLetter: false,
  //   url: "/admin/newsletter",
  //   navigateTo: "/site-settings",
  // },
  {
    viewSetting: false,
    url: "/admin/site-settings",
    navigateTo: "/dashboard",
  },
];
class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: routes,
      permissions: {},
    };

    this.mainPanelRef = createRef();
  }

  componentDidMount() {
    let roleEncrypted = localStorage.getItem("role");
    let role = "";
    if (roleEncrypted) {
      let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
      role = roleDecrypted;
      this.props.getRole(role);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    // component will receive props alternative

    if (Object.keys(props.getRoleRes).length > 0) {
      this.setState({ permissions: props.getRoleRes.role });
      let data = props.getRoleRes.role;
      let path = window.location.pathname;
      for (const key in data) {
        ProtectedArrayProperties.forEach((val) => {
          if (key === Object.keys(val)[0] && (Object.values(val)[1] === path || (path.includes(val.url) && val.hasParam)) && data[key] === false) {
            this.props.navigate(Object.values(val)[2]);
          }
        });
      }
    }
  }

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Not Found";
  };

  componentDidUpdate(e) {

    let pathname = window.history.state?.usr?.location?.pathname
    if (pathname) {
      pathname = pathname.split('/')[1]

      if (window.innerWidth < 993 && pathname !== window.location.pathname && document.documentElement.className.indexOf("nav-open") !== -1) {
        document.documentElement.classList.toggle("nav-open");
      }

    }
    // if (e?.history?.action === "PUSH") {
    // document.documentElement?.scrollTop = 0;
    // document.scrollingElement?.scrollTop = 0;
    // this.mainPanelRef.scrollTop = 0;
    // }
  }

  render() {

    return (
      <div>
        {localStorage.getItem("accessToken") ? (
          <div className={`wrapper`}>
            <Sidebar {...this.props} routes={this.state.routes} image={image3} background={"black"} permissionsData={this.state.permissions ? this.state.permissions : {}} />
            <div id="main-panel" className="main-panel position-relative" ref={this.mainPanelRef}>
              <AdminNavbar {...this.props} brandText={this.getBrandText(window.location.pathname)} />
              <div className="content">{this.props.children}</div>
              <Footer />
            </div>
          </div>
        ) : (
          this.props.navigate("/")
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getRoleRes: state.role.getRoleRes,
});

Admin.propTypes = {
  getRoleRes: PropTypes.object,
  getRole: PropTypes.func,
  navigate: PropTypes.func,
  children: PropTypes.object,
};
export default connect(mapStateToProps, { getRole, beforeRole })(Admin);
