import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addRole, updateRole, beforeRole, getRoles } from "./permissions.actions";
import $ from "jquery";
import { Button, Form, Container, Row, Col, Modal, FormGroup } from "react-bootstrap";
import { removeExtraSpaces } from "utils/validations/validations";
import PropTypes from "prop-types";

const PermissionsModal = (props) => {
  const dispatch = useDispatch();
  const [totalRoles, setTotalRoles] = useState(0);
  const [rolesErr, setErr] = useState({});
  const [permissions, setPermissions] = useState({
    /**  system permissions **/

    viewDashboard: false,

    // staff's records
    addStaff: false,
    editStaff: false,
    deleteStaff: false,
    viewStaff: false,

    // permissions
    addRole: false,
    editRole: false,
    deleteRole: false,
    viewRole: false,

    // users records
    addUser: false,
    editUser: false,
    deleteUser: false,
    viewUsers: false,

    // blog categories
    addBlogCategory: false,
    viewBlogCategories: false,
    deleteBlogCategory: false,
    editBlogCategory: false,

    // blogs
    addBlog: false,
    editBlog: false,
    deleteBlog: false,
    viewBlogs: false,

    // blog posts
    addBlogPost: false,
    editBlogPost: false,
    deleteBlogPost: false,
    viewBlogPosts: false,

    // EmailTemplates
    viewEmailTemplates: false,
    addEmailTemplates: false,
    editEmailTemplates: false,
    deleteEmailTemplates: false,

    //FaqCategories
    viewFaqCategories: false,
    addFaqCategories: false,
    editFaqCategories: false,
    deleteFaqCategories: false,

    // FAQs
    addFaq: false,
    editFaq: false,
    deleteFaq: false,
    viewFaqs: false,

    // content
    viewContent: false,
    addContent: false,
    editContent: false,
    deleteContent: false,

    // contact
    viewContact: false,
    editContact: false,
    deleteContact: false,

    // settings
    viewSetting: false,
    editSetting: false,

    // newsletter/subscriptions
    viewNewsLetter: false,

    // products
    viewProducts: false,
    addProduct: false,
    editProduct: false,
    deleteProduct: false,

    // product categories
    viewProductCategories: false,
    addProductCategory: false,
    editProductCategory: false,
    deleteProductCategory: false,

  });
  const addRoleRes = useSelector((state) => state.role.addRoleRes);
  const updateRoleRes = useSelector((state) => state.role.updateRoleRes);
  const authenticate = useSelector((state) => state.role.authenticate);

  useEffect(() => {
    if (Object.keys(permissions).length) {
      let total = 0;
      Object.keys(permissions).forEach((key, index) => {
        if (key !== "status" && key !== "title" && key !== "_id" && key !== "superAdminRole") {
          total++;
        }
      });
      setTotalRoles(total);
    }
  }, []);

  const onChangeCheckbox = (name, value) => {
    let roles = permissions;
    if (name === "selectAll") {
      Object.keys(roles).forEach((val, key) => {
        if (val !== "title" && val !== "_id" && val !== "status") roles = { ...roles, [val]: value };
      });
      props.setSelectAll(value);
    } else {
      roles = { ...roles, [name]: value };

      // select all state settings
      let count = 0;
      let allSelected = false;
      Object.keys(roles).forEach((key, index) => {
        if (roles[key] === true && key !== "status" && key !== "title" && key !== "_id" && key !== "superAdminRole") {
          count++;
        } else {
          allSelected = true;
        }
      });
      let selectCount = count === totalRoles ? true : false;
      props.setSelectAll(selectCount);
    }
    setPermissions(roles);
  };

  const submit = (e) => {
    let err = {};
    if (!props.title || !props.title.trim()) {
      err.title = "Title Required.";
    }

    let all = null;
    for (let key in permissions) {
      if (permissions[key] === true) all = true;
    }

    if (!all) {
      err.roles = "Please check atleast one permission.";
    }

    if (err && (err.title || err.roles)) {
      setErr({ ...err });
      $("#roles-modal").scrollTop(0, 0);
      return;
    }

    if (props.modalType === 1) delete permissions["_id"];

    let roleTitle = removeExtraSpaces(props.title);

    const role = { ...permissions, title: roleTitle, status: props.status };
    delete role["superAdminRole"];

    if (props.modalType === 1)
      // add modal type
      dispatch(addRole(role));
    else if (props.modalType === 3)
      // update modal type
      dispatch(updateRole(role));

    setPermissions(role);
    props.setroleModal(!props.roleModal);
    props.setModalType(0);
    props.setData(role);
    props.setLoader(true);
  };

  useEffect(() => {
    if (Object.keys(props.role).length > 0) {
      updateInitialData({ ...props });
    }
  }, [props.role]);

  const updateInitialData = (props) => {
    let newprops = { ...props };
    let count = 0;
    let total = 0;
    let allSelected = false;
    console.log('newprops.role', newprops.role)
    Object.keys(newprops.role).forEach((key, index) => {
      if (key !== "status" && key !== "title" && key !== "_id" && key !== "superAdminRole") {
        total++
        if (newprops.role[key]) {
          count++
        }
      }
    });
    console.log(total, count)
    let selectCount = count === total ? true : false;
    props.setSelectAll(selectCount);
    setTotalRoles(total);
    setPermissions(newprops.role);
    props.setTitle(newprops.role.title);
    props.setStatus(newprops.role.status);
  };

  useEffect(() => {
    if (props.modalType === 2) {
      $(".modal-primary input").prop("disabled", true);
    } else {
      $(".modal-primary input").prop("disabled", false);
    }
  }, [props.modalType]);

  useEffect(() => {
    console.log('addRoleRes', addRoleRes)
    if (addRoleRes.success && authenticate === true) {
      // props.setLoader(false);
      setEmpty();
      dispatch(beforeRole());
      dispatch(getRoles())
    }
  }, [addRoleRes]);

  const onCloseHandler = () => {
    props.setroleModal(!props.roleModal);
    setErr({});
    if (props.modalType === 3) {
      updateInitialData({ ...props });
    }
  };

  useEffect(() => {
    console.log('updateRoleResupdateRoleRes', updateRoleRes)
    if (Object.keys(updateRoleRes).length > 0 && authenticate === true) {
      props.setLoader(false);
      dispatch(getRoles())
      dispatch(beforeRole());
    }
  }, [updateRoleRes]);

  const setEmpty = () => {
    props.setStatus(false);
    props.setTitle("");
    props.setSelectAll(false);
    for (let key in permissions) {
      permissions[key] = false;
    }
  };

  return (
    <Container fluid>
      {props.modalType > 0 && (
        <Modal className="modal-primary" onHide={onCloseHandler} show={props.roleModal}>
          <Modal.Header className="justify-content-center">
            <Row>
              <div className="col-12">
                <h4 className="mb-0 mb-md-3 mt-0">{props.modalType === 1 ? "Add New" : props.modalType === 2 ? "View" : "Edit"} Staff Role</h4>
              </div>
            </Row>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={12}>
                    <label className="label-font">
                      Title <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      className="mb-3"
                      placeholder="Enter title"
                      type="text"
                      name="title"
                      onChange={(e) => props.setTitle(e.target.value)}
                      disabled={props.modalType === 2}
                      value={props.title || ""}
                      required
                    />
                    <div className="d-flex justify-content-between align-items-center edit-title">
                      <span>
                        <label className={rolesErr.title ? `pl-1 text-danger` : `d-none`}>{rolesErr.title}</label>
                      </span>
                      <label className="right-label-checkbox">
                        Select All
                        <input type="checkbox" name="selectAll" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !props.selectAll)} checked={props.selectAll} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row className="align-items-start">
                  <Col md={4}>
                    <label className="label-font">Dashboard</label>
                  </Col>
                  <Col md={8}>
                    <label className="right-label-checkbox mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewDashboard"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewDashboard)}
                        checked={permissions.viewDashboard}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Staff</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewStaff"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewStaff)}
                        checked={permissions.viewStaff}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addStaff" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addStaff)} checked={permissions.addStaff} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editStaff"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editStaff)}
                        checked={permissions.editStaff}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteStaff"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteStaff)}
                        checked={permissions.deleteStaff}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Staff Roles</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input type="checkbox" name="viewRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewRole)} checked={permissions.viewRole} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addRole)} checked={permissions.addRole} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input type="checkbox" name="editRole" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editRole)} checked={permissions.editRole} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteRole"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteRole)}
                        checked={permissions.deleteRole}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Users</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewUsers"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewUsers)}
                        checked={permissions.viewUsers}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addUser" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addUser)} checked={permissions.addUser} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input type="checkbox" name="editUser" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editUser)} checked={permissions.editUser} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteUser"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteUser)}
                        checked={permissions.deleteUser}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Blog Categories</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewBlogCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewBlogCategories)}
                        checked={permissions.viewBlogCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addBlogCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addBlogCategory)}
                        checked={permissions.addBlogCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editBlogCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editBlogCategory)}
                        checked={permissions.editBlogCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteBlogCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteBlogCategory)}
                        checked={permissions.deleteBlogCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Blogs</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewBlogs"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewBlogs)}
                        checked={permissions.viewBlogs}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addBlog" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addBlog)} checked={permissions.addBlog} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input type="checkbox" name="editBlog" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editBlog)} checked={permissions.editBlog} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteBlog"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteBlog)}
                        checked={permissions.deleteBlog}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Blog Posts</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewBlogPosts"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewBlogPosts)}
                        checked={permissions.viewBlogPosts}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addBlogPost"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addBlogPost)}
                        checked={permissions.addBlogPost}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editBlogPost"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editBlogPost)}
                        checked={permissions.editBlogPost}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteBlogPost"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteBlogPost)}
                        checked={permissions.deleteBlogPost}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Faq Categories</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewFaqCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewFaqCategories)}
                        checked={permissions.viewFaqCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addFaqCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addFaqCategories)}
                        checked={permissions.addFaqCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editFaqCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editFaqCategories)}
                        checked={permissions.editFaqCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteFaqCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteFaqCategories)}
                        checked={permissions.deleteFaqCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">FAQs</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input type="checkbox" name="viewFaqs" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewFaqs)} checked={permissions.viewFaqs} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addFaq" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addFaq)} checked={permissions.addFaq} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input type="checkbox" name="editFaq" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editFaq)} checked={permissions.editFaq} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteFaq"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteFaq)}
                        checked={permissions.deleteFaq}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Product Categories</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewProductCategories"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewProductCategories)}
                        checked={permissions.viewProductCategories}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addProductCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addProductCategory)}
                        checked={permissions.addProductCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editProductCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editProductCategory)}
                        checked={permissions.editProductCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteProductCategory"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteProductCategory)}
                        checked={permissions.deleteProductCategory}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Products</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input type="checkbox" name="viewProducts" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewProducts)} checked={permissions.viewProducts} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input type="checkbox" name="addProduct" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addProduct)} checked={permissions.addProduct} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input type="checkbox" name="editProduct" disabled={props.modalType === 2} onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editProduct)} checked={permissions.editProduct} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteProduct"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteProduct)}
                        checked={permissions.deleteProduct}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Contacts</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewContact"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewContact)}
                        checked={permissions.viewContact}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editContact"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editContact)}
                        checked={permissions.editContact}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteContact"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteContact)}
                        checked={permissions.deleteContact}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Emails</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewEmailTemplates"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewEmailTemplates)}
                        checked={permissions.viewEmailTemplates}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addEmailTemplates"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addEmailTemplates)}
                        checked={permissions.addEmailTemplates}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editEmailTemplates"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editEmailTemplates)}
                        checked={permissions.editEmailTemplates}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteEmailTemplates"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteEmailTemplates)}
                        checked={permissions.deleteEmailTemplates}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Settings</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewSetting"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewSetting)}
                        checked={permissions.viewSetting}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editSetting"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editSetting)}
                        checked={permissions.editSetting}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Content</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewContent"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewContent)}
                        checked={permissions.viewContent}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Add
                      <input
                        type="checkbox"
                        name="addContent"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.addContent)}
                        checked={permissions.addContent}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Edit
                      <input
                        type="checkbox"
                        name="editContent"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.editContent)}
                        checked={permissions.editContent}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-checkbox me-3 mb-4">
                      Delete
                      <input
                        type="checkbox"
                        name="deleteContent"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.deleteContent)}
                        checked={permissions.deleteContent}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col md={4}>
                    <label className="label-font">Newsletters</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex">
                    <label className="right-label-checkbox me-3 mb-4">
                      View
                      <input
                        type="checkbox"
                        name="viewNewsLetter"
                        disabled={props.modalType === 2}
                        onChange={(e) => onChangeCheckbox(e.target.name, !permissions.viewNewsLetter)}
                        checked={permissions.viewNewsLetter}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </Form.Group>
              <FormGroup>
                <Row>
                  <Col md={4}>
                    <label className="label-font">Status</label>
                  </Col>
                  <Col md={8} className="check-inline d-flex align-items-center">
                    <label className="right-label-radio me-3 mb-2">
                      Active
                      <input name="status" disabled={props.modalType === 2} type="radio" checked={props.status} value={props.status} onChange={(e) => props.setStatus(true)} />
                      <span className="checkmark"></span>
                    </label>
                    <label className="right-label-radio me-3 mb-2">
                      Inactive
                      <input name="status" disabled={props.modalType === 2} type="radio" checked={!props.status} value={!props.status} onChange={(e) => props.setStatus(false)} />
                      <span className="checkmark"></span>
                    </label>
                  </Col>
                </Row>
              </FormGroup>
              <span>
                <label className={rolesErr?.roles ? `pl-1 text-danger` : `d-none`}>{rolesErr.roles}</label>
              </span>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="outline-button" onClick={() => onCloseHandler()}>
              Close
            </Button>
            {props.modalType === 2 ? (
              ""
            ) : (
              <Button className="btn-filled" onClick={() => submit()}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

PermissionsModal.propTypes = {
  modalType: PropTypes.number,
  setModalType: PropTypes.func,
  setLoader: PropTypes.func,
  setData: PropTypes.func,
  setroleModal: PropTypes.func,
  roleModal: PropTypes.bool,
  setStatus: PropTypes.func,
  setTitle: PropTypes.func,
  setSelectAll: PropTypes.func,
  selectAll: PropTypes.bool,
  status: PropTypes.bool,
  title: PropTypes.string,
  error: PropTypes.object,
  admin: PropTypes.object,
  role: PropTypes.object,
};

export default PermissionsModal;
