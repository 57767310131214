import { toast } from 'react-toastify';
import { CREATE_BLOG_CAT, LIST_BLOG_CATS, GET_BLOG_CAT, EDIT_BLOG_CAT, DELETE_BLOG_CAT, BEFORE_BLOG_CAT } from '../../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `blogs/categories`

export const beforeBlogCategory = () => {
    return {
        type: BEFORE_BLOG_CAT
    }
}

export const createBlogCategory = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'POST',
        body,
        actionType: CREATE_BLOG_CAT,
        addAccessToken: true
    })
};

export const listBlogCategories = (qs = '', showToast = true) => dispatch => {
    if (showToast) toast.dismiss()
    let url = `${apiUrl}`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: LIST_BLOG_CATS,
        showToast
    })
};

export const getBlogCategory = (id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${id}`,
        method: 'GET',
        actionType: GET_BLOG_CAT,
        showToast: false
    })
};

export const editBlogCategory = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'PUT',
        body,
        actionType: EDIT_BLOG_CAT,
        addAccessToken: true
    })
};

export const deleteBlogCategory = (id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${id}`,
        method: 'DELETE',
        actionType: DELETE_BLOG_CAT,
    })
};