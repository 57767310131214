import { toast } from 'react-toastify';
import { BEFORE_USER, GET_USERS, DELETE_USER, UPSERT_USER } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';
const apiUrl = `users`

export const beforeUser = () => {
    return {
        type: BEFORE_USER
    }
}

export const createUser = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'POST',
        actionType: UPSERT_USER,
        body,
        addAccessToken: true
    })
};

export const getUsers = (qs = '', body = {}, toastCheck = true) => dispatch => {
    toast.dismiss();

    let url = `${apiUrl}`;

    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'GET',
        actionType: GET_USERS,
        body,
        addAccessToken: true,
        showToast: toastCheck
    })
};

export const deleteUser = (Id) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}/${Id}`,
        method: 'DELETE',
        actionType: DELETE_USER,
    })
};

export const updateUser = (body) => dispatch => {

    generalHttpRequest({
        url: `${apiUrl}`,
        method: 'PUT',
        actionType: UPSERT_USER,
        body,
        addAccessToken: true
    })
};