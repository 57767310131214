import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ENV } from "../../../config/config";
import { beforeRole, deleteRole, getPermission, getRoles } from "./permissions.actions";
import FullPageLoader from "components/fullPageLoader/fullPageLoader";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Pagination from "rc-pagination";
import { Link } from "react-router-dom";
import PermissionsModal from "./permissionsModalComponent";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { Button, Card, Form, Table, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import "rc-pagination/assets/index.css";

const CryptoJS = require("crypto-js");
const { objectToQueryString, secretKey } = ENV;

const StaffPermissions = (props) => {
  const [roleModal, setroleModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [isLoader, setLoader] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState({});
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [roles, setRoles] = useState();
  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [status, setStatus] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [title, setTitle] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const [role, setRole] = useState({
    /**  system permissions **/
    viewDashboard: false,

    // staff's records
    addStaff: false,
    editStaff: false,
    deleteStaff: false,
    viewStaff: false,

    // permissions
    addRole: false,
    editRole: false,
    deleteRole: false,
    viewRole: false,

    // users records
    addUser: false,
    editUser: false,
    deleteUser: false,
    viewUsers: false,

    // blog categories
    addBlogCategory: false,
    viewBlogCategories: false,
    deleteBlogCategory: false,
    editBlogCategory: false,

    // blogs
    addBlog: false,
    editBlog: false,
    deleteBlog: false,
    viewBlogs: false,

    // blog posts
    addBlogPost: false,
    editBlogPost: false,
    deleteBlogPost: false,
    viewBlogPosts: false,

    // EmailTemplates
    viewEmailTemplates: false,
    addEmailTemplates: false,
    editEmailTemplates: false,
    deleteEmailTemplates: false,

    //FaqCategories
    viewFaqCategories: false,
    addFaqCategories: false,
    editFaqCategories: false,
    deleteFaqCategories: false,

    // FAQs
    addFaq: false,
    editFaq: false,
    deleteFaq: false,
    viewFaqs: false,

    // content
    viewContent: false,
    addContent: false,
    editContent: false,
    deleteContent: false,

    // contact
    viewContact: false,
    editContact: false,
    deleteContact: false,

    // settings
    viewSetting: false,
    editSetting: false,

    // newsletter/subscriptions
    viewNewsLetter: false,

    // products
    viewProducts: false,
    addProduct: false,
    editProduct: false,
    deleteProduct: false,

    // product categories
    viewProductCategories: false,
    addProductCategory: false,
    editProductCategory: false,
    deleteProductCategory: false,

  });

  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" },
  ];

  const setModal = (type = 0, adminRole = {}) => {
    setroleModal(!roleModal);
    setModalType(type);
    setLoader(false);
    if ((type === 2 || type === 3) && adminRole) {
      setRole(adminRole);
    }
    if (type === 1) {
      setEmpty();
    }
  };
  const setEmpty = () => {
    setTitle("");
    setStatus(false);
    setSelectAll(false);
    let _role = { ...role };
    for (let key in _role) {
      _role[key] = false;
    }
    setRole(_role);
  };

  const getData = (role) => {
    setRole(role);
    props.getRoles();
  };

  const deleteRoleHandler = (roleId) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      html: "If you delete an item, it will lost permanently.",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.value) {
        setLoader(true);
        props.deleteRole(roleId);
      }
    });
  };

  const onSearch = () => {
    if (search || searchStatus) {
      setResetButton(true);
      const qs = objectToQueryString({
        title: search.trim(),
        status: searchStatus,
      });
      props.getRoles(qs);
      setModalType(0);
      setLoader(true);
    } else {
      toast.error("Add title or select status to be searched.", {
        toastId: "FIELD_REQUIRED",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch();
    }
  };

  const reset = () => {
    setResetButton(false);
    setLoader(true);
    setSearch("");
    setSearchStatus("");
    props.getRoles();
  };

  const onPageChange = (page) => {
    const qs = objectToQueryString({
      page,
      limit,
      title: search.trim(),
      status: searchStatus,
    });
    props.getRoles(qs);
    setLoader(true);
  };

  useEffect(() => {
    props.getRoles();
    let roleEncrypted = localStorage.getItem("role");
    let role = "";
    if (roleEncrypted) {
      let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
      props.getPermission(roleDecrypted);
      role = roleDecrypted;
    }
    setCurrentRoleId(role !== "" ? role : null);
  }, []);

  useEffect(() => {
    if (Object.keys(props.getRolesRes).length > 0 && props.authenticate === true) {
      let { getRolesRes } = props;
      setLoader(false);
      setRoles(getRolesRes.data);
      setPage(getRolesRes.page);
      setPages(getRolesRes.pages);
      setLimit(getRolesRes.limit);

      props.beforeRole();
    }
  }, [props.getRolesRes]);

  useEffect(() => {
    if (props.currentUserPermission.authPermission) {
      setCurrentUserRole(props.currentUserPermission.permission.role);
    }
  }, [props.currentUserPermission.authPermission]);

  useEffect(() => {
    if (Object.keys(props.deleteRoleRes).length > 0 && props.authenticate === true) {
      setModalType(1);
      setLoader(false);
      toast.success(`Success! ${props.deleteRoleRes.message}`);
      props.beforeRole();
      props.getRoles();
    }
  }, [props.deleteRoleRes]);

  const renderDeleteTooltip = (props) => (
    <Tooltip id="button-tooltip1" {...props}>
      Delete
    </Tooltip>
  );
  const renderEditTooltip = (props) => (
    <Tooltip id="button-tooltip2" {...props}>
      Edit
    </Tooltip>
  );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip3" {...props}>
      View
    </Tooltip>
  );

  return (
    <>
      {isLoader ? (
        <FullPageLoader />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="filter-card card">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-between table-head">
                    <Card.Title className="text-white" as="h4">
                      Filters
                    </Card.Title>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4} sm={6} className="search-wrap">
                      <Form.Group className="mb-3">
                        <Form.Label className="d-block mb-2 text-white">Search with title...</Form.Label>
                        <Form.Control type="email" onKeyDown={handleKeyPress} name="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl={4} sm={6} className="search-wrap">
                      <label>Search with status...</label>
                      <Form.Group className="mb-3">
                        <Select
                          classNamePrefix="theme-select"
                          placeholder="Select Status"
                          name="status"
                          options={statusOptions}
                          onChange={(e) => setSearchStatus(e ? e.value : "")}
                          value={statusOptions.filter((l) => l.value === searchStatus)}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={4} md={6}>
                      <Form.Group className="mb-3">
                        <label className="d-none d-sm-block mb-2 form-label">&nbsp;</label>
                        <div className="d-flex  filter-btns-holder">
                          <button type="button" className="btn-filled me-3" onClick={onSearch}>
                            Search
                          </button>
                          {resetButton && (
                            <button type="button" className="outline-button" onClick={reset}>
                              Reset
                            </button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div className="table-outer">
                <Card>
                  <Card.Header>
                    <div className="d-flex align-items-center justify-content-between table-head">
                      <Card.Title as="h4">Permissions</Card.Title>
                      {currentUserRole?.addRole && (
                        <Button className="float-sm-right btn-filled d-flex align-items-center" onClick={() => setModal(1)}>
                          <span className="add-icon me-2">
                            <FontAwesomeIcon icon={faPlus} />
                          </span>{" "}
                          Add New Role
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <div className="table-responsive">
                      <Table className="custom-table">
                        <thead>
                          <tr>
                            <th className="td-start text-center">#</th>
                            <th className="td-name text-center">Title</th>
                            <th className="text-center td-status td-name text-center">Status</th>
                            <th className="td-actions text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles && roles.length > 0 ? (
                            roles.map((val, key) => {
                              return (
                                <tr key={key} className="align-items-center">
                                  <td className="text-center">{limit * page - limit + key + 1}</td>
                                  <td className="td-name text-center">
                                    <Link data-toggle="modal" data-target="modal-primary" className="text-capitalize" onClick={() => setModal(2, val)}>
                                      {val.title}
                                    </Link>
                                  </td>
                                  <td className="text-center td-name">
                                    {val.status ? <label className="status-tag bg-success m-0 text-white">Active</label> : <label className="status-tag bg-danger m-0 text-white">Inactive</label>}
                                  </td>
                                  <td className="td-actions text-center">
                                    <ul className="list-unstyled mb-0 d-flex justify-content-center">
                                      {currentUserRole?.viewRole ? (
                                        <li className="d-inline-block align-top">
                                          <OverlayTrigger
                                            overlay={renderViewTooltip}
                                            placement="left"
                                            delay={{ show: 150, hide: 200 }}
                                          >
                                            <button type="button" className="btn-link btn-icon btn btn-info" onClick={() => setModal(2, val)}>
                                              <i className="fas fa-eye"></i>
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      ) : null}
                                      {currentUserRole?.editRole ? (
                                        <li className="d-inline-block align-top">
                                          <OverlayTrigger
                                            overlay={renderEditTooltip}
                                            placement="left"
                                            delay={{ show: 150, hide: 200 }}
                                          >
                                            <button
                                              type="button"
                                              className="btn-link btn-icon btn btn-success"
                                              disabled={currentRoleId === val._id || val.superAdminRole}
                                              onClick={() => setModal(3, val)}
                                            >
                                              <i className="fas fa-edit"></i>
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      ) : null}
                                      {currentUserRole?.deleteRole ? (
                                        <li className="d-inline-block align-top">
                                          <OverlayTrigger
                                            overlay={renderDeleteTooltip}
                                            placement="left"
                                            delay={{ show: 150, hide: 200 }}
                                          >
                                            <button
                                              type="button"
                                              className="btn-link btn-icon btn btn-danger"
                                              disabled={currentRoleId === val._id || val.superAdminRole}
                                              onClick={() => deleteRoleHandler(val._id)}
                                            >
                                              <i className="fas fa-trash"></i>
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      ) : null}
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center px-0" colSpan="4">
                                <span className="alert alert-info d-block text-center">No Record Found</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <Col className="p-4">
                        <Pagination
                          defaultCurrent={1}
                          pageSize // items per page
                          current={page} // current active page
                          total={pages} // total pages
                          onChange={onPageChange}
                          locale={localeInfo}
                        />
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <PermissionsModal
            setData={getData}
            modalType={modalType}
            setModalType={setModalType}
            roleModal={roleModal}
            setroleModal={setroleModal}
            role={role}
            setLoader={setLoader}
            title={title}
            status={status}
            setTitle={setTitle}
            setStatus={setStatus}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addRoleRes: state.role.addRoleRes,
  updateRoleRes: state.role.updateRoleRes,
  deleteRoleRes: state.role.deleteRoleRes,
  getRoleRes: state.role.getRoleRes,
  getRolesRes: state.role.getRolesRes,
  authenticate: state.role.authenticate,
  errors: state.errors,
  currentUserPermission: state.role,
});

StaffPermissions.propTypes = {
  deleteRole: PropTypes.func,
  getRoles: PropTypes.func,
  getPermission: PropTypes.func,
  beforeRole: PropTypes.func,
  getRolesRes: PropTypes.object,
  currentUserPermission: PropTypes.object,
  authenticate: PropTypes.bool,
  deleteRoleRes: PropTypes.object,
};

export default connect(mapStateToProps, {
  beforeRole,
  deleteRole,
  getRoles,
  getPermission,
})(StaffPermissions);
