import React from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import validator from "validator";
import { forgotPassword, beforeAdmin } from "../admin/admin.action";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { Images } from '../../assets/assets'
import PropTypes from "prop-types";
import "./forgotPassword.css";

const ForgotPassword = (props) => {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [email, setEmail] = React.useState("");
  const [emailMsg, setEmailMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");

  const resetPassword = (e) => {
    e.preventDefault();
    if (validator.isEmpty(email)) {
      setEmailMsg("Email address is required");
    } else if (!validator.isEmail(email)) {
      setEmailMsg("Invalid Email");
    } else {
      setEmailMsg("");
      props.forgotPassword({ email });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setCardClasses("");
    }, 500);
  });

  React.useEffect(() => {
    if (props.admin.forgotPasswordAuth) {
      setEmail("");
      setMsg(props.admin.forgotMsg);
      props.beforeAdmin();
    }
  }, [props.admin.forgotPasswordAuth]);

  const getAlert = () => {
    if (msg) {
      return (
        <Alert variant="info">
          <span>{msg}</span>
        </Alert>
      );
    }
  };

  return (
    <>
      <div className="full-page section-image h-100 auth-page" data-color="black">
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Row>
              <Col className="mx-auto" lg="6" md="8">
                <Form action="" className="form" method="">
                  <Card className={"card-login " + cardClasses}>
                    <Card.Header className="text-center">
                      <div className="logo-holder d-inline-block align-top">
                        <img src={Images.Logo} className="img-fluid" alt="" />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {getAlert()}
                      <div className="form-group">
                        <label>
                          Email address<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <Form.Control placeholder="Enter email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                          <span>
                            <label className="pl-1 text-danger"></label>
                          </span>
                        </div>
                      </div>
                      <span className={emailMsg ? `` : `d-none`}>
                        <label className="pl-1 text-danger">{emailMsg}</label>
                      </span>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-center">
                      <Button className="btn-filled" type="submit" onClick={resetPassword}>
                        Submit
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  error: state.error,
});

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func,
  beforeAdmin: PropTypes.func,
  admin: PropTypes.object,
  error: PropTypes.object,
};

export default connect(mapStateToProps, { forgotPassword, beforeAdmin })(ForgotPassword);
