import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRole } from "views/adminStaff/permissions/permissions.actions";
import { ENV } from "config/config";
import PropTypes from "prop-types";
const CryptoJS = require("crypto-js");
const { secretKey, exclusiveRoutes } = ENV;

const Getter = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userID");
  const currentLocation = location.pathname.split("/")[1];

  useEffect(() => {
    window.scroll(0, 0);

    let roleEncrypted = localStorage.getItem("role");

    if (roleEncrypted) {
      let roleDecrypted = CryptoJS.AES.decrypt(roleEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
      props.getRole(roleDecrypted);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!userId && !exclusiveRoutes.includes(currentLocation)) {
      localStorage.clear();
      navigate("/login");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    // when an error is received
    if (props.error.error && props.error.error !== null) {
      let { error } = props.error;
      // if user is not found, clear storage and redirect to login screen
      // if (error.userDisabled || error.user404 || error.notExist) {
      if (error.userError) {
        localStorage.clear();
        navigate("/login");
      }
    }
  }, [props.error]);

  return <></>;
};

const mapStateToProps = (state) => ({
  error: state.error,
});

Getter.propTypes = {
  error: PropTypes.object,
  getRole: PropTypes.func,
};

export default connect(mapStateToProps, { getRole })(Getter);
