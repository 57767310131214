import { toast } from 'react-toastify';
import { BEFORE_CONTACT, GET_CONTACTS, EDIT_CONTACT } from '../../redux/types';
import { generalHttpRequest } from 'utils/httpRequests/httpRequests';

export const beforeContact = () => {
    return {
        type: BEFORE_CONTACT
    }
}

export const getContacts = (qs = null, body = {}) => dispatch => {
    toast.dismiss();

    let url = `contacts/list`;
    if (qs)
        url += `?${qs}`

    generalHttpRequest({
        url,
        method: 'POST',
        body,
        actionType: GET_CONTACTS,
        addAccessToken: true
    })
};

export const updateContact = (body, method = 'PUT') => dispatch => {

    generalHttpRequest({
        url: `contacts/edit`,
        method,
        body,
        actionType: EDIT_CONTACT,
    })
};


